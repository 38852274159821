import axios from "axios";

const state = {
  serie: null,
  latestCollections: null,
  latest4Collections: null,
  categories: null,
};

const getters = {
  serie: (state) => state.serie,
  collections: (state) => state.collections,
  categories: (state) => state.categories,
  latestCollections: (state) => state.latestCollections,
  latest4Collections: (state) => state.latest4Collections,
};

const mutations = {
  SET_SERIE(state, serie) {
    // Mutation pour la série
    state.serie = serie;
  },
  SET_COLLECTIONS(state, collections) {
    state.collections = collections;
  },
  SET_LATEST_COLLECTIONS(state, collections) {
    state.latestCollections = collections;
  },
  SET_LATEST_4_COLLECTIONS(state, collections) {
    state.latest4Collections = collections;
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
};

const actions = {
  async loadCollections({ commit }, lang) {
    try {
      const response = await axios.get("/doovies", {
        params: {
          locale: lang,
          fields: ["titre", "collection", "desc", "voix", "cc", "note", "free"],
          populate: {
            locale: lang,
            doovie_categories: {
              fields: ["titre"],
              locale: lang,
            },
            cover: { fields: ["formats"] },
            logo: { fields: ["formats"] },
          },
        },
      });

      commit("SET_COLLECTIONS", response.data.data);
      commit("SET_LATEST_COLLECTIONS", response.data.data.slice(-1));
      commit(
        "SET_LATEST_4_COLLECTIONS",
        response.data.data.slice(-5).reverse()
      );
      // console.log("Collections récupérées :", response.data.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des collections:", error);
    }
  },

  async getCategories({ commit }, lang) {
    try {
      const response = await axios.get("/doovie-categories", {
        params: {
          locale: lang,
          fields: ["titre"],
        },
      });
      commit("SET_CATEGORIES", response.data.data);
      // console.log("categories: ", response.data.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des catégories:", error);
    }
  },
  async loadSerie({ commit }, id) {
    try {
      const response = await axios.get(`/doovies/${id}`, {
        params: {
          fields: ["titre", "id"],
          populate: {
            Saison: {
              fields: ["id"],
              populate: {
                Episodes: {
                  fields: ["numero", "url", "titre", "free"],
                  populate: {
                    soustitre: {
                      fields: ["*"],
                    },
                  },
                },
              },
            },
          },
        },
      });

      // console.log("serie : ", response.data.data);
      commit("SET_SERIE", response.data.data);
      // console.log("Collections récupérées :", response.data.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des collections:", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
