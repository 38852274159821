<template>
  <div class="sun-down">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
    >
      <g id="night_14322283" transform="translate(-98 -2.031)">
        <g
          id="Groupe_198"
          data-name="Groupe 198"
          transform="translate(98 2.031)"
        >
          <path
            id="Tracé_546"
            data-name="Tracé 546"
            d="M245.752,26.213a4.292,4.292,0,0,1,.268,1.5v.032a3,3,0,0,1-1.126,5.788H231.379a3.379,3.379,0,0,1-.607-6.7,4.884,4.884,0,0,1,3.3-5.844,7.617,7.617,0,0,1-.062-.967,7.514,7.514,0,0,1,6.887-7.483.75.75,0,0,1,.733,1.082,4.694,4.694,0,0,0,6.289,6.289.75.75,0,0,1,1.082.733A7.507,7.507,0,0,1,245.752,26.213ZM234.41,22.451a3.381,3.381,0,0,0-1.912,4.731.75.75,0,0,1-.669,1.092h-.45a1.877,1.877,0,1,0,0,3.755h13.515a1.5,1.5,0,0,0,.218-2.988.75.75,0,0,1-.632-.866,2.854,2.854,0,0,0,.038-.464,2.793,2.793,0,0,0-.107-.768,2.815,2.815,0,0,0-4.4-1.484A.75.75,0,0,1,238.825,25a3.385,3.385,0,0,0-2.867-2.7,3.488,3.488,0,0,0-.449-.029,3.377,3.377,0,0,0-1.1.182Zm10.58,2.464a6.021,6.021,0,0,0,2.284-3.192,6.2,6.2,0,0,1-7.467-7.468,6.013,6.013,0,0,0-4.3,5.761,6.131,6.131,0,0,0,.046.751A4.884,4.884,0,0,1,240,23.742a4.319,4.319,0,0,1,4.988,1.172Z"
            transform="translate(-228 -12.531)"
            fill-rule="evenodd"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "sun-down-icon",
};
</script>

<style scoped>
.sun-down {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sun-down svg {
  width: 21px;
  height: 21px;
}

.sun-down svg path {
  fill: var(--link);
}

.current-prayer svg path {
  fill: #fff;
}
</style>
