<template>
  <Head>
    <!-- Titre de la page -->
    <title>Coran en ligne - Doonun</title>

    <!-- Description Meta -->
    <meta
      name="description"
      content="Lisez et écoutez les sourates du Coran en arabe et en français sur Doonun, avec transcription phonétique et Tafsir. Suivez votre progression et explorez différentes fonctionnalités."
    />

    <!-- Robots Meta -->
    <meta name="robots" content="index, follow, noarchive" />

    <!-- Favicon -->
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />

    <!-- Charset -->
    <meta charset="UTF-8" />

    <!-- Viewport pour la réactivité mobile -->
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

    <!-- Canonical URL -->
    <link rel="canonical" href="https://www.doonun.com/" />

    <!-- Open Graph / Facebook -->
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.doonun.com/" />
    <meta property="og:title" content="Accueil - Doonun" />
    <meta
      property="og:description"
      content="Lisez et écoutez les sourates du Coran en arabe et en français sur Doonun, avec transcription phonétique et Tafsir. Suivez votre progression et explorez différentes fonctionnalités."
    />
    <meta
      property="og:image"
      content="https://cp.doonun.com/uploads/CORAN_e6d55279b0.png"
    />

    <!-- Twitter Cards -->
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:url" content="https://www.doonun.com/" />
    <meta name="twitter:title" content="Accueil - Doonun" />
    <meta
      name="twitter:description"
      content="Lisez et écoutez les sourates du Coran en arabe et en français sur Doonun, avec transcription phonétique et Tafsir. Suivez votre progression et explorez différentes fonctionnalités."
    />
    <meta
      name="twitter:image"
      content="https://cp.doonun.com/uploads/CORAN_e6d55279b0.png"
    />
    <meta name="twitter:site" content="@doonunfr" />
    <meta name="twitter:creator" content="@doonunfr" />

    <!-- Langue de la page -->
    <meta http-equiv="Content-Language" content="fr" />
  </Head>

  <div class="container">
    <h1>{{ $t("titlepage") }}</h1>
    <div class="switch-container">
      <el-switch
        v-model="isAscending"
        size="large"
        :active-text="`114 ${$t('a')} 1`"
        :inactive-text="`1 ${$t('a')} 114`"
      />
    </div>
    <div class="search">
      <el-input
        v-model="input1"
        @input="handleInput"
        style="max-width: 640px; width: 100%"
        size="large"
        :placeholder="$t('searchcoran')"
        :suffix-icon="Search"
        class="coran-search"
      />
    </div>
    <div class="check-goal" v-if="isAuthenticated">
      <CreateGoal
        v-if="showCreateGoal"
        :is-editing="isEditing"
        @goal-saved="handleGoalSaved"
        @edit-goal="handleEditGoal"
      />
      <WeeklyProgress v-else @edit-goal="handleEditGoal" />
    </div>
    <div class="container-card">
      <Resume v-if="!searchTerm" />
      <CardCoran
        v-for="sourate in sortedSourates"
        :key="sourate?.id"
        :Titre="sourate?.attributes?.Titre"
        :Numero="sourate?.attributes?.Numero"
        :Arabe="sourate?.attributes?.Arabe"
        :Phonetique="sourate?.attributes?.Phonetique"
        :Revelation="sourate?.attributes?.Revelation"
        :Nombre="sourate?.attributes?.Nombre"
        :like="sourate?.attributes?.like_sourate?.data?.attributes?.likes"
        :vues="sourate?.attributes?.vues || 0"
        :fav="isLiked(sourate?.attributes.Numero)"
        :taux="
          sourate?.attributes?.versets_checks?.data[0]?.attributes?.taux || 0
        "
      />
    </div>
  </div>
  <Tabs />
</template>

<script>
import { ref } from "vue";
import { mapActions, mapGetters } from "vuex";
import { Search } from "@element-plus/icons-vue";
import CardCoran from "../../components/coran/CardCoran.vue";
import CreateGoal from "../../components/coran/CreateGoal.vue";
import WeeklyProgress from "../../components/coran/WeeklyProgress.vue";
import Resume from "../../components/coran/Resume.vue";
import Tabs from "../../components/global/Tabs.vue";
import { Head } from "@vueuse/head";

export default {
  name: "coran-doonun",
  components: {
    CardCoran,
    CreateGoal,
    WeeklyProgress,
    Resume,
    Head,
    Tabs,
  },
  setup() {
    const input1 = ref("");
    const searchTerm = ref("");
    const isAscending = ref(false);
    let debounceTimeout = null;

    // Fonction de debounce manuelle
    const handleInput = (value) => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
      debounceTimeout = setTimeout(() => {
        searchTerm.value = value;
      }, 300); // 300 ms de délai
    };

    return {
      input1,
      Search,
      handleInput,
      searchTerm,
      isAscending,
    };
  },
  data() {
    return {
      showCreateGoal: false,
      isEditing: false,
    };
  },
  computed: {
    ...mapGetters("sourate", ["sourates"]),
    ...mapGetters("like", ["likes"]),
    ...mapGetters("login", ["isAuthenticated"]),

    likedSourates() {
      return this.isAuthenticated && Array.isArray(this.likes.data)
        ? this.likes.data.map((like) => like.attributes.id_sourate)
        : [];
    },

    filteredSourates() {
      if (!this.searchTerm) {
        return this.sourates;
      }

      const term = this.normalizeString(this.searchTerm);

      return this.sourates.filter((sourate) => {
        const { Titre, Numero, Arabe, Phonetique, Revelation } =
          sourate.attributes;

        return (
          (Titre && this.normalizeString(Titre).includes(term)) ||
          (Numero && Numero.toString().includes(term)) ||
          (Arabe && Arabe.includes(term)) || // L'arabe est souvent en caractères uniques
          (Phonetique && this.normalizeString(Phonetique).includes(term)) ||
          (Revelation && this.normalizeString(Revelation).includes(term))
        );
      });
    },

    sortedSourates() {
      return this.filteredSourates.slice().sort((a, b) => {
        if (this.isAscending) {
          return b.attributes.Numero - a.attributes.Numero;
        } else {
          return a.attributes.Numero - b.attributes.Numero;
        }
      });
    },
  },
  methods: {
    ...mapActions("sourate", ["fetchSourates"]),
    ...mapActions("like", ["fetchLikes"]),

    isLiked(numero) {
      return this.likedSourates.includes(String(numero));
    },
    normalizeString(str) {
      return str.toLowerCase().replace(/[^a-z0-9]/g, "");
    },
    handleGoalSaved() {
      this.showCreateGoal = false; // Masquer CreateGoal
    },
    handleEditGoal() {
      this.showCreateGoal = true; // Afficher CreateGoal
      this.isEditing = true; // Mettre en mode édition
    },
  },
  async created() {
    await this.$store.dispatch("user/fetchUser"); // Attendre la récupération de l'utilisateur
    if (this.isAuthenticated) {
      await this.fetchLikes();
    }
    this.fetchSourates(this.$i18n.locale); // Récupérer les sourates après avoir récupéré l'utilisateur et les likes
  },
  watch: {
    "$i18n.locale"() {
      this.fetchSourates(this.$i18n.locale);
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
  margin-top: 100px;
  margin-bottom: 100px;
  min-height: 100vh;
  z-index: 2;
}

h1 {
  font-size: clamp(20px, 8vw, 30px);
  text-align: center;
  margin-bottom: 20px;
}

.search {
  margin: 15px 0 5px 0;
}

.container-card {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  align-items: stretch;
  justify-content: left;
  gap: 15px;
  margin-top: 6px;
}

.switch-container {
  display: flex;
  justify-content: center;
  margin: 20px 0 0 0;
}

.check-goal {
  /* margin-bottom: 20px; */
}
</style>
