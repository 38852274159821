<template>
  <Head>
    <title>Horaires de prières - Ville - Doonun</title>
    <meta
      name="description"
      :content="`Retrouvez facilement les horaires de prières de votre ville sur Doonun.`"
    />
    <meta property="og:title" :content="`Horaires de prières - Ville`" />
    <meta property="og:url" :content="`https://www.doonun.com/priere`" />
    <meta
      property="og:description"
      :content="`Retrouvez facilement les horaires de prières de votre ville sur Doonun.`"
    />
    <meta property="og:type" content="website" />
    <meta
      property="og:image"
      content="https://cp.doonun.com/uploads/2148590247_f23daa8ddc.jpg"
    />
    <meta name="robots" content="index, follow" />

    <!-- Twitter -->
    <meta
      name="twitter:title"
      :content="`Horaires de prières - Ville - Doonun`"
    />
    <meta
      name="twitter:description"
      :content="`Retrouvez facilement les horaires de prières de votre ville sur Doonun.`"
    />
    <meta
      name="twitter:image"
      content="https://cp.doonun.com/uploads/2148590247_f23daa8ddc.jpg"
    />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@doonunfr" />
    <meta name="twitter:creator" content="@doonunfr" />
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div class="container">
    <CurrentPrayer :prayerTimes="prayerTimes" :city="`${city}, ${country}`" />
    <CitySearch @city-selected="handleCitySelected" />
    <div v-if="city && country">
      <component
        :is="currentComponent"
        :prayerTimes="prayerTimes"
        :sunnahTimes="sunnahTimes"
        :qiblaDirection="qiblaDirection"
        :city="`${city}, ${country}`"
      />
    </div>
  </div>
  <div class="tabs">
    <router-link to="/">
      <div class="home">
        <i class="fa-solid fa-house"></i>
      </div>
    </router-link>
    <div class="prays" @click="changeComponent('PrayerTimes')">
      <i
        class="fa-solid fa-mosque"
        :class="{ 'current-component': currentComponent === 'PrayerTimes' }"
      ></i>
    </div>

    <!-- <div class="qibla" @click="changeComponent('QiblaCompass')">
      <i
        class="fa-solid fa-compass"
        :class="{ 'current-component': currentComponent === 'QiblaCompass' }"
      ></i>
    </div> -->

    <div class="calendar" @click="changeComponent('Hijri')">
      <i
        class="fa-solid fa-calendar"
        :class="{ 'current-component': currentComponent === 'Hijri' }"
      ></i>
    </div>
  </div>
</template>

<script>
import Hijri from "../../components/hours/Hijri.vue";
import PrayerTimesComponent from "../../components/hours/PrayerTimes.vue";
// import QiblaCompass from "../../components/hours/QiblaCompass.vue";
import CurrentPrayer from "../../components/hours/CurrentPrayer.vue";
import CitySearch from "../../components/hours/CitySearch.vue";

export default {
  name: "HorairesDoonun",
  components: {
    CitySearch,
    CurrentPrayer,
    PrayerTimes: PrayerTimesComponent,
    // QiblaCompass,
    Hijri,
  },
  data() {
    return {
      city: "",
      country: "",
      latitude: null,
      longitude: null,
      prayerTimes: null,
      sunnahTimes: null,
      qiblaDirection: null,
      errorMessage: "",
      currentComponent: "PrayerTimes", // Composant par défaut
    };
  },
  methods: {
    changeComponent(componentName) {
      this.currentComponent = componentName;
      // Vous pouvez ajouter des actions supplémentaires ici si nécessaire
    },
    handleCitySelected(item) {
      if (
        item &&
        item.city &&
        item.country &&
        typeof item.latitude === "number" &&
        typeof item.longitude === "number"
      ) {
        this.city = item.city;
        this.country = item.country;
        this.latitude = item.latitude;
        this.longitude = item.longitude;
        this.calculatePrayerTimes();
      } else {
        console.error("Item sélectionné invalide :", item);
      }
    },
    calculatePrayerTimes() {
      if (this.latitude == null || this.longitude == null) {
        console.error(
          "Latitude et longitude doivent être définies pour calculer les horaires de prières."
        );
        return;
      }

      const {
        PrayerTimes,
        CalculationMethod,
        Coordinates,
        Qibla,
        SunnahTimes,
      } = require("adhan");

      const coordinates = new Coordinates(this.latitude, this.longitude);

      const params = CalculationMethod.MoonsightingCommittee();

      const date = new Date();
      const prayerTimes = new PrayerTimes(coordinates, date, params);
      const sunnahTimes = new SunnahTimes(prayerTimes);

      this.prayerTimes = prayerTimes;
      this.sunnahTimes = sunnahTimes;

      // Calculer la direction de la Qibla
      const qibla = Qibla(coordinates);
      this.qiblaDirection = qibla;
    },
  },
  mounted() {
    // Initialisation si nécessaire
  },
};
</script>

<style scoped>
.container {
  margin-top: 100px;
  max-width: 500px;
  min-height: 100vh;
  margin: 100px auto;
  padding: 0 20px;
}

.top {
  display: flex;
  justify-content: space-around;
}

h2 {
  display: block;
  margin-top: 20px;
  margin-right: auto;
  text-align: end;
  padding: 0 !important;
  max-width: 250px;
  margin: 20px;
  font-size: 16px;
}

p {
  text-align: center;
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  left: 0;
  position: sticky;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 5vh;
  background: rgba(255, 255, 255, 0.3) !important;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px) !important;
  max-height: 50px;
  height: 100%;
}

.tabs div {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
}

.tabs div i {
  font-size: 24px;
}

.current-component {
  color: var(--button);
}
</style>
