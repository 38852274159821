<template>
  <div class="sun-full">
    <svg
      id="brightness_11450978"
      xmlns="http://www.w3.org/2000/svg"
      width="21.248"
      height="21.248"
      viewBox="0 0 21.248 21.248"
    >
      <path
        id="Tracé_544"
        data-name="Tracé 544"
        d="M11.865,260.212v2.277a.759.759,0,1,0,1.518,0v-2.277a.759.759,0,0,0-1.518,0Zm-5.312-2.591-1.832,1.832a.759.759,0,1,0,1.073,1.073l1.832-1.832a.759.759,0,1,0-1.073-1.073Zm11.069,1.073,1.832,1.832a.759.759,0,1,0,1.073-1.073l-1.832-1.832a.759.759,0,1,0-1.073,1.073Zm-5-12.142a6.071,6.071,0,1,0,6.071,6.071A6.074,6.074,0,0,0,12.624,246.553Zm0,1.518a4.553,4.553,0,1,1-4.553,4.553A4.556,4.556,0,0,1,12.624,248.071Zm9.895,3.8H20.212a.759.759,0,1,0,0,1.518h2.277a.76.76,0,0,0,.736-.574l.005-.024.005-.027,0-.024,0-.024,0-.024,0-.024v-.075l0-.024,0-.024,0-.024,0-.024-.005-.028-.007-.027-.007-.026-.008-.027-.008-.022-.008-.021-.008-.021-.01-.021-.01-.021-.011-.02-.011-.02-.011-.02-.013-.019-.013-.019-.014-.018-.017-.021-.018-.02-.018-.02-.02-.019-.021-.02-.02-.018-.021-.017-.021-.016-.023-.016-.019-.013-.019-.011-.02-.011-.02-.011-.021-.011-.02-.009-.026-.011-.027-.01-.025-.008-.027-.008-.027-.007-.024-.005-.023,0-.024,0-.024,0-.024,0Zm-19.76,1.517H5.035a.759.759,0,1,0,0-1.518H2.759a.759.759,0,1,0,0,1.518Zm15.936-5.757,1.832-1.832a.759.759,0,0,0-1.073-1.073l-1.832,1.832a.759.759,0,0,0,1.073,1.073ZM7.626,246.553l-1.832-1.832a.759.759,0,0,0-1.073,1.073l1.832,1.832a.759.759,0,0,0,1.073-1.073Zm4.239-3.794v2.277a.759.759,0,1,0,1.518,0v-2.277a.759.759,0,0,0-1.518,0Z"
        transform="translate(-2 -242)"
        fill-rule="evenodd"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "sun-full-icon",
};
</script>

<style scoped>
.sun-full {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sun-full svg {
  width: 20px;
  height: 20px;
}

.sun-full svg path {
  fill: var(--link);
}

.current-prayer svg path {
  fill: #fff;
}
</style>
