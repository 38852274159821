<template>
  <div class="container">
    <section class="how-it-works">
      <h2 class="title">{{ $t("titleHow") }}</h2>
      <div class="steps">
        <div class="step">
          <div class="step-number">1</div>
          <div class="step-content">
            <h3 class="step-title">{{ $t("titleHow1") }}</h3>
            <p class="step-description">{{ $t("descHow1") }}</p>
          </div>
        </div>
        <div class="step">
          <div class="step-number">2</div>
          <div class="step-content">
            <h3 class="step-title">{{ $t("titleHow2") }}</h3>
            <p class="step-description">{{ $t("descHow2") }}</p>
          </div>
        </div>
        <div class="step">
          <div class="step-number">3</div>
          <div class="step-content">
            <h3 class="step-title">{{ $t("titleHow3") }}</h3>
            <p class="step-description">{{ $t("descHow3") }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "HowItWorks",
  data() {
    return {};
  },
};
</script>

<style scoped>
.how-it-works {
  display: block;
  padding: 50px 0px;
  text-align: center;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}
.how-it-works .title {
  font-size: 2rem;
  margin-bottom: 30px;
  font-weight: bold;
  color: var(--color);
}
.steps {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.step {
  display: flex;
  /* align-items: center; */
  gap: 15px;
  background: var(--card);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.step-number {
  font-size: 1.5rem;
  font-weight: bold;
  color: #4caf50;
  background: #e8f5e9;
  padding: 10px;
  border-radius: 50%;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.step-content {
  text-align: left;
}
.step-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: var(--color);
}
.step-description {
  font-size: 0.95rem;
  color: var(--link);
}
@media (min-width: 1000px) {
  .steps {
    flex-direction: row;
  }
  .step {
    flex: 1;
  }
}
</style>
