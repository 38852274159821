<template>
  <div>
    <div class="free" v-if="free === true">
      <span>{{ $t("free") }}</span>
    </div>
    <div class="sub" v-if="free === false">
      <i class="fa-solid fa-lock"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "free-d",
  props: {
    free: Boolean,
  },
};
</script>

<style scoped>
.sub {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 25px;
  min-height: 25px;
  max-width: 25px;
  max-height: 25px;
  padding: 10px;
  border-radius: 3px;
  background: #ffbf00;
  color: #fff;
  font-size: 12px;
}

.free {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  min-height: 25px;
  background: #ff4444;
  border-radius: 3px;
  padding: 4px 6px;
}

.free span {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: #fff;
}
</style>
