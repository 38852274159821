<template>
  <div class="goal-container">
    <div class="goal-input">
      <div class="goal-header">
        <span class="goal-icon">
          <img src="https://cp.doonun.com/uploads/goal_762e46e5b1.png" alt="" />
        </span>
        <div class="goal-text">
          <h2 v-if="isEditing">
            Combien de verset voulez-vous lire par jour ?
          </h2>
          <p v-if="!isEditing && goalSet">
            Lire {{ goalValue }} versets par jour.
          </p>
        </div>
      </div>
      <div class="goal-actions">
        <template v-if="isEditing">
          <input
            type="number"
            v-model.number="goalValue"
            min="0"
            max="100"
            class="goal-number"
            placeholder="Objectif"
          />
          <button @click="saveGoal" class="ok-button" :disabled="!canSave">
            OK
          </button>
        </template>
        <template v-else-if="goalSet">
          <button @click="editGoal" class="modify-button">
            <el-icon><EditPen /></el-icon>
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CreateGoal",
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      goalValue: 0,
      goalSet: false, // Indique si un objectif a été défini
    };
  },
  computed: {
    canSave() {
      return this.goalValue > 0;
    },
  },
  methods: {
    ...mapActions("check", ["createOrUpdateDailyGoal"]),
    async saveGoal() {
      try {
        await this.createOrUpdateDailyGoal({ goalValue: this.goalValue });
        this.goalSet = this.goalValue > 0;
        this.$emit("goal-saved"); // Émettre un événement au parent
      } catch (error) {
        console.error("Erreur lors de la sauvegarde de l'objectif :", error);
      }
    },
    editGoal() {
      this.$emit("edit-goal"); // Émettre un événement au parent
    },
    async fetchCurrentGoal() {
      try {
        const goal = await this.$store.dispatch("check/getCurrentDailyGoal");
        if (goal && goal > 0) {
          this.goalValue = goal;
          this.goalSet = true;
        } else {
          this.goalSet = false;
          // L'édition est gérée par le parent
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de l'objectif actuel :",
          error
        );
      }
    },
  },
  created() {
    // Initialiser l'état en récupérant l'objectif actuel
    this.fetchCurrentGoal();
  },
};
</script>

<style scoped>
.goal-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 5px 0;
}

.goal-input {
  background: var(--bgV);
  border-radius: 6px;
  padding: 5px 6px 5px 5px;
  border: 1px solid var(--borderV);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 640px;
}

.goal-header {
  display: flex;
  align-items: center;
  gap: 5px;
}

.goal-icon {
  min-width: 35px;
  min-height: 35px;
  max-width: 35px;
  max-height: 35px;
}

.goal-icon img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.goal-text h2 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.1rem;
  margin: 0;
}

.goal-text p {
  opacity: 0.9;
  font-size: 14px;
}

.goal-actions {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.goal-number {
  width: 40px;
  height: 40px;
  text-align: center;
  border: 1px solid var(--borderV);
  border-radius: 8px;
  font-size: 1rem;
}

.goal-number::-webkit-outer-spin-button,
.goal-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.goal-number[type="number"] {
  -moz-appearance: textfield;
}

.ok-button {
  background-color: var(--button);
  color: var(--colorBtn);
  border: none;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.ok-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.modify-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--button);
  border: none;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-weight: bold;
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
}

.modify-button i {
  color: var(--colorBtn);
  font-size: 16px;
}

@media (max-width: 401px) {
  .goal-icon {
    min-width: 25px;
    min-height: 25px;
    max-width: 25px;
    max-height: 25px;
  }

  .goal-text h2 {
    font-size: 13px;
  }

  .goal-actions {
    gap: 5px;
  }

  .goal-number,
  .modify-button,
  .ok-button {
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    font-size: 12px;
  }
}
</style>
