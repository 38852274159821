<template>
  <router-link :to="`/articles/${lien}`">
    <article class="box-post">
      <img :src="`https://cp.doonun.com${img}`" :alt="title" />
      <span>{{ date }}</span>
      <h3>{{ title }}</h3>
      <p>{{ desc }}</p>
      <span>{{ cat }}</span>
    </article>
  </router-link>
</template>

<script>
export default {
  name: "default-post",
  props: {
    title: String,
    desc: String,
    cat: String,
    img: String,
    lien: String,
    date: String,
  },
};
</script>

<style scoped>
.box-post {
  width: 100%;
  min-width: 315px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.box-post img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 260px;
  border-radius: 6px;
}

.box-post p,
.box-post h3 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.box-post {
  font-size: 14px;
}
</style>
