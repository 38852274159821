<template>
  <div class="container">
    <div>
      <video ref="videoRef" controls></video>
      <br />
      <button @click="transcode" :disabled="!loaded">
        Transcoder webm en mp4 avec texte
      </button>
      <p ref="messageRef"></p>
      <p>
        Ouvrez les outils de développement (Ctrl+Shift+I) pour voir les logs
      </p>
    </div>
    <button @click="load" :disabled="loaded">
      Charger ffmpeg-core (~31 MB)
    </button>
  </div>
</template>
<script>
export default {
  name: "FfmpegProcessor",
};
</script>
