<template>
  <router-link :to="link">
    <Left />
  </router-link>
</template>

<script>
import Left from "../icons/Left.vue";
export default {
  name: "back-Doonun",
  components: { Left },
  props: {
    link: String,
  },
  methods: {},
};
</script>

<style scoped>
a {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  background: var(--card);
  border-radius: 50%;
}
</style>
