// Store Vuex
import axios from "axios";

const state = {
  articles: null,
  articles4: null,
  article: null,
  selectedArticle: null,
  categories: null,
  categories10: null,
  currentCategory: null,
  articlesByCategory: null,
  topArticles: null,
  searchResults: [],
  searchCache: {},
  currentPage: 1,
  totalPages: 1,
};

const getters = {
  articlesList: (state) => state.articles,
  articles4List: (state) => state.articles4,
  articleLast: (state) => state.article,
  articleDetails: (state) => state.selectedArticle,
  categoriesList: (state) => state.categories,
  categoriesList10: (state) => state.categories10,
  currentCategory: (state) => state.currentCategory,
  articlesByCategoryList: (state) => state.articlesByCategory,
  topArticlesList: (state) => state.topArticles,
  searchResultsList: (state) => state.searchResults,
  currentPage: (state) => state.currentPage,
  totalPages: (state) => state.totalPages,
};

const mutations = {
  SET_ARTICLES(state, articles) {
    state.articles = articles;
  },
  SET_ARTICLES_4(state, articles4) {
    state.articles4 = articles4;
  },
  SET_ARTICLE(state, article) {
    state.article = article;
  },
  SET_SELECTED_ARTICLE(state, article) {
    state.selectedArticle = article;
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_CATEGORIES10(state, categories10) {
    state.categories10 = categories10;
  },
  SET_CURRENT_CATEGORY(state, category) {
    state.currentCategory = category;
  },
  SET_ARTICLES_BY_CATEGORY(state, articles) {
    state.articlesByCategory = articles;
  },
  SET_TOP_ARTICLES(state, topArticles) {
    state.topArticles = topArticles;
  },
  UPDATE_ARTICLE_VIEWS(state, { articleId, newViews }) {
    const article = state.articles.find((a) => a.id === articleId);
    if (article) {
      article.attributes.vues = newViews;
    }
    if (state.selectedArticle && state.selectedArticle.id === articleId) {
      state.selectedArticle.attributes.vues = newViews;
    }
  },
  SET_SEARCH_RESULTS(state, results) {
    state.searchResults = results;
  },
  ADD_SEARCH_RESULTS(state, results) {
    state.searchResults = [...state.searchResults, ...results];
  },
  SET_SEARCH_CACHE(state, { query, results }) {
    state.searchCache = {
      ...state.searchCache,
      [query]: results,
    };
  },
  SET_CURRENT_PAGE(state, page) {
    state.currentPage = page;
  },
  SET_TOTAL_PAGES(state, total) {
    state.totalPages = total;
  },
  CLEAR_SEARCH_RESULTS(state) {
    state.searchResults = [];
    state.currentPage = 1;
    state.totalPages = 1;
  },
};

const actions = {
  async fetchArticles({ commit }, lang) {
    try {
      const response = await axios.get("/articles", {
        params: {
          locale: lang,
          populate: {
            categories: {
              fields: ["id", "titre", "url", "text"],
            },
            Cover: {
              fields: ["url", "alternativeText"],
            },
          },
        },
      });
      commit("SET_ARTICLES", response.data.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des articles:", error);
    }
  },
  async fetchLatestArticle({ commit }, lang) {
    try {
      const response = await axios.get("/articles", {
        params: {
          locale: lang,
          sort: ["publishedAt:desc"], // Trier par date de publication décroissante
          pagination: {
            limit: 1, // Limiter à 1 article
          },
          populate: {
            categories: {
              fields: ["id", "titre", "url", "text"],
            },
            Cover: {
              fields: ["url", "alternativeText"],
            },
          },
        },
      });

      // console.log("last : ", response.data.data);

      const latestArticle = response.data.data[0];
      commit("SET_ARTICLE", latestArticle);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération du dernier article:",
        error
      );
    }
  },
  async fetchLates4tArticles({ commit }, lang) {
    try {
      const response = await axios.get("/articles", {
        params: {
          locale: lang,
          sort: ["publishedAt:desc"], // Trier par date de publication décroissante
          pagination: {
            limit: 5, // Limiter à 1 article
          },
          populate: {
            categories: {
              fields: ["id", "titre", "url", "text"],
            },
            Cover: {
              fields: ["url"],
            },
          },
        },
      });

      // console.log("last : ", response.data.data);

      const latest4Articles = response.data.data;
      commit("SET_ARTICLES_4", latest4Articles);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération du dernier article:",
        error
      );
    }
  },

  async fetchArticleBySlug({ commit, dispatch }, { slug, lang }) {
    try {
      const response = await axios.get(`/articles`, {
        params: {
          locale: lang,
          filters: {
            url: {
              $eq: slug,
            },
          },
          pagination: {
            limit: 1,
          },
          populate: {
            categories: {
              fields: ["id", "titre", "url", "text"],
              locale: lang,
            },
            Cover: {
              fields: ["url", "alternativeText"],
              locale: lang,
            },
          },
        },
      });
      const article = response.data.data[0]; // Extraction du premier article
      console.log("article slug : ", article);

      commit("SET_SELECTED_ARTICLE", article);

      // Vérifiez et mettez à jour les vues
      const articleId =
        article?.id !== undefined ? article.id : article?.attributes?.id;
      const currentViews = article?.attributes?.vues || 0;

      if (articleId !== undefined) {
        dispatch("updateArticleViews", { articleId, currentViews });
      } else {
        console.error("ID de l'article est undefined");
      }
    } catch (error) {
      console.error(
        "Erreur lors de la récupération de l'article par slug:",
        error
      );
      commit("SET_SELECTED_ARTICLE", null);
    }
  },
  async fetchCategories({ commit }, lang) {
    try {
      const response = await axios.get("/categories", {
        params: {
          locale: lang,

          fields: ["id", "titre", "url"],
          populate: {
            Cover: {
              fields: ["url", "formats"],
            },
          },
        },
      });
      commit("SET_CATEGORIES", response.data.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des catégories:", error);
    }
  },
  async fetchCategories10({ commit }, lang) {
    try {
      const response = await axios.get("/categories", {
        params: {
          locale: lang,
          pagination: {
            limit: 10,
          },
          fields: ["id", "titre", "url"],
          populate: {
            Cover: {
              fields: ["url", "formats"],
            },
          },
        },
      });
      commit("SET_CATEGORIES10", response.data.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des catégories:", error);
    }
  },
  async fetchArticlesByCategory({ commit }, { categoryUrl, lang }) {
    try {
      const response = await axios.get(`/categories`, {
        params: {
          locale: lang,
          filters: {
            url: {
              $eq: categoryUrl,
            },
          },
          populate: {
            articles: {
              fields: ["id", "titre", "url", "text", "Meta", "publishedAt"],
              populate: {
                Cover: {
                  fields: ["url", "formats"],
                },
                categories: {
                  fields: ["id", "titre", "url"],
                },
              },
            },
            Cover: {
              fields: ["url", "formats"],
            },
          },
        },
      });
      console.log("Réponse catégories :", response.data.data);

      // Extraction du premier élément du tableau de catégories
      const category = response.data.data[0];
      if (category && category.attributes) {
        // Commit les informations de la catégorie
        commit("SET_CURRENT_CATEGORY", category);

        // Commit les articles de la catégorie
        if (category.attributes.articles && category.attributes.articles.data) {
          commit("SET_ARTICLES_BY_CATEGORY", category.attributes.articles.data);
        } else {
          commit("SET_ARTICLES_BY_CATEGORY", []);
        }
      } else {
        commit("SET_CURRENT_CATEGORY", null);
        commit("SET_ARTICLES_BY_CATEGORY", []);
      }
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des articles par catégorie:",
        error
      );
      commit("SET_CURRENT_CATEGORY", null);
      commit("SET_ARTICLES_BY_CATEGORY", []);
    }
  },
  async updateArticleViews({ commit }, { articleId, currentViews }) {
    // console.log("ID de l'article à mettre à jour :", articleId);
    // console.log("Nombre de vues actuel :", currentViews);
    try {
      await axios.put(`/articles/${articleId}`, {
        data: {
          vues: Number(currentViews) + 1, // Incrémenter les vues directement
        },
      });
      // Mettre à jour l'état local
      commit("UPDATE_ARTICLE_VIEWS", { articleId, newViews: currentViews + 1 });
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour des vues de l'article:",
        error
      );
    }
  },
  async fetchTopArticles({ commit }, lang) {
    try {
      const response = await axios.get("/articles", {
        params: {
          locale: lang,
          sort: ["vues:desc"], // Trier par vues décroissantes
          pagination: {
            limit: 5, // Limiter à 5 articles
          },
          populate: {
            categories: {
              fields: ["id", "titre", "url", "text"],
            },
            Cover: {
              fields: ["url", "formats"],
            },
          },
        },
      });
      console.log("Articles les plus lus :", response.data.data);
      commit("SET_TOP_ARTICLES", response.data.data);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des articles les plus lus:",
        error
      );
    }
  },
  async searchArticles({ commit, state }, { query, lang, page = 1 }) {
    const cacheKey = `${query}-${page}`;

    // Vérifiez si les résultats sont déjà dans le cache
    if (state.searchCache[cacheKey]) {
      if (page === 1) {
        commit("SET_SEARCH_RESULTS", state.searchCache[cacheKey].results);
      } else {
        commit("ADD_SEARCH_RESULTS", state.searchCache[cacheKey].results);
      }
      commit("SET_CURRENT_PAGE", state.searchCache[cacheKey].page);
      commit("SET_TOTAL_PAGES", state.searchCache[cacheKey].totalPages);
      return;
    }

    try {
      const response = await axios.get("/articles", {
        params: {
          locale: lang,
          filters: {
            $or: [
              { titre: { $containsi: query } },
              { text: { $containsi: query } },
              { Meta: { $containsi: query } },
            ],
          },
          populate: {
            categories: {
              fields: ["id", "titre", "url", "text"],
            },
            Cover: {
              fields: ["url", "formats"],
            },
          },
          pagination: {
            page: page,
            pageSize: 10, // Nombre d'articles par page
          },
        },
      });

      const { data, meta } = response.data;

      if (page === 1) {
        commit("SET_SEARCH_RESULTS", data);
      } else {
        commit("ADD_SEARCH_RESULTS", data);
      }

      commit("SET_CURRENT_PAGE", meta.pagination.page);
      commit("SET_TOTAL_PAGES", meta.pagination.pageCount);

      // Mettez en cache les résultats
      commit("SET_SEARCH_CACHE", {
        query: cacheKey,
        results: data,
        page: meta.pagination.page,
        totalPages: meta.pagination.pageCount,
      });
    } catch (error) {
      console.error("Erreur lors de la recherche des articles:", error);
      if (page === 1) {
        commit("SET_SEARCH_RESULTS", []);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
