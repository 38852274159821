<template>
  <router-link :to="`/articles/${link}`">
    <article class="trends">
      <img :src="`https://cp.doonun.com${img}`" :alt="title" />
      <div class="right">
        <h3>{{ title }}</h3>
        <span>{{ date }} | {{ cat }}</span>
      </div>
    </article>
  </router-link>
</template>

<script>
export default {
  name: "trends-news",
  props: {
    title: String,
    img: String,
    link: String,
    cat: String,
    date: String,
  },
};
</script>

<style scoped>
.trends {
  display: grid;
  grid-template-columns: 90px 1fr;
  gap: 15px;
}

.trends img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.trends .right {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.trends .right h3 {
  font-size: 17px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.4rem;
}

.trends .right span {
  font-size: 14px;
}
</style>
