<template>
  <div class="tabs">
    <ul>
      <li>
        <router-link to="/">
          <Home />
        </router-link>
      </li>
      <li>
        <router-link to="/coran">
          <Coran />
        </router-link>
      </li>
      <li v-if="isAuthenticated">
        <router-link to="/fiiyd">
          <Fiiyd />
        </router-link>
      </li>
      <li>
        <router-link to="/cours">
          <Cours />
        </router-link>
      </li>
      <!-- <li v-if="isAuthenticated">
        <router-link to="/doovie">
          <Doovie />
        </router-link>
      </li> -->
      <li v-if="!isAuthenticated">
        <router-link to="/priere">
          <Salat />
        </router-link>
      </li>
      <!-- <li>
        <router-link to="/duaas">
          <Duaa />
        </router-link>
      </li> -->
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Coran from "../icons/tabs/Quran.vue";
import Cours from "../icons/tabs/Cours.vue";
import Home from "../icons/tabs/Home.vue";
// import Duaa from "../icons/tabs/Duaa.vue";
import Salat from "../icons/tabs/Salat.vue";
// import Doovie from "../icons/tabs/Doovie.vue";
import Fiiyd from "../icons/tabs/Fiiyd.vue";
export default {
  name: "tabs-d",
  components: {
    Coran,
    Cours,
    Home,
    // Duaa,
    // Doovie,
    Fiiyd,
    Salat,
  },
  computed: {
    ...mapGetters("login", ["isAuthenticated"]),
  },
};
</script>

<style scoped>
.tabs {
  display: none;
}

@media (max-width: 480px) {
  .tabs {
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    padding: 15px 20px 5vh 20px;
    background: var(--card);
    /* background: var(--optionsBackground);
    backdrop-filter: var(--options-v-blur);
    -webkit-backdrop-filter: var(--options-v-blur); */
    z-index: 998;
  }

  .tabs ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
  }

  .tabs ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  .tabs ul li a {
    display: inline-block;
  }
}
</style>
