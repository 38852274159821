<!-- Movie.vue -->
<template>
  <router-link :to="movieUrl" class="item" @click="addToResume">
    <div class="movie-card" @click="showSubscriptionDialog()">
      <div class="info" @click.prevent="handleClick">
        <el-icon><InfoFilled /></el-icon>
      </div>
      <div class="free" :class="display" v-if="free !== null">
        <Free :free="free" />
      </div>
      <img :src="`https://cp.doonun.com${image}`" :alt="title" />
      <div class="logo-md" v-if="logo">
        <img :src="`https://cp.doonun.com${logo}`" :alt="title" />
      </div>
    </div>
  </router-link>
  <PopupMovie
    :isOpen="isOpen"
    :title="title"
    :description="description"
    :image="image"
    :free="free"
    :lien="lien"
    :lang="lang"
    :note="note"
    :cc="cc"
    :categories="categories"
    @update:isOpen="isOpen = $event"
  />
  <el-dialog
    v-model="centerDialogVisible"
    :title="`${$t('warningTitleM')}`"
    width="500"
    align-center
  >
    <span>{{ $t("messageDoovie") }}</span>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Ok</el-button>
        <el-button type="primary" @click="goToSubscription">
          {{ $t("btnSub1") }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import PopupMovie from "./Popup.vue";
import Free from "../global/Free.vue";
import { mapGetters } from "vuex";
export default {
  name: "MovieCard",
  components: {
    Free,
    PopupMovie,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    free: Boolean,
    display: String,
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    logo: String,
    categories: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
      default: "",
    },
    lien: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      default: "",
    },
    note: Number,
    cc: {
      type: String,
      default: "",
    },
    episode: {
      type: Number,
      default: 1,
    },
    timer: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
      centerDialogVisible: false,
    };
  },
  computed: {
    ...mapGetters("user", ["isAuthenticated"]),
    ...mapGetters("subscribe", ["isSubscribed"]),
    movieUrl() {
      if (this.isAuthenticated) {
        if (this.isSubscribed || this.free) {
          return `/doovie/${this.id}`;
        } else {
          return "";
        }
      } else {
        return "/connexion";
      }
    },
  },
  methods: {
    togglePopup() {
      this.isOpen = !this.isOpen;
    },
    addToResume() {
      const movie = {
        id: this.id,
        title: this.title,
        image: this.image,
        lien: this.lien,
        episode: this.episode,
        timer: this.timer,
        categories: this.categories,
        description: this.description,
        lang: this.lang,
        cc: this.cc,
      };

      // Récupérer les movies sauvegardés ou initialiser un tableau vide
      let resumeMovies = JSON.parse(localStorage.getItem("resumeMovies")) || [];

      // Optionnel : Assurer l'unicité basée sur 'lien'
      resumeMovies = resumeMovies.filter((m) => m.lien !== this.lien);

      // Ajouter le nouveau movie
      resumeMovies.push(movie);

      // Limiter le tableau à 5 éléments
      if (resumeMovies.length > 5) {
        resumeMovies.shift(); // Supprime le premier élément (le plus ancien)
      }

      // Sauvegarder le tableau mis à jour dans localStorage
      localStorage.setItem("resumeMovies", JSON.stringify(resumeMovies));
    },
    handleClick() {
      this.togglePopup();
    },
    showSubscriptionDialog() {
      if (!this.isSubscribed || !this.free) {
        this.centerDialogVisible = true;
      }
    },
    goToSubscription() {
      this.centerDialogVisible = false;
      this.$router.push({ name: "tarifs" });
    },
  },
};
</script>

<style scoped>
@media (max-width: 1400px) {
  .movie-card {
    aspect-ratio: 16 / 9;
  }

  img {
    aspect-ratio: 16 / 9;
  }
}
.movie-card {
  position: relative;
  overflow: hidden;
  border-radius: 6px;
}

.info {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  background: #000;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  cursor: pointer;
}

.free {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 10px;
  margin-left: 10px;
}

.info i {
  color: #fff;
  font-size: 16px;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 0;
  z-index: 1;
  border-radius: 6px;
}

.logo-md {
  visibility: hidden;
  display: flex;
  align-items: end;
  position: absolute;
  bottom: 0;
  z-index: 3;
  left: 0;
  width: 100%;
  padding: 30px 5px;
  /* backdrop-filter: blur(15px); */
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 31%,
    rgba(0, 0, 0, 0) 100%
  );
  min-height: 150px;
}

.logo-md img {
  visibility: none;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-bottom: auto 0 0 0;
}

.display {
  display: none;
}

@media (max-width: 700px) {
  .movie-card {
    aspect-ratio: auto;
    height: 190px;
    border-radius: 6px;
  }

  .logo-md,
  .logo-md img {
    visibility: visible;
  }

  img {
    object-position: center;
    aspect-ratio: auto;
    height: 190px;
    border-radius: 6px;
  }
}
</style>
