// store/modules/resume.js

import axios from "axios";

const state = {
  checks: [],
  lastVersetDetails: null,
  nextSourahDetails: null, // <--- NOUVEL état pour la sourate suivante
  nextSourahFirstVerse: null, // <--- NOUVEL état pour le premier verset de la sourate suivante
};

const getters = {
  resume: (state) => state.checks,
  lastVersetDetails: (state) => state.lastVersetDetails,
  nextSourahDetails: (state) => state.nextSourahDetails,
  nextSourahFirstVerse: (state) => state.nextSourahFirstVerse,
};

const mutations = {
  SET_CHECKS(state, checks) {
    state.checks = checks;
  },
  SET_LAST_VERSET_DETAILS(state, verset) {
    state.lastVersetDetails = verset;
  },
  // Nouvelles mutations
  SET_NEXT_SOURAH_DETAILS(state, sourah) {
    state.nextSourahDetails = sourah;
  },
  SET_NEXT_SOURAH_FIRST_VERSE(state, verse) {
    state.nextSourahFirstVerse = verse;
  },
};

const actions = {
  /**
   * Récupère la dernière vérification et le dernier verset lu.
   * Si la sourate actuelle est terminée (taux = 100),
   * on va aussi chercher la sourate suivante pour pouvoir la commencer.
   *
   * @param {Object} context - Contexte de l'action Vuex
   * @param {String} lang - Langue pour la locale
   */
  async fetchChecks({ commit, rootGetters }, lang) {
    try {
      const userId = rootGetters["user/userId"];
      const response = await axios.get("/versets-checks", {
        params: {
          locale: lang,
          fields: ["id", "id_user", "id_sourate", "list_verset", "taux"],
          filters: { id_user: { $eq: userId } },
          sort: ["updatedAt:desc"],
          pagination: { limit: 1 },
          populate: {
            sourate: {
              fields: [
                "Arabe",
                "Numero",
                "Nombre",
                "Phonetique",
                "Revelation",
                "Titre",
              ],
              locale: lang,
            },
          },
        },
      });

      const data = response.data.data;
      if (Array.isArray(data) && data.length > 0) {
        const check = data[0].attributes;
        const idSourate = check.id_sourate;
        const listVerset = check.list_verset
          .split("|")
          .map(Number)
          .sort((a, b) => a - b);
        const lastVersetNumber = listVerset[listVerset.length - 1];
        check.lastVersetNumber = lastVersetNumber;

        // Récupérer les détails du dernier verset lu
        const versetResponse = await axios.get("/ayahs", {
          params: {
            filters: {
              sourate: { $eq: idSourate },
              numero: { $eq: lastVersetNumber },
            },
            fields: ["id", "fr", "numero"],
            locale: lang,
            pagination: { limit: 1 },
          },
        });

        const versetData = versetResponse.data.data;
        if (Array.isArray(versetData) && versetData.length > 0) {
          commit("SET_LAST_VERSET_DETAILS", versetData[0]);
        } else {
          commit("SET_LAST_VERSET_DETAILS", null);
        }

        // Si la sourate actuelle est terminée (taux = 100),
        // on récupère la sourate suivante et son premier verset
        if (check.taux === 100 && data[0].attributes?.sourate?.data) {
          const currentSourahNumber =
            data[0].attributes.sourate.data.attributes.Numero;

          // 1) Récupération des infos de la sourate suivante
          const nextSourahResponse = await axios.get("/sourates", {
            params: {
              filters: {
                Numero: { $eq: currentSourahNumber + 1 },
              },
              locale: lang,
              pagination: { limit: 1 },
            },
          });
          const nextSourahData = nextSourahResponse.data.data?.[0] || null;
          commit("SET_NEXT_SOURAH_DETAILS", nextSourahData);

          // 2) Optionnel : Récupérer le premier verset de la sourate suivante
          if (nextSourahData) {
            const idNextSourah = nextSourahData.attributes.Numero;
            const firstAyahResponse = await axios.get("/ayahs", {
              params: {
                filters: {
                  sourate: { $eq: idNextSourah },
                  numero: { $eq: 1 },
                },
                fields: ["id", "fr", "numero"],
                locale: lang,
                pagination: { limit: 1 },
              },
            });
            const firstAyahData = firstAyahResponse.data.data?.[0] || null;
            commit("SET_NEXT_SOURAH_FIRST_VERSE", firstAyahData);
          }
        } else {
          // Si le taux n'est pas 100, on nettoie juste pour éviter la confusion
          commit("SET_NEXT_SOURAH_DETAILS", null);
          commit("SET_NEXT_SOURAH_FIRST_VERSE", null);
        }
      } else {
        // Aucun check trouvé
        commit("SET_LAST_VERSET_DETAILS", null);
      }

      commit("SET_CHECKS", data || []);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des vérifications :",
        error
      );
      commit("SET_CHECKS", []);
      commit("SET_LAST_VERSET_DETAILS", null);
      commit("SET_NEXT_SOURAH_DETAILS", null);
      commit("SET_NEXT_SOURAH_FIRST_VERSE", null);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
