<template>
  <div class="sun-night">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.724"
      height="21"
      viewBox="0 0 20.724 21"
    >
      <g id="moon_14322271" transform="translate(-0.999 -49.19)">
        <g
          id="Groupe_199"
          data-name="Groupe 199"
          transform="translate(0.999 49.19)"
        >
          <path
            id="Tracé_547"
            data-name="Tracé 547"
            d="M22.925,67.56A10.567,10.567,0,1,1,11.07,54.123a.7.7,0,0,1,.722,1.051,8.1,8.1,0,0,0,10.18,11.538.7.7,0,0,1,.953.848Zm-1.868.939A9.512,9.512,0,0,1,9.9,55.858,9.157,9.157,0,1,0,21.057,68.5Z"
            transform="translate(-2.23 -54.113)"
            fill-rule="evenodd"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "sun-night-icon",
};
</script>

<style scoped>
.sun-night {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sun-night svg {
  width: 20px;
  height: 20px;
}

.sun-night svg path {
  fill: var(--link);
}

.current-prayer svg path {
  fill: #fff;
}
</style>
