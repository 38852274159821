<template>
  <div v-if="prayerTimes">
    <ul>
      <li :class="{ 'current-prayer': isCurrentPrayer('Fajr') }">
        <div class="left">
          <SunFajr />
          <span class="name">Fajr</span>
        </div>
        <div class="countdown">
          <p v-if="isCurrentPrayer('Fajr')">{{ countdown }}</p>
        </div>
        <div class="time">
          <p class="hour">{{ formatTime(prayerTimes.fajr) }}</p>
          <div class="bell"></div>
        </div>
      </li>
      <li :class="{ 'current-prayer': isCurrentPrayer('sunrise') }">
        <div class="left">
          <SunUp />
          <span class="name">Shuruk</span>
        </div>
        <div class="countdown">
          <p v-if="isCurrentPrayer('sunrise')">{{ countdown }}</p>
        </div>
        <div class="time">
          <p class="hour">{{ formatTime(prayerTimes.sunrise) }}</p>
          <div class="bell"></div>
        </div>
      </li>
      <li :class="{ 'current-prayer': isCurrentPrayer('Dhuhr') }">
        <div class="left">
          <SunFull />
          <span class="name">Dhuhr</span>
        </div>
        <div class="countdown">
          <p v-if="isCurrentPrayer('Dhuhr')">{{ countdown }}</p>
        </div>
        <div class="time">
          <p class="hour">{{ formatTime(prayerTimes.dhuhr) }}</p>
          <div class="bell"></div>
        </div>
      </li>
      <li :class="{ 'current-prayer': isCurrentPrayer('Asr') }">
        <div class="left">
          <SunCloud />
          <span class="name">Asr</span>
        </div>
        <div class="countdown">
          <p v-if="isCurrentPrayer('Asr')">{{ countdown }}</p>
        </div>
        <div class="time">
          <p class="hour">{{ formatTime(prayerTimes.asr) }}</p>
          <div class="bell"></div>
        </div>
      </li>
      <li :class="{ 'current-prayer': isCurrentPrayer('Maghrib') }">
        <div class="left">
          <SunDown />
          <span class="name">Maghrib</span>
        </div>
        <div class="countdown">
          <p v-if="isCurrentPrayer('Maghrib')">{{ countdown }}</p>
        </div>
        <div class="time">
          <p class="hour">{{ formatTime(prayerTimes.maghrib) }}</p>
          <div class="bell"></div>
        </div>
      </li>
      <li :class="{ 'current-prayer': isCurrentPrayer('Isha') }">
        <div class="left">
          <SunNight />
          <span class="name">Isha</span>
        </div>
        <div class="countdown"></div>
        <div class="time">
          <p class="hour">{{ formatTime(prayerTimes.isha) }}</p>
          <div class="bell"></div>
        </div>
      </li>
      <li class="sunnah">
        <div class="left">
          <SunNight />
          <span class="name">{{ $t("mNight") }}</span>
        </div>

        <div class="time">
          <p class="hour">{{ formatTime(sunnahTimes.middleOfTheNight) }}</p>
          <div class="bell"></div>
        </div>
      </li>
      <li class="sunnah">
        <div class="left">
          <SunNight />
          <span class="name">{{ $t("lNight") }}</span>
        </div>
        <div class="time">
          <p class="hour">
            {{ formatTime(sunnahTimes.lastThirdOfTheNight) }}
          </p>

          <div class="bell"></div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from "moment-timezone";
import SunFajr from "@/components/icons/priere/SunFajr.vue";
import SunCloud from "@/components/icons/priere/SunCloud.vue";
import SunDown from "@/components/icons/priere/SunDown.vue";
import SunUp from "@/components/icons/priere/SunUp.vue";
import SunNight from "@/components/icons/priere/SunNight.vue";
import SunFull from "@/components/icons/priere/SunFull.vue";
import { Prayer } from "adhan";

export default {
  name: "PrayerTimes",
  components: {
    SunFajr,
    SunCloud,
    SunDown,
    SunFull,
    SunNight,
    SunUp,
  },
  props: {
    prayerTimes: {
      type: Object,
      required: true,
    },
    sunnahTimes: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      countdown: "00:00:00",
      nextPrayer: null,
      timer: null,
    };
  },
  computed: {
    formattedDate() {
      return moment().format("DD MMMM, YYYY");
    },
    currentPrayerName() {
      if (!this.prayerTimes) return "Aucune prière en cours";
      const currentPrayer = this.prayerTimes.currentPrayer();
      return this.prayerName(currentPrayer);
    },
    currentPrayerTime() {
      if (!this.prayerTimes) return "N/A";
      const currentPrayer = this.prayerTimes.currentPrayer();
      switch (currentPrayer) {
        case Prayer.Fajr:
          return this.formatTime(this.prayerTimes.fajr);
        case Prayer.Shuruk:
          return this.formatTime(this.prayerTimes.sunrise);
        case Prayer.Dhuhr:
          return this.formatTime(this.prayerTimes.dhuhr);
        case Prayer.Asr:
          return this.formatTime(this.prayerTimes.asr);
        case Prayer.Maghrib:
          return this.formatTime(this.prayerTimes.maghrib);
        case Prayer.Isha:
          return this.formatTime(this.prayerTimes.isha);
        case Prayer.None:
        default:
          return "N/A";
      }
    },
  },
  methods: {
    formatTime(date) {
      if (!date) return "N/A";
      const timezone = moment.tz.guess();
      return moment(date).tz(timezone).format("HH:mm");
    },
    prayerName(prayer) {
      switch (prayer) {
        case Prayer.Fajr:
          return "Fajr";
        case Prayer.Shuruk:
          return "Shuruk";
        case Prayer.Dhuhr:
          return "Dhuhr";
        case Prayer.Asr:
          return "Asr";
        case Prayer.Maghrib:
          return "Maghrib";
        case Prayer.Isha:
          return "Isha";
        default:
          return "Aucune prière en cours";
      }
    },
    isCurrentPrayer(prayerName) {
      return this.currentPrayerName === prayerName;
    },
    determineNextPrayer() {
      const now = moment();
      const timezone = moment.tz.guess();
      const prayers = [
        { name: "Fajr", time: moment(this.prayerTimes.fajr).tz(timezone) },
        {
          name: "Shuruk",
          time: moment(this.prayerTimes.sunrise).tz(timezone),
        },
        { name: "Dhuhr", time: moment(this.prayerTimes.dhuhr).tz(timezone) },
        { name: "Asr", time: moment(this.prayerTimes.asr).tz(timezone) },
        {
          name: "Maghrib",
          time: moment(this.prayerTimes.maghrib).tz(timezone),
        },
        { name: "Isha", time: moment(this.prayerTimes.isha).tz(timezone) },
      ];

      // Trouver la prochaine prière après maintenant
      for (let i = 0; i < prayers.length; i++) {
        if (now.isBefore(prayers[i].time)) {
          this.nextPrayer = prayers[i];
          return;
        }
      }

      // Si aucune prière restante aujourd'hui, la prochaine est Fajr demain
      this.nextPrayer = {
        name: "Fajr",
        time: moment(this.prayerTimes.fajr).add(1, "day").tz(timezone),
      };
    },
    updateCountdown() {
      if (!this.nextPrayer) return;

      const now = moment();
      const duration = moment.duration(this.nextPrayer.time.diff(now));

      if (duration.asSeconds() <= 0) {
        // Si le temps est écoulé, déterminer la prochaine prière
        this.determineNextPrayer();
        return;
      }

      const hours = Math.floor(duration.asHours()).toString().padStart(2, "0");
      const minutes = duration.minutes().toString().padStart(2, "0");
      const seconds = duration.seconds().toString().padStart(2, "0");

      this.countdown = `${hours}:${minutes}:${seconds}`;
    },
  },
  mounted() {
    this.determineNextPrayer();
    this.updateCountdown();
    this.timer = setInterval(() => {
      this.updateCountdown();
    }, 1000);
  },
  beforeUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  watch: {
    prayerTimes: {
      handler() {
        this.determineNextPrayer();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
h3 {
  text-align: center;
}

ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  max-width: 500px;
  width: 100%;
  gap: 10px;
}

li {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background: var(--card);
  border-radius: 30px;
}

.sunnah {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

li .left {
  display: flex;
  align-items: center;
  gap: 10px;
  /* max-width: 100px; */
  width: 100%;
}

li .name {
  font-weight: 500;
}

.time {
  display: flex;
  justify-content: end;
  /* max-width: 100px; */
  width: 100%;
}

.hour {
  font-size: 16px;
  font-weight: 800;
}

.current-prayer {
  background-color: var(--button);
  color: #fff;
}

/* Styles pour le compte à rebours */
.countdown {
  display: flex;
  justify-content: center;
}

.countdown p {
  font-size: 16px;
  font-weight: 700;
}
</style>
