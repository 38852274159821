<template>
  <div class="box-statistique">
    <div class="close" @click="closeStat">
      <el-icon><Close /></el-icon>
    </div>
    <div class="total-day">
      <h2 class="total">
        {{ todayVersesCount || 0 }}
      </h2>
    </div>
    <div class="filters">
      <p>Aujourd'hui</p>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item @click="updatePeriod('week')">
          <span :class="{ active: selectedPeriod === 'week' }">Semaine</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item @click="updatePeriod('month')">
          <span :class="{ active: selectedPeriod === 'month' }">Mois</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item @click="updatePeriod('year')">
          <span :class="{ active: selectedPeriod === 'year' }">Année</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="statistique-goal">
      <div>
        <Line v-if="loaded" :data="chartData" :options="chartOptions" />
      </div>
    </div>
  </div>
</template>

<script>
// import { ArrowRight } from "@element-plus/icons-vue";
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { mapGetters, mapActions } from "vuex";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "StatistiqueGoal",
  components: { Line },
  data() {
    return {
      selectedPeriod: "week", // par défaut
      loaded: false,
      chartData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  computed: {
    ...mapGetters("goalCheck", ["getGoalChecks"]),

    /**
     * Calcule le total des versets lus aujourd'hui
     */
    todayVersesCount() {
      const today = new Date();
      const todayStart = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      const todayEnd = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 1
      );

      return this.getGoalChecks
        .filter((check) => {
          const checkDate = new Date(check.createdAt);
          return checkDate >= todayStart && checkDate < todayEnd;
        })
        .reduce((sum, check) => sum + check.versesCount, 0);
    },
  },
  methods: {
    ...mapActions("goalCheck", ["fetchGoalChecks"]),

    async updatePeriod(period) {
      this.selectedPeriod = period;
      this.loaded = false;
      try {
        await this.fetchGoalChecks({ period });
        this.chartData = this.prepareAggregatedChartData(
          this.getGoalChecks,
          period
        );
        this.loaded = true;
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Regroupe les données en fonction de la période sélectionnée
     */
    prepareAggregatedChartData(goalChecks, period) {
      // 1. Grouper par jour (si "week"), par semaine (si "month") ou par mois (si "year")
      const groupedData = this.groupDataByPeriod(goalChecks, period);

      // 2. Extraire les labels et les valeurs
      const labels = Object.keys(groupedData);
      const versesCount = Object.values(groupedData);

      // 3. Construire le chartData final
      return {
        labels,
        datasets: [
          {
            label: "Versets lus",
            data: versesCount,
            borderColor: "rgba(75, 192, 192, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            lineTension: 0.2,
            fill: true,
          },
        ],
      };
    },

    /**
     * Retourne un objet { label: totalVersesCount } selon la période
     */
    groupDataByPeriod(goalChecks, period) {
      // Objet final ex: { "15/02": 10, "16/02": 17 }
      const aggregated = {};

      goalChecks.forEach((check) => {
        const date = new Date(check.createdAt);
        let labelKey = "";

        switch (period) {
          case "week": {
            // Grouper par jour (jj/mm)
            labelKey = `${date.getDate()}/${date.getMonth() + 1}`;
            break;
          }
          case "month": {
            // Grouper par semaine dans le mois
            const weekNumber = Math.ceil(date.getDate() / 7);
            labelKey = `S${weekNumber}`;
            break;
          }
          case "year": {
            // Grouper par mois (en abréviation ou en nombre)
            const monthNumber = date.getMonth();
            const monthsLabels = [
              "Jan",
              "Fév",
              "Mar",
              "Avr",
              "Mai",
              "Juin",
              "Juil",
              "Aoû",
              "Sep",
              "Oct",
              "Nov",
              "Déc",
            ];
            labelKey = monthsLabels[monthNumber];
            break;
          }
        }

        // On additionne les versesCount dans le groupe correspondant
        if (!aggregated[labelKey]) {
          aggregated[labelKey] = 0;
        }
        aggregated[labelKey] += check.versesCount;
      });

      return aggregated;
    },

    closeStat() {
      this.$emit("close");
    },
  },
  async mounted() {
    // Chargement initial en "week" par défaut
    try {
      await this.fetchGoalChecks({ period: this.selectedPeriod });
      this.chartData = this.prepareAggregatedChartData(
        this.getGoalChecks,
        this.selectedPeriod
      );
      this.loaded = true;
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

<style scoped>
.box-statistique {
  position: relative;
  display: block;
  max-width: 640px;
  margin: 0 auto;
  background: var(--truncated);
  border: 1px solid var(--button);
  padding: 10px 20px 20px 20px;
  border-radius: 6px;
  margin-bottom: 15px;
}

.close {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  width: 25px;
  height: 25px;
  background: var(--card);
  border-radius: 6px;
  transition: 0.2s ease-in;
}

.close i {
  color: var(--color);
  opacity: 0.7;
}

.close:hover {
  background: var(--color);
}

.close:hover i {
  color: #fff;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
}

.filters p {
  font-size: 14px;
  font-weight: 300;
  color: var(--color);
  opacity: 0.8;
}

.total-day .total {
  font-size: 2em;
  font-weight: bold;
  margin: 0;
}

.filters span {
  cursor: pointer;
}

.filters .active,
.filters span:hover {
  font-weight: 700;
  color: var(--color);
}

.statistique-goal > div {
  position: relative;
  height: 400px;
}
</style>
