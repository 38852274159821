<template>
  <div class="sun">
    <svg
      id="weather_15742818"
      xmlns="http://www.w3.org/2000/svg"
      width="22.766"
      height="21.248"
      viewBox="0 0 22.766 21.248"
    >
      <g id="Groupe_196" data-name="Groupe 196" transform="translate(0 0)">
        <path
          id="Tracé_543"
          data-name="Tracé 543"
          d="M494.26,16.464a7.589,7.589,0,1,1,2.417,14.783s-9.869,0-9.876-.014l-.047,0a5.612,5.612,0,0,1-1.59-.356,5.692,5.692,0,0,1,.818-10.881,4.553,4.553,0,1,1,8.278-3.53Zm-6.925,4.921-.143,0a4.174,4.174,0,0,0-.322,8.335c.03,0,9.807.012,9.807.012a6.071,6.071,0,1,0-5.795-7.886.732.732,0,0,1-.1.2.758.758,0,0,1-1.084.177q-.06-.046-.122-.088a4.145,4.145,0,0,0-2.244-.748Zm5.51-4.276a3.035,3.035,0,1,0-4.955,2.8,5.66,5.66,0,0,1,1.892.581,7.627,7.627,0,0,1,3.063-3.379Zm-9.827,1.238a.759.759,0,0,1,0-1.518h.759a.759.759,0,0,1,0,1.518Zm6.071-7.589a.759.759,0,0,1,1.518,0v.759a.759.759,0,0,1-1.518,0Zm-4.867,2.277a.759.759,0,1,1,1.073-1.073l.537.536a.759.759,0,0,1-1.074,1.074ZM494.4,11.963a.759.759,0,1,1,1.073,1.073l-.536.537a.759.759,0,0,1-1.074-1.074Z"
          transform="translate(-481.5 -10)"
          fill-rule="evenodd"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "sun-icon",
};
</script>

<style scoped>
.sun {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sun svg {
  width: 20px;
  height: 20px;
}

.sun svg path {
  fill: var(--link);
}

.current-prayer svg path {
  fill: #fff;
}
</style>
