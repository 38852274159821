<!-- src/components/CurrentPrayer.vue -->
<template>
  <div class="infos" @click="centerDialogVisible = true">
    <i class="fa-solid fa-question"></i>
  </div>
  <div class="box-current">
    <div class="current-prayer">
      <span class="prayer-name">{{ currentPrayerName }} {{ $t("a") }}</span>
      <p class="time-pray">{{ currentPrayerTime }}</p>
      <!-- Ajout de la date du jour -->
      <p class="current-date">{{ formattedDate }}</p>
    </div>
    <div class="before"></div>
    <div class="after"></div>
  </div>

  <el-dialog
    :title="''"
    v-model="centerDialogVisible"
    width="90%"
    destroy-on-close
    start
  >
    <div class="dialog-content">
      <p
        id="markdown"
        class="markdown-body"
        v-html="$md.render(String($t('infoPrays')))"
      ></p>
    </div>
  </el-dialog>
</template>

<script>
import { ref } from "vue";
import { Prayer } from "adhan";
import moment from "moment-timezone";
// Importation de la locale française pour moment
import "moment/locale/fr";

export default {
  name: "CurrentPrayer",
  props: {
    prayerTimes: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const centerDialogVisible = ref(false);
    return { centerDialogVisible };
  },
  computed: {
    // Utilisation de la locale française
    currentLocale() {
      moment.locale("fr");
      return moment.locale();
    },
    // Obtention de la prochaine prière au lieu de la prière actuelle
    currentPrayerName() {
      if (!this.prayerTimes) return "Aucune prière en cours";
      const nextPrayer = this.prayerTimes.nextPrayer();
      return this.prayerName(nextPrayer);
    },
    // Obtention de l'heure de la prochaine prière
    currentPrayerTime() {
      if (!this.prayerTimes) return "N/A";
      const nextPrayer = this.prayerTimes.nextPrayer();
      switch (nextPrayer) {
        case Prayer.Fajr:
          return this.formatTime(this.prayerTimes.fajr);
        case Prayer.Sunrise:
          return this.formatTime(this.prayerTimes.sunrise);
        case Prayer.Dhuhr:
          return this.formatTime(this.prayerTimes.dhuhr);
        case Prayer.Asr:
          return this.formatTime(this.prayerTimes.asr);
        case Prayer.Maghrib:
          return this.formatTime(this.prayerTimes.maghrib);
        case Prayer.Isha:
          return this.formatTime(this.prayerTimes.isha);
        case Prayer.None:
        default:
          return this.formatTime(this.prayerTimes.fajr);
      }
    },
    // Propriété calculée pour formater la date actuelle
    formattedDate() {
      return moment().format("DD MMM");
    },
  },
  methods: {
    prayerName(prayer) {
      switch (prayer) {
        case Prayer.Fajr:
          return "Fajr";
        case Prayer.Sunrise:
          return "Shuruk";
        case Prayer.Dhuhr:
          return "Dhuhr";
        case Prayer.Asr:
          return "Asr";
        case Prayer.Maghrib:
          return "Maghrib";
        case Prayer.Isha:
          return "Isha";
        case Prayer.None:
        default:
          return "Fajr";
      }
    },
    formatTime(date) {
      if (!date) return "N/A";
      const timezone = moment.tz.guess();
      return moment(date).tz(timezone).format("HH:mm");
    },
  },
  // Rafraîchissement périodique pour mettre à jour la prière en cours
  mounted() {
    this.updatePrayer();
    this.timer = setInterval(this.updatePrayer, 60000); // Mise à jour toutes les minutes
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.box-current {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 500px;
  max-height: 310px;
  min-width: 100%;
  min-height: 310px;
}

.current-prayer {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  gap: 10px;
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 200px;
  min-width: 200px;
  min-height: 200px;
  background: var(--button);
  border-radius: 50%;
  color: #fff;
  z-index: 3;
}

.box-current .after,
.box-current .before {
  position: absolute;
  background: var(--button);
  border-radius: 50%;
  margin: 0 auto;
}
.box-current .before {
  z-index: 1;
  width: 250px;
  height: 250px;
  max-width: 250px;
  max-height: 250px;
  min-width: 250px;
  min-height: 250px;
  opacity: 0.3;
}
.box-current .after {
  z-index: 2;
  width: 310px;
  height: 310px;
  max-width: 310px;
  max-height: 310px;
  min-width: 310px;
  min-height: 310px;
  opacity: 0.2;
}

.prayer-name {
  font-size: 18px;
}

.current-prayer .time-pray {
  margin-top: -10px;
  margin-bottom: -10px;
  font-size: calc(3rem - 10px);
  font-weight: 800;
  line-height: 1.2em;
}

.infos {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 80px;
  margin-right: 20px;
  width: 40px;
  height: 40px;
  background: var(--card);
  border-radius: 50%;
}

.infos i {
  font-size: 16px;
  color: var(--color) !important;
}

.current-date {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}
</style>
