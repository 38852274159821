<template>
  <div class="sun-cloud">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
    >
      <g id="cloudy_14322295" transform="translate(-146 -2)">
        <g id="Groupe_197" data-name="Groupe 197" transform="translate(146 2)">
          <path
            id="Tracé_545"
            data-name="Tracé 545"
            d="M165.147,13.645A4.309,4.309,0,0,1,167,17.188v.031A3,3,0,0,1,165.875,23h-13.5a3.375,3.375,0,0,1-.606-6.7,4.879,4.879,0,0,1,3.4-5.869,5.251,5.251,0,1,1,9.98,3.21ZM155.011,12.1a3.375,3.375,0,0,0-1.518,4.562.75.75,0,0,1-.667,1.092h-.451a1.875,1.875,0,0,0,0,3.75h13.5a1.5,1.5,0,0,0,.219-2.984.75.75,0,0,1-.632-.865,2.815,2.815,0,0,0-2.544-3.267c-.076-.006-.153-.009-.23-.009a2.8,2.8,0,0,0-1.687.563.75.75,0,0,1-1.187-.457,3.376,3.376,0,0,0-4.8-2.385Zm8.77.919a3.751,3.751,0,0,0-6.973-2.755,4.883,4.883,0,0,1,4.181,2.963,4.323,4.323,0,0,1,2.791-.208ZM159.5,2.75a.75.75,0,0,1,1.5,0v1.5a.75.75,0,0,1-1.5,0Zm6.583,2.106a.75.75,0,1,1,1.061,1.061l-1.061,1.06a.75.75,0,0,1-1.061-1.061ZM153.356,5.917a.75.75,0,0,1,1.061-1.061l1.061,1.061a.75.75,0,0,1-1.061,1.06ZM167.75,12.5a.75.75,0,0,1,0-1.5h1.5a.75.75,0,0,1,0,1.5Z"
            transform="translate(-149 -2)"
            fill="#40404d"
            fill-rule="evenodd"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "sun-cloud-icon",
};
</script>

<style scoped>
.sun-cloud {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sun-cloud svg {
  width: 20px;
  height: 20px;
}

.sun-cloud svg path {
  fill: var(--color);
}

.current-prayer svg path {
  fill: #fff;
}
</style>
