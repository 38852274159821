<template>
  <el-button
    type="none"
    style="padding: 0; border: none"
    @click="drawer = true"
  >
    <Hamber />
  </el-button>

  <el-drawer v-model="drawer" title="Doonun" size="70%" direction="ltr">
    <ul>
      <router-link to="/" @click="closeDrawer">
        <li>
          {{ $t("home") }}
        </li>
      </router-link>
      <router-link to="/coran" @click="closeDrawer">
        <li>
          {{ $t("quran") }}
        </li>
      </router-link>
      <router-link to="/versets" @click="closeDrawer">
        <li>
          {{ $t("pageSearch") }}
        </li>
      </router-link>
      <router-link to="/articles" @click="closeDrawer">
        <li>
          {{ $t("news") }}
        </li>
      </router-link>
      <router-link to="/histoire" @click="closeDrawer">
        <li>
          {{ $t("timeline") }}
        </li>
      </router-link>
      <router-link to="/priere" @click="closeDrawer">
        <li>
          {{ $t("pray") }}
        </li>
      </router-link>
      <router-link to="/fiiyd" @click="closeDrawer">
        <li>Fiiyd</li>
      </router-link>
      <router-link to="/doovie" @click="closeDrawer">
        <li>Doovie</li>
      </router-link>
      <router-link to="/cours" @click="closeDrawer">
        <li>
          {{ $t("course") }}
        </li>
      </router-link>
      <router-link to="/livres" @click="closeDrawer">
        <li>
          {{ $t("books") }}
        </li>
      </router-link>
      <router-link to="/duaas" @click="closeDrawer">
        <li>
          {{ $t("duaa") }}
        </li>
      </router-link>
    </ul>
  </el-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import Hamber from "../icons/Hamber.vue";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "menu-doonun",
  setup() {
    const drawer = ref(false);
    const route = useRoute();

    watch(route, () => {
      drawer.value = false;
    });

    return { drawer };
  },
  components: { Hamber },
  computed: {
    ...mapGetters("login", ["isAuthenticated"]),
  },
};
</script>

<style scoped>
.el-overlay {
  z-index: 999 !important;
}

ul {
  display: flex;
  flex-direction: column;
  width: 100%;
}

ul li {
  font-size: 16px;
  width: 100%;
  padding: 10px 0 10px 0;
  border-bottom: 1px dotted var(--color);
}

ul li a {
  width: 100% !important;
}
</style>
