<template>
  <div class="progress-container" @click="showStatistics = true">
    <div class="progress-tracker">
      <!-- Icône de gauche -->
      <div class="progress-header">
        <span class="progress-icon">
          <img src="https://cp.doonun.com/uploads/goal_762e46e5b1.png" alt="" />
        </span>
        <!-- Barre de progression + cercles -->
        <div class="days-grid">
          <div
            v-for="day in lastSevenDays"
            :key="day.dateKey"
            class="day-item"
            :class="computeDayClass(day)"
          >
            <!-- Affiche l'icône "Check" si la classe est "completed" -->
            <span v-if="computeDayClass(day) === 'completed'" class="day-check">
              <el-icon><Check /></el-icon>
            </span>
            <!-- Affiche l'icône "Close" si la classe est "day-x" -->
            <span v-else-if="computeDayClass(day) === 'day-x'" class="day-x">
              <el-icon><Close /></el-icon>
            </span>
            <!-- Sinon, on laisse le cercle tel quel (pointillé, no-data...) -->
            <span
              v-else-if="computeDayClass(day) === 'day-no-data'"
              class="day-no-data"
            ></span>
          </div>
        </div>
      </div>

      <!-- Boutons à droite -->
      <div class="progress-actions">
        <button @click.prevent="editGoal" class="modify-button">
          <el-icon><EditPen /></el-icon>
        </button>
      </div>
    </div>
  </div>
  <Statistique v-if="showStatistics" @close="showStatistics = false" />
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Check, Close } from "@element-plus/icons-vue";
import Statistique from "./Statistique.vue";

function formatLocalKey(dateObj) {
  const yyyy = dateObj.getFullYear();
  const mm = String(dateObj.getMonth() + 1).padStart(2, "0");
  const dd = String(dateObj.getDate()).padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`; // ex: "2025-01-14"
}

export default {
  name: "WeeklyProgress",
  components: {
    Check,
    Close,
    Statistique,
  },
  props: {
    // Optionnel : si vous souhaitez recevoir des props du parent
  },
  data() {
    return {
      // Ici, "days" est juste un placeholder pour afficher 7 items
      days: ["1", "2", "3", "4", "5", "6", "7"],
      showStatistics: false,
    };
  },
  computed: {
    ...mapState("check", ["weekChecks"]),

    lastSevenDays() {
      const result = [];
      const now = new Date();

      if (this.weekChecks.length < 4) {
        for (let i = 0; i < 7; i++) {
          const day = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + i
          );
          const dayKey = formatLocalKey(day);

          const checkOfDay = this.weekChecks.find((check) => {
            const checkDate = new Date(check.attributes.createdAt);
            return formatLocalKey(checkDate) === dayKey;
          });

          // Récupérer les données nécessaires
          const valide = checkOfDay ? checkOfDay.attributes.valide : false;

          result.push({
            dateKey: dayKey,
            valide,
          });
        }
      } else {
        for (let i = 0; i < 7; i++) {
          const day = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - i
          );
          const dayKey = formatLocalKey(day);

          const checkOfDay = this.weekChecks.find((check) => {
            const checkDate = new Date(check.attributes.createdAt);
            return formatLocalKey(checkDate) === dayKey;
          });

          // Récupérer les données nécessaires
          const valide = checkOfDay ? checkOfDay.attributes.valide : false;

          result.unshift({
            dateKey: dayKey,
            valide,
          });
        }
      }
      return result;
    },
  },
  methods: {
    ...mapActions("check", ["fetchGoalChecksForWeek"]),

    computeDayClass(day) {
      const todayKey = formatLocalKey(new Date());

      if (!day) return "day-no-data";

      if (day.dateKey < todayKey) {
        return day.valide ? "completed" : "day-x";
      } else if (day.dateKey === todayKey) {
        return day.valide ? "completed" : "day-pending";
      } else {
        return "day-no-data";
      }
    },
    editGoal() {
      this.$emit("edit-goal");
    },
  },
  async mounted() {
    await this.fetchGoalChecksForWeek();
  },
};
</script>

<style scoped>
/* === Couleurs et variables (optionnel) === */
:root {
  --main-bg: #e6fbfc; /* Fond général */
  --tracker-bg: #d9f6f9; /* Couleur du bloc tracker */
  --circle-bg: #7dd3fc; /* Cercles (état normal ou completed) */
  --circle-red: #ef4444; /* Cercles rouges (état x) */
  --border-color: #c2eef2; /* Bordures ou ligne */
}

/* === Container Principal === */
.progress-container {
  width: 100%;
  padding: 5px 0;
  /* background-color: var(--main-bg); */
  display: flex;
  justify-content: center;
  cursor: pointer;
}

/* === Tracker === */
.progress-tracker {
  background: var(--bgV);
  border-radius: 6px;
  padding: 5px 6px 5px 5px;
  border: 1px solid var(--borderV);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 640px;
}

/* === Header (icône + cercles) === */
.progress-header {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Icône de gauche (cible) */
.progress-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 35px;
  min-height: 35px;
  max-width: 35px;
  max-height: 35px;
}
.progress-icon img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

/* === Grille des jours === */
.days-grid {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px; /* Espace horizontal entre chaque cercle */
}

/* Ligne horizontale derrière les cercles */
.days-grid::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  height: 2px;
  background-color: var(--button);
  z-index: 1;
}

/* === Cercles des jours === */
.day-item {
  z-index: 2; /* Passe au-dessus de la ligne */
  width: 25px;
  height: 25px;
  background-color: var(--button);
  border-radius: 50%;
  border: 2px solid var(--borderV);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  position: relative;
}

/* Jour complété (coché) */
.day-item.completed {
  background-color: var(--button);
  border-color: var(--borderV);
}
.day-item.completed .day-check,
.day-item.day-x .day-x,
.day-item.day-no-data .day-no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.day-item.completed .day-check i,
.day-item.day-x .day-x i,
.day-item.day-no-data .day-no-data i {
  color: #fff;
  font-weight: 700;
}

/* Jour rouge avec X */
.day-item.day-x {
  background-color: #000 !important;
  border-color: var(--borderV);
}
/* Jour pointillé (pas encore fait) */
.day-item.day-pending {
  background-color: var(--bgV);
  border: 2px dashed var(--borderV);
}

.day-item.day-no-data {
  background: var(--bgV);
  border: 2px solid var(--borderV);
}

/* === Boutons à droite === */
.progress-actions {
  display: flex;
  gap: 0.5rem;
}

.modify-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--button);
  border: none;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-weight: bold;
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
}

.modify-button i {
  color: var(--colorBtn);
  font-size: 16px;
}

@media (min-width: 401px) {
  .days-grid {
    gap: 12px;
  }
}

@media (max-width: 401px) {
  .progress-icon {
    min-width: 25px;
    min-height: 25px;
    max-width: 25px;
    max-height: 25px;
  }

  .days-grid {
    gap: 5px;
  }

  .day-item {
    z-index: 2;
    width: 20px;
    height: 20px;
  }
  .day-item.completed .day-check,
  .day-item.day-x .day-x,
  .day-item.day-no-data .day-no-data {
    font-size: 10px;
  }

  .modify-button {
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
  }
}
</style>
