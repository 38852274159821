<!-- MemoDrawer.vue -->
<template>
  <el-drawer
    :direction="direction"
    v-model="drawer"
    :title="$t('memopuptitle')"
    class="popupmemo"
    :show-close="true"
  >
    <div class="box-container-memo">
      <div class="change">
        <el-switch
          v-model="text"
          :active-text="$t('memophonetique')"
          :inactive-text="$t('memoarabic')"
        />
      </div>

      <!-- ÉTAPE 1 -->
      <div class="step-1" v-if="currentStep == 1">
        <h2>{{ $t("step1info") }}</h2>
        <div class="validate">
          <Wrong v-if="wrong" />
          <Success v-if="validate" />
        </div>
        <div class="input-repetition">
          <input
            type="number"
            v-model="repetitions"
            min="0"
            max="100"
            @input="limitRepetitions"
          />
          <button @click="toggleAudio">
            <component :is="isAudioPlaying ? 'Pause' : 'Ear'" />
            {{ isAudioPlaying ? $t("pausememo") : $t("listen") }}
          </button>
        </div>
        <div class="texts">
          <p v-if="!text" class="arabic">{{ arabe }}</p>
          <p v-else class="phonetic">{{ phonetique }}</p>
        </div>
      </div>

      <!-- ÉTAPE 2 -->
      <div class="step-2" v-if="currentStep == 2">
        <h2>{{ $t("listenword") }}</h2>
        <div class="validate">
          <Wrong v-if="wrong" />
          <Success v-if="validate" />
        </div>
        <div class="texts">
          <p class="arabic as2 cc-s" v-if="!text">
            <span
              class="w"
              v-for="(word, index) in arabe.split(' ')"
              :key="index"
              @click="playWordAudio(index), listenWord()"
            >
              {{ word }}&nbsp;
            </span>
          </p>
          <p class="phonetic ps2" v-else>
            <span
              class="w ph"
              v-for="(word, index) in arabe.split(' ')"
              :key="index"
              @click="playWordAudio(index), listenWord()"
            >
              {{ transliterateWord(word) }}&nbsp;
            </span>
          </p>
        </div>
      </div>

      <!-- ÉTAPE 3 -->
      <div class="step-3" v-if="currentStep == 3">
        <h2>{{ $t("missingword") }}</h2>
        <div class="validate">
          <Wrong v-if="wrong" />
          <Success v-if="validate" />
        </div>
        <div class="texts">
          <p class="arabic as2 st-c" v-if="!text">
            <span
              v-for="(word, index) in arabe.split(' ')"
              :key="index"
              :class="assignRandomClass(index)"
            >
              <span>{{ word }}&nbsp;</span>
            </span>
          </p>
          <p class="phonetic ps2 st-c" v-else>
            <span
              v-for="(word, index) in phonetique.split(' ')"
              :key="index"
              :class="assignRandomClass(index)"
            >
              <span>{{ word }}&nbsp;</span>
            </span>
          </p>
          <div class="c-btn">
            <button class="play-btn btn-p3" @click="changeClassOnClick">
              <openEye />
              {{ $t("unvealwords") }}
            </button>
          </div>
          <p class="decount">{{ countdown }}</p>
        </div>
      </div>

      <!-- ÉTAPE 4 -->
      <div class="step-4" v-if="currentStep == 4">
        <h2>{{ $t("movewords") }}</h2>
        <div class="validate">
          <Wrong v-if="wrong" />
          <Success v-if="validate" />
        </div>
        <div class="texts">
          <div class="result">
            <p class="arabic as2 st-c step4 move" v-if="!text">
              <span
                v-for="(word, index) in orderedArabicWords"
                :key="'ordered-ar-' + index"
                :data-index="index"
                :class="{
                  'word-wrong': incorrectWords.has(index),
                  'success-word': isOrderCorrect && !incorrectWords.has(index),
                }"
                >{{ word }}</span
              >
            </p>
            <p class="phonetic ps2 st-c step4" v-else>
              <span
                v-for="(word, index) in orderedPhoneticWords"
                :key="'ordered-ph-' + index"
                :data-index="index"
                :class="{
                  'word-wrong': incorrectWords.has(index),
                  'success-word': isOrderCorrect && !incorrectWords.has(index),
                }"
                >{{ word }}&nbsp;</span
              >
            </p>
          </div>
          <p
            v-if="!text"
            class="arabic as2 st-c step4 move"
            ref="cardContainer"
          >
            <span
              class="touch-word"
              v-for="wordObj in shuffledWords"
              :key="'shuffled-ar-' + wordObj.id"
              @click="moveWordToOrder(wordObj.id)"
            >
              <span>{{ wordObj.word }}&nbsp;</span>
            </span>
          </p>
          <p v-else class="phonetic ps2 st-c step4" ref="cardContainer">
            <span
              class="touch-word"
              v-for="wordObj in shuffledWords"
              :key="'shuffled-ph-' + wordObj.id"
              @click="moveWordToOrder(wordObj.id)"
            >
              <span>{{ wordObj.word }}&nbsp;</span>
            </span>
          </p>
        </div>
      </div>

      <!-- ÉTAPE 5 -->
      <div class="step-5" v-if="currentStep == 5">
        <h1>{{ $t("step5Title") }}</h1>
        <p>{{ $t("step5Message") }}</p>
        <p>{{ numero }}✅/{{ totalverses }}</p>
        <img
          class="caas-img caas-lazy caas-loaded"
          :alt="$t('step5ImageAlt')"
          loading="lazy"
          src="https://s.yimg.com/ny/api/res/1.2/F9HKon5iH2MbFpeAS1sM_Q--/YXBwaWQ9aGlnaGxhbmRlcjt3PTk2MDtjZj13ZWJw/https://media.zenfs.com/en/snappa_entertainment_819/77b45c811132100153dbac1724390867"
        />
        <div class="btn-finalStep">
          <span class="next" v-if="numero < totalverses" @click="goToNextVerse">
            {{ $t("nextMomo") }} <Right />
          </span>
          <span @click="drawer = false">{{ $t("step5Continue") }}</span>
        </div>
      </div>

      <!-- NAVIGATION -->
      <div class="navigation">
        <div class="left" @click="goToPreviousStep">
          <div class="btn" v-if="currentStep > 1">
            <Left />
          </div>
        </div>
        <div class="middle">
          <div class="prog">
            <div class="progression">
              <div class="prg" :style="{ width: progressBarWidth }"></div>
            </div>
            <span>{{ progressBarWidth }}</span>
          </div>
        </div>
        <div class="right" @click="goToNextStep">
          <div class="btn">
            <Right />
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { ref } from "vue";
import confetti from "../../assets/js/confetti";
import Wrong from "../icons/Wrong.vue";
import Success from "../icons/Success.vue";
import Ear from "../icons/Ear.vue";
import Pause from "../icons/Pause.vue";
import openEye from "../icons/openEye.vue";
import Left from "../icons/Left.vue";
import Right from "../icons/Right.vue";

export default {
  name: "MemoDrawer",
  components: {
    Wrong,
    Success,
    Ear,
    Pause,
    openEye,
    Left,
    Right,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    reciter: {
      type: String,
      default: "Hussary",
    },
    idsourate: {
      type: Number,
      required: true,
    },
    numero: {
      type: Number,
      required: true,
    },
    arabe: {
      type: String,
      required: true,
    },
    phonetique: {
      type: String,
      required: true,
    },
    totalverses: Number,
  },
  emits: ["update:modelValue", "memorizeNextVerse"],
  setup() {
    const direction = ref("btt");
    const text = ref(false);

    return {
      direction,
      text,
    };
  },
  data() {
    return {
      repetitions: 1,
      isAudioPlaying: false,
      audioPlayCount: 0,
      currentStep: 1,
      countdown: 10,
      shuffledWords: [],
      orderedArabicWords: [],
      orderedPhoneticWords: [],
      isOrderCorrect: null,
      incorrectWords: new Set(),
      hasRevealedWords: false,
      wordListen: 0,
      wrong: false,
      validate: false,
      audioElement: null,
      currentAudio: null,
    };
  },
  computed: {
    drawer: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    progressBarWidth() {
      return `${(this.currentStep / 5) * 100}%`;
    },
  },
  watch: {
    drawer(newVal) {
      if (!newVal) {
        this.resetState();
      }
    },
    text() {
      if (this.currentStep === 4) {
        this.shuffleWords();
      }
    },
  },
  methods: {
    limitRepetitions() {
      this.repetitions = Math.min(this.repetitions, 100);
    },
    toggleAudio() {
      this.isAudioPlaying ? this.pauseAudio() : this.playAudio();
    },
    playAudio() {
      const url = `https://audio.doonun.com/sourates/ar/${
        this.reciter || "Hussary"
      }/${this.idsourate}/${this.numero}.mp3`;
      if (!this.repetitions || !url) return;
      if (!this.isAudioPlaying) {
        this.audioPlayCount = this.repetitions;
        this.isAudioPlaying = true;
      }
      this.audioElement = this.audioElement || new Audio(url);
      this.audioElement.addEventListener("ended", this.handleAudioEnd);
      this.audioElement.play();
    },
    handleAudioEnd() {
      if (this.audioPlayCount > 1) {
        this.audioPlayCount--;
        this.repetitions--;
        this.audioElement.play();
        this.validate = false;
      } else {
        this.isAudioPlaying = false;
        this.audioPlayCount = Math.max(this.audioPlayCount - 1, 0);
        this.repetitions--;
        this.validate = true;
        this.wrong = false;
      }
    },
    pauseAudio() {
      if (this.audioElement && this.isAudioPlaying) {
        this.isAudioPlaying = false;
        this.audioElement.pause();
      }
    },
    listenWord() {
      this.wordListen++;
      this.validate = true;
      this.wrong = false;
    },
    playWordAudio(index) {
      const audioPath = this.getWordAudioPath(this.numero, index);
      if (audioPath) {
        if (this.currentAudio) {
          this.currentAudio.pause();
          this.currentAudio = null;
        }
        this.currentAudio = new Audio(audioPath);
        this.currentAudio.play();
        this.currentAudio.addEventListener("ended", () => {
          this.currentAudio = null;
        });
      }
    },
    getWordAudioPath(verset, wordIndex) {
      if (!verset) return null;
      const sourateDossier = String(this.idsourate);
      const versetFile = String(this.numero).padStart(3, "0");
      const sourateNumero = String(this.idsourate).padStart(3, "0");
      const versetNumero = String(this.numero).padStart(3, "0");
      const wordNumber = String(wordIndex + 1).padStart(3, "0");
      return `https://audio.doonun.com/mots/${sourateDossier}/${versetFile}/${sourateNumero}_${versetNumero}_${wordNumber}.mp3`;
    },
    transliterateWord(word) {
      const transliterationMap = {
        ء: "ʾ",
        ا: "a",
        ب: "b",
        ت: "t",
        ث: "th",
        ج: "j",
        ح: "h",
        خ: "kh",
        د: "d",
        ذ: "dh",
        ر: "r",
        ز: "z",
        س: "s",
        ش: "sh",
        ص: "s",
        ض: "d",
        ط: "t",
        ظ: "z",
        ع: "ʿ",
        غ: "gh",
        ف: "f",
        ق: "q",
        ك: "k",
        ل: "l",
        م: "m",
        ن: "n",
        ه: "h",
        و: "w",
        ي: "y",
        ى: "a",
        ة: "h",
        ٱ: "A",
        إ: "i",
        "َ": "a",
        "ِ": "i",
        "ُ": "u",
        "ً": "an",
        "ٍ": "in",
        "ٌ": "un",
        "ْ": "",
        "ّ": "",
      };
      return word
        .split("")
        .map((char) => transliterationMap[char] || char)
        .join("");
    },
    assignRandomClass(index) {
      return index % 2 === 0 ? "msqtxt" : "";
    },
    changeClassOnClick() {
      const spans = document.querySelectorAll(".msqtxt");
      spans.forEach((span) => {
        span.classList.replace("msqtxt", "msqtxtVisible");
      });
      let countdown = 10;
      const timer = setInterval(() => {
        this.countdown = --countdown || 10;
        if (countdown === 0) {
          clearInterval(timer);
          spans.forEach((span) => {
            span.classList.replace("msqtxtVisible", "msqtxt");
          });
        }
      }, 1000);
      this.hasRevealedWords = true;
      this.validate = true;
      this.wrong = false;
    },
    shuffleWords() {
      const words = (this.text ? this.phonetique : this.arabe)
        .split(/\s+/)
        .filter(Boolean);
      for (let i = words.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [words[i], words[j]] = [words[j], words[i]];
      }
      this.shuffledWords = words.map((word, index) => ({
        id: index + Math.random(),
        word,
      }));
      this.remainingWordsToMove = words.length;
    },
    moveWordToOrder(wordId) {
      const wordObj = this.shuffledWords.find((w) => w.id === wordId);
      if (!wordObj) return;
      if (!this.text) {
        this.orderedArabicWords.push(wordObj.word);
      } else {
        this.orderedPhoneticWords.push(wordObj.word);
      }
      this.shuffledWords = this.shuffledWords.filter((w) => w.id !== wordId);
      if (!--this.remainingWordsToMove) {
        this.checkOrder();
      }
    },
    resetOrder() {
      this.orderedArabicWords = [];
      this.orderedPhoneticWords = [];
      this.isOrderCorrect = null;
      this.incorrectWords.clear();
      document.querySelectorAll(".word-wrong").forEach((el) => {
        el.classList.remove("word-wrong");
      });
      this.shuffleWords();
    },
    checkOrder() {
      const originalWords = (this.text ? this.phonetique : this.arabe).split(
        " "
      );
      const orderedWords = this.text
        ? this.orderedPhoneticWords
        : this.orderedArabicWords;
      this.incorrectWords.clear();
      let allCorrect = true;
      orderedWords.forEach((word, index) => {
        const wordElement = document.querySelector(
          `span[data-index="${index}"]`
        );
        if (originalWords[index] !== word) {
          this.incorrectWords.add(index);
          if (wordElement) {
            wordElement.classList.add("word-wrong");
            wordElement.classList.remove("success-word");
          }
          allCorrect = false;
        } else {
          if (wordElement) {
            wordElement.classList.remove("word-wrong");
            wordElement.classList.add("success-word");
          }
        }
      });
      this.isOrderCorrect = allCorrect;
      this.playSound(
        require(`@/assets/audios/quiz/${
          this.isOrderCorrect ? "correct" : "error"
        }.${this.isOrderCorrect ? "wav" : "mp3"}`)
      );
      if (!this.isOrderCorrect) {
        setTimeout(this.resetOrder, 2000);
      } else {
        this.validate = true;
        this.wrong = false;
        this.triggerConfetti();
        if (this.currentStep === 4) {
          this.currentStep = 5;
        }
      }
    },
    playSound(audioPath) {
      new Audio(audioPath).play().catch(() => {});
    },
    triggerConfetti() {
      const container = this.$refs.cardContainer || document.body;
      const rect = container.getBoundingClientRect();
      confetti({
        particleCount: 150,
        spread: 60,
        origin: {
          x: (rect.left + rect.width / 2) / window.innerWidth,
          y: (rect.top + rect.height / 2) / window.innerHeight,
        },
      });
    },
    goToNextStep() {
      if (this.currentStep === 1 && this.repetitions >= 1) {
        this.wrong = true;
        return;
      }
      if (this.currentStep === 2 && this.wordListen <= 0) {
        this.wrong = true;
        return;
      }
      if (this.currentStep === 3 && !this.hasRevealedWords) {
        this.wrong = true;
        return;
      }
      if (this.currentStep === 4 && !this.isOrderCorrect) {
        this.wrong = true;
        return;
      }
      if (this.currentStep < 5) {
        this.shuffleWords();
        this.wrong = false;
        this.validate = false;
        this.currentStep++;
      }
    },
    goToPreviousStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
        this.resetOrder();
      }
    },
    resetState() {
      this.pauseAudio();
      if (this.currentAudio) {
        this.currentAudio.pause();
        this.currentAudio = null;
      }
      this.isAudioPlaying = false;
      this.audioPlayCount = 0;
      this.countdown = 10;
      this.shuffleWords();
      if (this.currentStep === 4) {
        this.resetOrder();
      }
      this.currentStep = 1;
    },
    goToNextVerse() {
      this.$emit("memorizeNextVerse");
    },
  },
};
</script>

<style scoped>
.box-container-memo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}
.change {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
h2 {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
}
.input-repetition {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.input-repetition input {
  display: block;
  width: 150px;
  height: 150px;
  background: var(--button);
  color: #fff;
  border-radius: 50%;
  outline: none;
  border: none;
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
}
.input-repetition input::-webkit-outer-spin-button,
.input-repetition input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-repetition input[type="number"] {
  -moz-appearance: textfield;
}
.input-repetition button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px 8px 5px;
  gap: 3px;
  border: 1px solid var(--card);
  transition: 0.2s ease-in-out;
  background: var(--body);
  color: var(--color);
  border-radius: 30px;
  width: 120px;
  font-size: 14px;
  font-weight: 500;
}
.input-repetition button:hover {
  width: 130px;
  border: 1px solid var(--button);
}
.texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto 0 auto;
}
.texts .arabic {
  font-size: 1.7rem;
  font-family: "uthmanic";
  text-align: center;
}
.texts .phonetic {
  text-align: center;
  font-size: 1.1rem;
}
.step-2 .as2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: 2px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 1.7rem;
  font-family: "uthmanic";
}
.step-2 .ps2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2px;
  max-width: 800px;
  margin-top: 30px;
  user-select: none;
  margin-bottom: 20px;
}
.step-2 .cc-s .w,
.step-2 .ps2 .w {
  padding: 3px 5px;
  background: var(--body);
  border-radius: 6px;
}
.step-2 .cc-s .w:hover,
.step-2 .ps2 .w:hover {
  background: var(--button);
  color: #fff;
  cursor: pointer;
}
.step-3 .st-c {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  justify-content: center;
  user-select: none;
}
.step-3 .as2 {
  flex-direction: row-reverse;
}
.msqtxt {
  border: 1px dashed var(--color);
  padding: 1px 5px;
  border-radius: 6px;
  color: var(--card);
}
.msqtxtVisible {
  color: var(--button);
  padding: 1px 5px;
  border-radius: 6px;
  background-color: var(--card);
}
.c-btn {
  display: flex;
  justify-content: center;
}
.play-btn {
  margin-top: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  background: var(--body);
  color: var(--color);
  border-radius: 50px;
  padding: 8px 15px;
  font-weight: 500;
  font-size: 14px;
  transition: 0.2s;
  border: none;
  outline: 1px solid var(--body);
}
.play-btn:hover {
  outline: 1px solid var(--button);
}
.decount {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  font-size: 1.5rem;
  border-radius: 50%;
  font-weight: 700;
  width: 100px;
  height: 100px;
  background: var(--body);
}
.step4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3px;
}
.result {
  margin-bottom: 20px;
}
.move {
  flex-direction: row-reverse;
}
.touch-word {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--body);
  padding: 3px;
  border-radius: 6px;
  cursor: pointer;
}
.touch-word:hover {
  background: var(--button);
  color: #fff;
}
.word-wrong {
  color: red !important;
}
.success-word {
  color: #67c23a;
}
.validate {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 20px 50px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  width: 100%;
  background: var(--truncated);
  height: 50px;
  margin: 0 auto;
}
.navigation .left,
.navigation .right {
  width: 40px;
  height: 40px;
}
.navigation .left .btn,
.navigation .right .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  background: var(--body);
  border-radius: 6px;
  max-width: 40px;
  max-height: 40px;
  width: 100px;
  height: 100%;
}
.navigation .middle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.prog {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}
.prog span {
  margin-top: -3px;
  font-weight: 500;
  font-size: 14px;
}
.progression {
  background-color: var(--body);
  border-radius: 5px;
  width: 70%;
  height: 6px;
}
.prg {
  background-color: var(--button);
  height: 100%;
  border-radius: 5px;
  transition: 0.2s;
}

.step-5 h1 {
  text-align: center;
}

.step-5 p {
  text-align: center;
}

.step-5 img {
  margin: 20px 0 5px 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.btn-finalStep {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.btn-finalStep span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: var(--body);
  border-radius: 6px;
  padding: 10px 30px;
  width: auto;
  transition: 0.2s;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.btn-finalStep span:hover {
  outline: 1px solid var(--button);
}
.btn-finalStep .next {
  display: flex;
  gap: 5px;
  background: var(--color);
  color: var(--colorBtn);
  border-radius: 6px;
  transition: 0.2s ease-in-out;
}

.btn-finalStep .next:hover {
  background: var(--button);
  gap: 8px;
}
</style>
