<template>
  <Head>
    <title>Bibliothèque - Doonun</title>
    <meta name="robots" content="Noindex, nofollow" />
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div class="options">
    <div class="box-options">
      <button id="settings" @click="toggleButtons"><Settings /></button>
      <button
        id="sidebarToggle"
        :style="{ display: buttonsVisible ? 'inline-block' : 'none' }"
      >
        <Menu />
      </button>
      <div :style="{ display: buttonsVisible ? 'inline-block' : 'none' }">
        <span id="numPages">{{ numPages }}</span>
      </div>
      <input
        type="number"
        id="pageNumber"
        v-model.number="currentPage"
        :style="{ display: buttonsVisible ? 'inline-block' : 'none' }"
        @change="goToPage"
      />
      <button
        id="lastPage"
        :style="{ display: buttonsVisible ? 'inline-block' : 'none' }"
        @click="goToLastPage"
      >
        <End />
      </button>
      <button
        id="firstPage"
        :style="{ display: buttonsVisible ? 'inline-block' : 'none' }"
        @click="goToFirstPage"
      >
        <Start />
      </button>

      <button
        id="next"
        :style="{ display: buttonsVisible ? 'inline-block' : 'none' }"
        @click="goToNextPage"
      >
        <Suivant />
      </button>
      <button
        id="previous"
        :style="{ display: buttonsVisible ? 'inline-block' : 'none' }"
        @click="goToPreviousPage"
      >
        <Precedent />
      </button>

      <button
        :id="idConfig.zoomOut"
        type="button"
        :style="{ display: buttonsVisible ? 'inline-block' : 'none' }"
        @click="zoomOut"
      >
        <Zoommoins />
      </button>
      <button
        :id="idConfig.zoomIn"
        type="button"
        :style="{ display: buttonsVisible ? 'inline-block' : 'none' }"
        @click="zoomIn"
      >
        <Zoomplus />
      </button>
      <button
        id="pageRotateCw"
        :style="{ display: buttonsVisible ? 'inline-block' : 'none' }"
        @click="rotatePage"
      >
        <Rotate />
      </button>
      <button
        id="cursorHandTool"
        :style="{ display: buttonsVisible ? 'inline-block' : 'none' }"
        @click="selectHandTool"
      >
        <Hands />
      </button>
      <button
        id="cursorSelectTool"
        :style="{ display: buttonsVisible ? 'inline-block' : 'none' }"
        @click="selectTextTool"
      >
        <Text />
      </button>
      <button
        id="scrollHorizontal"
        :style="{ display: buttonsVisible ? 'inline-block' : 'none' }"
        @click="scrollHorizontal"
      >
        <Horizontal />
      </button>
      <button
        id="scrollVertical"
        :style="{ display: buttonsVisible ? 'inline-block' : 'none' }"
        @click="scrollVertical"
      >
        <Vertical />
      </button>
      <button
        id="presentationMode"
        :style="{ display: buttonsVisible ? 'inline-block' : 'none' }"
        @click="toggleFullscreen"
      >
        <Fullscreen />
      </button>
    </div>
  </div>
  <div class="container" v-if="livre">
    <PdfApp
      v-if="pdfUrl"
      :id-config="idConfig"
      :config="config"
      style="height: 90vh"
      :pdf="pdfUrl"
      @error="handlePdfError"
    ></PdfApp>
  </div>

  <div v-else>
    <div class="loading">
      <Loading />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "../../components/navigation/Loading.vue";
import Menu from "../../components/icons/pdf/Menu.vue";
import Zoommoins from "../../components/icons/pdf/Zoommoins.vue";
import Zoomplus from "../../components/icons/pdf/Zoomplus.vue";
import Rotate from "../../components/icons/pdf/Rotate.vue";
import Hands from "../../components/icons/pdf/Hands.vue";
import Horizontal from "../../components/icons/pdf/Horizontal.vue";
import Vertical from "../../components/icons/pdf/Vertical.vue";
import Fullscreen from "../../components/icons/pdf/Fullscreen.vue";
import Text from "../../components/icons/pdf/Text.vue";
import Start from "../../components/icons/pdf/Start.vue";
import End from "../../components/icons/pdf/End.vue";
import Precedent from "../../components/icons/pdf/Precedent.vue";
import Suivant from "../../components/icons/pdf/Suivant.vue";
import Settings from "../../components/icons/pdf/Settings.vue";
import PdfApp from "vue3-pdf-app";
import { Head } from "@vueuse/head";
import "vue3-pdf-app/dist/icons/main.css";

export default {
  components: {
    PdfApp,
    Menu,
    Zoommoins,
    Zoomplus,
    Rotate,
    Hands,
    Horizontal,
    Vertical,
    Fullscreen,
    Text,
    Start,
    End,
    Precedent,
    Suivant,
    Settings,
    Loading,
    Head,
  },
  name: "livres-details",
  data() {
    return {
      config: {
        toolbar: false,
      },
      idConfig: { zoomIn: "zoomInId", zoomOut: "zoomOutId" },
      buttonsVisible: false,
      currentPage: 1,
      numPages: 0,
    };
  },
  computed: {
    ...mapGetters("livres", ["livre"]),
    ...mapGetters("subscribe", ["isSubscribed"]),
    pdfUrl() {
      return this.livre?.attributes?.pdf?.data?.attributes?.url
        ? `https://cp.doonun.com${this.livre.attributes.pdf.data.attributes.url}`
        : null;
    },
  },
  methods: {
    ...mapActions("livres", ["fetchLivre"]),
    toggleButtons() {
      this.buttonsVisible = !this.buttonsVisible;
    },
  },
  created() {
    if (!this.isSubscribed) {
      // Redirige vers /doovie si non abonné
      this.$router.push("/livres");
      return;
    }
    // Passez un objet avec slug et lang
    this.fetchLivre({
      slug: this.$route.params.slug,
      lang: this.$i18n.locale, // Utilisez la locale actuelle
    });
  },
  watch: {
    $route(to) {
      this.fetchLivre({
        slug: to.params.slug,
        lang: this.$i18n.locale, // Passez la locale ici
      });
    },
    "$i18n.locale"(newLocale) {
      // Ajoutez un watcher pour la locale si nécessaire
      this.fetchLivre({
        slug: this.$route.params.slug,
        lang: newLocale,
      });
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 100px;
  position: relative;
}
.options {
  display: flex;
  justify-content: left;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 998;
  margin: 0 0 10px 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.box-options {
  display: flex;
  flex-direction: column-reverse;
  justify-self: center;
  min-width: 55px;
  align-items: stretch;
  gap: 10px;
  background: var(--card);
  padding: 10px 0;
}

button {
  border: none;
  background: var(--card);
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
}

#pageNumber {
  border: none;
  min-width: 20px;
  max-width: 45px;
  padding: 10px 3px 10px 3px;
  background: var(--card);
  color: var(--color);
  border-radius: 3px;
  text-align: center;
}

span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 80vh;
  width: 100%;
  background: var(--card);
}

#pageNumber {
  max-width: 55px;
}

@media (max-width: 490px) {
  .container {
    margin-top: 70px;
    padding: 0;
  }
}
</style>
