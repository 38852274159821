<!-- dooviePlay.vue -->
<template>
  <Head>
    <title>{{ cleanTitle(currentEpisode.titre) }} - Doonun</title>
    <meta name="robots" content="Noindex, nofollow" />
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div>
    <Head>
      <title>Doovie Play</title>
      <meta name="robots" content="Noindex, nofollow" />
      <link
        rel="icon"
        href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
      />
    </Head>

    <div class="bg">
      <div class="container">
        <!-- Section du Lecteur Vidéo -->
        <VideoPlayer :url="currentEpisode.url" />
        <!-- Section des Boutons de Navigation -->
        <div class="navigation-buttons">
          <button
            class="nav-button"
            @click="goToPreviousEpisode"
            :disabled="!previousEpisode"
            :title="
              previousEpisode
                ? 'Aller à l\'épisode précédent'
                : 'Aucun épisode précédent'
            "
          >
            <i class="fas fa-arrow-left"></i>
          </button>
          <h3 class="currenth3">
            {{ currentEpisode.numero }} - {{ currentEpisode.titre }}
          </h3>
          <button
            class="nav-button"
            @click="goToNextEpisode"
            :disabled="!nextEpisode"
            :title="
              nextEpisode
                ? 'Aller à l\'épisode suivant'
                : 'Aucun épisode suivant'
            "
          >
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
        <!-- Section de Sélection des Épisodes -->
        <!-- Section de Sélection des Épisodes -->
        <div class="demo-collapse">
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item
              v-for="(saison, index) in serie.attributes.Saison"
              :title="`Saison ${index + 1}`"
              :name="index + 1"
              :key="saison.id"
            >
              <div
                v-for="episode in saison.Episodes"
                :key="episode.id"
                :class="[
                  'episode-item',
                  { active: episode.id === selectedEpisodeId },
                  { locked: !episode.free && !isSubscribed },
                ]"
                @click="handleEpisodeClick(episode)"
                :title="
                  !episode.free && !isSubscribed
                    ? 'Épisode payant'
                    : 'Épisode gratuit'
                "
              >
                <div class="numero">
                  <span>
                    {{ episode.numero }}
                  </span>
                </div>
                <div class="lock" v-if="!episode.free && !isSubscribed">
                  <!-- Modification ici -->
                  <i class="fas fa-lock locked-icon" aria-hidden="true"></i>
                </div>
                <span class="episode-title">
                  {{ episode.titre || "Sans Titre" }}
                </span>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>

    <!-- Composant Tabs -->
    <Tabs />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import { Head } from "@vueuse/head";
import Tabs from "../../components/global/Tabs.vue";
import VideoPlayer from "../../components/global/VideoPlayer.vue";
import { ref } from "vue";

export default defineComponent({
  name: "dooviePlay",
  components: {
    Head,
    Tabs,
    VideoPlayer,
  },
  data() {
    return {
      serieId: this.$route.params.id,
      selectedEpisodeId: null,
    };
  },
  setup() {
    const activeName = ref(1);
    return {
      activeName,
    };
  },
  computed: {
    ...mapGetters("doovie", ["serie"]),
    // Importer les getters pour l'abonnement
    ...mapGetters("subscribe", ["isSubscribed"]),
    // Obtenir tous les épisodes de toutes les saisons
    allEpisodes() {
      if (
        !this.serie ||
        !this.serie.attributes ||
        !this.serie.attributes.Saison
      ) {
        return [];
      }
      // Aplatir les épisodes de toutes les saisons en un seul tableau
      return this.serie.attributes.Saison.flatMap((saison) => saison.Episodes);
    },
    currentEpisode() {
      if (!this.allEpisodes.length) {
        return {};
      }
      // Si un épisode est sélectionné, le renvoyer
      if (this.selectedEpisodeId) {
        return (
          this.allEpisodes.find((ep) => ep.id === this.selectedEpisodeId) ||
          this.allEpisodes[0]
        );
      }
      // Par défaut, on prend le premier épisode
      return this.allEpisodes[0];
    },
    // Épisode précédent
    previousEpisode() {
      const currentIndex = this.allEpisodes.findIndex(
        (ep) => ep.id === this.selectedEpisodeId
      );
      if (currentIndex > 0) {
        return this.allEpisodes[currentIndex - 1];
      }
      return null;
    },
    // Épisode suivant
    nextEpisode() {
      const currentIndex = this.allEpisodes.findIndex(
        (ep) => ep.id === this.selectedEpisodeId
      );
      if (currentIndex !== -1 && currentIndex < this.allEpisodes.length - 1) {
        return this.allEpisodes[currentIndex + 1];
      }
      return null;
    },
  },
  methods: {
    ...mapActions("doovie", ["loadSerie"]),
    selectEpisode(episode) {
      this.selectedEpisodeId = episode.id;
    },
    handleEpisodeClick(episode) {
      if (episode.free || this.isSubscribed) {
        this.selectEpisode(episode);
      } else {
        this.showSubscriptionDialog();
      }
    },
    goToPreviousEpisode() {
      if (
        this.previousEpisode &&
        (this.previousEpisode.free || this.isSubscribed)
      ) {
        this.selectEpisode(this.previousEpisode);
      } else if (this.previousEpisode) {
        // Afficher un message ou une boîte de dialogue pour souscrire
        this.showSubscriptionDialog();
      }
    },
    goToNextEpisode() {
      if (this.nextEpisode && (this.nextEpisode.free || this.isSubscribed)) {
        this.selectEpisode(this.nextEpisode);
      } else if (this.nextEpisode) {
        // Afficher un message ou une boîte de dialogue pour souscrire
        this.showSubscriptionDialog();
      }
    },
    cleanTitle(title) {
      // Votre logique de nettoyage de titre ici
      return title;
    },
    showSubscriptionDialog() {
      // Implémentez votre logique pour afficher une boîte de dialogue de souscription
      alert("Vous devez être abonné pour accéder à cet épisode.");
      // Par exemple, rediriger vers la page de souscription :
      this.$router.push({ name: "tarifs" });
    },
  },
  mounted() {
    this.loadSerie(this.serieId);
  },
  watch: {
    // Réinitialiser l'épisode sélectionné lorsque la série change
    serie(newSerie) {
      if (newSerie && newSerie.attributes && newSerie.attributes.Saison) {
        // Réinitialiser à l'épisode 1 ou le premier disponible
        const firstEpisode = newSerie.attributes.Saison[0]?.Episodes[0];
        if (firstEpisode) {
          this.selectedEpisodeId = firstEpisode.id;
        }
      }
    },
  },
});
</script>

<style scoped>
.bg {
  background-color: #000;
  padding: 65px 0;
  min-height: 100vh;
}

.container {
  margin: 0 auto;
}

/* Section des Boutons de Navigation */
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.nav-button {
  background-color: #5354fe;
  border: none;
  color: #fff;
  padding: 10px 15px;
  font-size: 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;
}

.nav-button i {
  margin: 0 5px;
}

.nav-button:hover:not(:disabled) {
  background-color: #e6b800;
}

.nav-button:disabled {
  background-color: #212121;
  color: #ccc;
  cursor: not-allowed;
}

.navigation-buttons .nav-button:first-child {
  margin-right: 10px;
}

.saison {
  margin-bottom: 25px;
}

.saison h4 {
  margin-bottom: 10px;
  color: #5354fe;
  font-size: 1.2em;
  border-bottom: 2px solid #5354fe;
  padding-bottom: 5px;
}

.episodes-list ul {
  list-style: none;
  padding: 0;
}

.episode-item {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 12px 15px;
  border-bottom: 1px solid #000;
  transition: background-color 0.3s, color 0.3s;
  color: #fff;
}

.episode-item:hover {
  background-color: #1e1e1e;
}

.episode-item.active {
  background-color: #5354fe;
  color: #fff;
  font-weight: bold;
}

.episode-item.locked {
  cursor: not-allowed;
  color: #888;
  position: relative;
}

.episode-item.locked .lock {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 15px;
}

.numero span {
  font-size: 18px;
}

.lock {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 30px;
}

.locked-icon {
  color: #ff4d4d;
}

.el-collapse {
  --el-collapse-border-color: var(--el-border-color-lighter);
  --el-collapse-header-height: 48px;
  --el-collapse-header-bg-color: #000;
  --el-collapse-header-text-color: #fff;
  --el-collapse-header-font-size: 20px;
  --el-collapse-content-bg-color: #212121;
  --el-collapse-content-font-size: 13px;
  --el-collapse-content-text-color: #fff;
  border-bottom: 1px solid var(--el-collapse-border-color);
  border-top: 1px solid var(--el-collapse-border-color);
}

.currenth3 {
  color: #fff;
  text-align: center;
}
</style>
