<template>
  <div class="container">
    <div class="box-why">
      <h2>{{ $t("why") }}</h2>
      <p>{{ $t("whycitation") }}</p>
      <img
        src="https://cp.doonun.com/uploads/10671315_min_ee4b71f0e1_min_cfc0eb0cf9.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "why-doonun",
};
</script>

<style scoped>
.container {
  margin: auto;
  width: 100%;
}

.box-why {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #dcdfe6 1px, transparent 1px) 0 0,
    linear-gradient(to right, #dcdfe6 1px, transparent 1px) 0 100%,
    linear-gradient(to left, #dcdfe6 1px, transparent 1px) 100% 0,
    linear-gradient(to left, #dcdfe6 1px, transparent 1px) 100% 100%,
    linear-gradient(to bottom, #dcdfe6 1px, transparent 1px) 0 0,
    linear-gradient(to bottom, #dcdfe6 1px, transparent 1px) 100% 0,
    linear-gradient(to top, #dcdfe6 1px, transparent 1px) 0 100%,
    linear-gradient(to top, #dcdfe6 1px, transparent 1px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  min-height: 400px;
  padding: 100px 20px;
  gap: 10px;
}

.box-why h2 {
  text-wrap: balance;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0;
  font-size: clamp(20px, 8vw, 30px);
  white-space: break-spaces;
  text-align: center;
}

.box-why p {
  font-size: 18px;
  max-width: 600px;
  /* white-space: break-spaces; */
  /* text-wrap: balance; */
  text-align: center;
}

.box-why img {
  width: 100%;
  max-width: 1000px;
  height: 100%;
}

@media (max-width: 760px) {
  .box-why {
    padding: 50px 20px;
  }
}
</style>
