<template>
  <div class="box-charity">
    <div class="box">
      <a href="https://buy.stripe.com/fZe6rz57v24S2FqcMM" target="_blank">
        <p>{{ $t("giveDonation") }}</p>
      </a>
      <p class="message">
        {{ $t("donationM") }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "charity-doonun",
};
</script>

<style scoped>
.box-charity {
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 300px;
  max-height: 300px;
  max-width: 400px;
  background-image: url("https://cp.doonun.com/uploads/charity_f8df8cb917.png");
  background-size: cover;
  background-position: center center;
  border-radius: 6px;
}
.box {
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 300px;
  max-height: 300px;
  max-width: 400px;
  border-radius: 6px;
  background: linear-gradient(
    0deg,
    rgb(255, 255, 255) 15%,
    rgba(255, 255, 255, 0) 80%
  );
}

a p {
  padding: 10px 40px;
  text-align: center;
  background: #ffbb73;
  color: #fff;
  /* margin-bottom: 30px; */
  font-weight: 500;
  border-radius: 30px;
  transition: 0.2s ease-in;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

a:hover p {
  background: var(--button);
}

.message {
  padding: 20px 25px;
  text-wrap: balance;
  text-align: center;
  color: #181818;
  font-weight: 500;
  line-height: 1.2rem;
}
</style>
