<template>
  <div>
    <h2 id="scroll-target">{{ $t("courseCompTitle") }}</h2>
    <div class="courses-container">
      <CardCourse
        v-for="cours in userCourses"
        :key="cours?.id"
        :id="cours?.id"
        :titre="cours?.attributes?.titre"
        :points="cours?.attributes?.points"
        :gain="cours?.attributes?.gain"
        :level="cours?.attributes?.niveau"
        :url="cours?.attributes?.url"
        :category="cours?.attributes?.Category"
        :percent="cours?.attributes?.suivis?.data[0]?.attributes?.pourcentage"
        :publishedAt="cours?.attributes?.publishedAt"
        :cover="
          cours?.attributes?.Couverture?.data?.attributes?.formats.small.url
        "
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CardCourse from "../../components/cours/CardCourse.vue";
export default {
  name: "course-profil",
  components: {
    CardCourse,
  },
  computed: {
    ...mapGetters("cours", ["userCourses"]),
    ...mapGetters("login", ["isAuthenticated"]),
  },
  methods: {
    ...mapActions("cours", ["coursUser"]),
  },
  async mounted() {
    try {
      if (this.isAuthenticated) {
        await this.coursUser();
      }
    } catch (error) {
      console.error("Error fetching user courses:", error);
    }
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin: 50px 0 20px 0;
}

.courses-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(300px - 15px), 1fr));
  gap: 15px;
  max-width: 600px;
  margin: 0 auto;
}
</style>
