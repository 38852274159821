// store/modules/goalCheck.js
import axios from "axios";

const state = () => ({
  goalChecks: [], // Tableau contenant les goal-checks
});

const mutations = {
  SET_GOAL_CHECKS(state, checks) {
    state.goalChecks = checks;
  },
  ADD_GOAL_CHECK(state, check) {
    state.goalChecks.push(check);
  },
  UPDATE_GOAL_CHECK(state, updatedCheck) {
    const index = state.goalChecks.findIndex(
      (check) => check.id === updatedCheck.id
    );
    if (index !== -1) {
      state.goalChecks.splice(index, 1, updatedCheck);
    }
  },
};

const actions = {
  async fetchGoalChecks({ commit }, { period = "week" }) {
    try {
      const userId = this.getters["user/userId"];
      let startDate;

      const now = new Date();
      switch (period) {
        case "month":
          startDate = new Date(now.getFullYear(), now.getMonth(), 1);
          break;
        case "year":
          startDate = new Date(now.getFullYear(), 0, 1);
          break;
        case "week":
        default:
          startDate = new Date(now.getTime() - 6 * 24 * 60 * 60 * 1000);
          break;
      }

      const response = await axios.get(`/goal-checks`, {
        params: {
          filters: {
            users_permissions_user: { id: userId },
            createdAt: { $gte: startDate.toISOString() },
          },
          sort: ["createdAt:asc"],
          fields: ["id", "versesCount", "valide", "createdAt"],
        },
      });

      // Map Strapi v4 => on aplatit le tableau
      const rawData = response.data?.data || [];
      const formattedData = rawData.map((item) => ({
        id: item.id,
        ...item.attributes,
      }));

      commit("SET_GOAL_CHECKS", formattedData);
    } catch (error) {
      console.error("Erreur fetchGoalChecks:", error);
    }
  },

  // Action supplémentaire si vous avez besoin de manipuler les goal-checks
  async addGoalCheck({ commit }, checkData) {
    try {
      const response = await axios.post(`/goal-checks`, {
        data: checkData,
      });
      commit("ADD_GOAL_CHECK", response.data.data);
    } catch (error) {
      console.error("Erreur addGoalCheck:", error);
      throw error;
    }
  },
};

const getters = {
  getGoalChecks: (state) => state.goalChecks,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
