<template>
  <span class="conts" v-if="isAuthenticated && lastCheck">
    <!-- SI TAUX < 100 : on affiche la sourate actuelle -->
    <router-link
      v-if="lastCheck?.attributes?.taux < 100"
      :to="`/sourate/${lastCheck?.attributes?.sourate?.data?.attributes?.Numero}#${lastVersetDetails?.attributes?.numero}`"
    >
      <div class="verse-resume">
        <div class="demo-progress pr">
          <el-progress
            type="circle"
            :percentage="lastCheck?.attributes?.taux || 0"
            :width="45"
            :stroke-width="4"
            :color="customColors"
          />
        </div>
        <div>
          <h3>V°{{ lastVersetDetails?.attributes?.numero }}</h3>
          <h4>
            {{ lastCheck?.attributes?.sourate?.data?.attributes?.Phonetique }}
          </h4>
        </div>
        <p class="verse-fr" v-if="lastVersetDetails">
          {{ lastVersetDetails?.attributes.fr }}
        </p>

        <div class="reprendre">{{ $t("resumeVerse") }}</div>
      </div>
    </router-link>

    <!-- SI TAUX = 100 : on affiche la prochaine sourate -->
    <router-link
      v-else-if="nextSourahDetails"
      :to="`/sourate/${nextSourahDetails.attributes.Numero}#1`"
    >
      <div class="verse-resume">
        <div class="demo-progress pr">
          <!-- On force l’affichage à 100%, puisqu'on a fini la précédente -->
          <el-progress
            type="circle"
            :percentage="0"
            :width="45"
            :stroke-width="4"
            :color="customColors"
          />
        </div>
        <div>
          <h3>V°1</h3>
          <h4>
            {{ nextSourahDetails.attributes.Phonetique }}
          </h4>
        </div>
        <p class="verse-fr" v-if="nextSourahFirstVerse">
          {{ nextSourahFirstVerse?.attributes.fr }}
        </p>

        <div class="reprendre">{{ $t("start") }}</div>
      </div>
    </router-link>

    <router-link
      v-else
      :to="`/sourate/${lastCheck?.attributes?.sourate?.data?.attributes?.Numero}#${lastVersetDetails?.attributes?.numero}`"
    >
      <div class="verse-resume">
        <div class="demo-progress pr">
          <el-progress
            type="circle"
            :percentage="lastCheck?.attributes?.taux || 0"
            :width="45"
            :stroke-width="4"
            :color="customColors"
          />
        </div>
        <div>
          <h3>V°{{ lastVersetDetails?.attributes?.numero }}</h3>
          <h4>
            {{ lastCheck?.attributes?.sourate?.data?.attributes?.Phonetique }}
          </h4>
        </div>
        <p class="verse-fr" v-if="lastVersetDetails">
          {{ lastVersetDetails?.attributes.fr }}
        </p>

        <div class="reprendre">{{ $t("resumeVerse") }}</div>
      </div>
    </router-link>
  </span>
</template>

<script>
import { ref } from "vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ResumeDoonun",
  setup() {
    const customColors = [
      { color: "#FEC733", percentage: 20 },
      { color: "#FC68EB", percentage: 40 },
      { color: "#F88055", percentage: 60 },
      { color: "#FFD300", percentage: 80 },
      { color: "#2BC971", percentage: 100 },
    ];

    const format = (percentage) =>
      percentage === 100 ? "✔" : `${percentage}%`;
    const percentage = ref(50);

    return { percentage, format, customColors };
  },
  computed: {
    // Récupération de toutes les infos utiles
    ...mapGetters("resume", [
      "resume",
      "lastVersetDetails",
      "nextSourahDetails",
      "nextSourahFirstVerse",
    ]),
    ...mapGetters("login", ["isAuthenticated"]),

    // Prend le premier élément du store (dernier check)
    lastCheck() {
      return this.resume ? this.resume[0] : null;
    },
  },
  methods: {
    ...mapActions("resume", ["fetchChecks"]),
  },
  mounted() {
    this.fetchChecks(this.$i18n.locale);
  },
  created() {
    this.fetchChecks(this.$i18n.locale);
  },
  watch: {
    resume(newVal) {
      newVal; // Juste pour déclencher la reactivité
    },
    "$i18n.locale"() {
      this.fetchChecks(this.$i18n.locale);
    },
  },
};
</script>

<style scoped>
.conts {
  display: inline;
  width: 100%;
  min-width: 235px;
  max-width: 640px;
  min-height: 180px;
  background: var(--card);
  border-radius: 6px;
}
.verse-resume {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px 0;
  padding: 1.3rem;
  border-radius: 8px;
  background: var(--card);
  /* transition: 0.2s; */
  height: 100%;
}

.box-loding {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  width: 100%;
  min-width: 235px;
  min-height: 180px;
  height: 100%;
  background: var(--card);
}

.reprendre {
  display: inline-block;
  padding: 10px 15px;
  text-align: center;
  color: var(--colorBtn);
  background: var(--color);
  border-radius: 4px;
  font-size: 13px;
  font-weight: 400;
  /* transition: 0.2s ease-in-out; */
}

.reprendre:hover {
  background: var(--button);
  color: #fff;
}

.verse-resume h3 {
  font-size: 14px;
  font-weight: 600;
  font-family: "Inter";
  margin-bottom: 15px;
}

.verse-resume h4 {
  margin-top: -5px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter";
}

.verse-resume p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 14px;
}

.resume:hover {
  background: var(--button);
}

.pr {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-top: 20px;
  margin-right: 20px;
}

.pr svg {
  width: 50px !important;
  height: 50px !important;
}
</style>
