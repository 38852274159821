<template>
  <Head>
    <title>
      Sourate {{ sourate?.attributes?.Phonetique }} :
      {{ sourate?.attributes?.Titre }} : {{ sourate?.attributes?.Arabe }} -
      Doonun
    </title>
    <meta
      name="description"
      :content="`Découvrez la sourate ${sourate?.attributes?.Phonetique} en arabe et français, avec tajweed, phonétique et tafsir. Cliquez sur les mots pour écouter leur prononciation. Mémorisez chaque verset facilement avec Doonun.`"
    />
    <meta
      property="og:title"
      :content="`Sourate ${sourate?.attributes?.Phonetique} : ${sourate?.attributes?.Titre} : ${sourate?.attributes?.Arabe}`"
    />
    <meta
      property="og:url"
      :content="`https://www.doonun.com/sourate/${this.$route.params.id}`"
    />
    <meta
      property="og:description"
      :content="`Découvrez la sourate ${sourate?.attributes?.Phonetique} en arabe et français, avec tajweed, phonétique et tafsir. Cliquez sur les mots pour écouter leur prononciation. Mémorisez chaque verset facilement avec Doonun.`"
    />
    <meta property="og:type" content="website" />
    <meta
      property="og:image"
      content="https://cp.doonun.com/uploads/sourate_a3bfc2f69f.png"
    />
    <meta name="robots" content="index, follow" />

    <!-- Twitter -->
    <meta
      name="twitter:title"
      :content="`Sourate ${sourate?.attributes?.Phonetique} : ${sourate?.attributes?.Titre} : ${sourate?.attributes?.Arabe}`"
    />
    <meta
      name="twitter:description"
      :content="`Découvrez la sourate ${sourate?.attributes?.Phonetique} en arabe et français, avec tajweed, phonétique et tafsir. Cliquez sur les mots pour écouter leur prononciation. Mémorisez chaque verset facilement avec Doonun.`"
    />
    <meta
      name="twitter:image"
      content="https://cp.doonun.com/uploads/sourate_a3bfc2f69f.png"
    />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@doonunfr" />
    <meta name="twitter:creator" content="@doonunfr" />
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div class="container c-sourate">
    <div class="sourate">
      <div class="back">
        <Back :link="'/coran'" />
      </div>
      <div class="loading" v-if="loading">
        <Loading />
      </div>
      <div class="after-load" v-else>
        <div class="recitateur">
          <Recitateur @singer-changed="handleSingerChange" />
        </div>
        <h1>{{ sourate?.attributes?.Titre }}</h1>
        <h2>
          {{ sourate?.attributes?.Numero }} -
          {{ sourate?.attributes?.Phonetique }} :
          <span>{{ sourate?.attributes?.Arabe }}</span>
        </h2>
        <div class="toggle-v">
          <el-switch
            v-model="displayV"
            :active-text="$t('lectureV')"
            :inactive-text="$t('traductionV')"
          />
        </div>
        <div
          class="context c-down"
          v-if="sourate?.attributes?.Contexte != null"
        >
          <div
            id="markdown"
            class="markdown-body"
            :class="{ truncated: !showFullText }"
            v-html="$md.render(String(sourate?.attributes?.Contexte))"
          ></div>
          <div class="button-c">
            <p class="open-c" v-if="!showFullText" @click="toggleText">
              {{ $t("readcontxt") }}
            </p>
            <p class="close-c" v-else @click="toggleText">
              {{ $t("closecontxt") }}
            </p>
          </div>
        </div>
        <div
          class="bismi"
          v-if="this.$route.params.id !== '1' && this.$route.params.id !== '9'"
        >
          <Bismillah />
        </div>
        <div v-if="displayV" class="box-verset">
          <FullVerses :options="options" :verses="ayahs" />
        </div>
        <div v-else class="lists-ayahs" ref="ayahsContainer">
          <Verset
            v-for="(verset, index) in ayahs"
            :key="index"
            :surah="sourate?.attributes?.Phonetique"
            :index="index + 1"
            :options="options"
            :ref="'verset-' + index"
            :numero="verset?.attributes?.numero"
            :fr="verset?.attributes?.fr"
            :arabe="verset?.attributes?.arabe"
            :tajweed="verset?.attributes?.Tajweed"
            :tafsir="verset?.attributes?.tafsir"
            :phonetique="verset?.attributes?.phonetique"
            :checks="ayahsChecksList"
            :id="ayahsCheckId"
            :idVerse="verset?.id"
            :totalverses="sourate?.attributes?.Nombre"
            :idsourate="sourate?.attributes?.Numero"
            :playAr="verset.playAr"
            :playFr="verset.playFr"
            :favoris="Boolean(verset.attributes?.users?.data[0]?.id)"
            :reciter="selectedSinger"
            @playAudioSolo="playAudioSolo"
          />
        </div>
        <div class="charity">
          <Charity />
        </div>
      </div>
      <audio ref="audioPlayer" controls hidden></audio>
      <audio ref="audioPlayerAll" controls hidden></audio>
    </div>
  </div>
  <div class="container opt">
    <div class="cont-options">
      <Options
        :initial-options="options"
        @update:initialOptions="updateOptions"
        :playAllAr="playAllAr"
        :playAllFr="playAllFr"
        :playAllArFr="playAllArFr"
        @playAllAudio="playAllAudio"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Loading from "../../components/navigation/Loading.vue";
import Back from "../../components/navigation/Back.vue";
import Bismillah from "../../components/icons/Basmallah.vue";
import Verset from "../../components/coran/Verset.vue";
import FullVerses from "../../components/coran/FullVerses.vue";
import Recitateur from "../../components/coran/Recitateur.vue";
import Options from "../../components/coran/Options.vue";
import Charity from "../../components/global/Charity.vue";
import { Head } from "@vueuse/head";

export default {
  name: "sourate-doonun",
  components: {
    Bismillah,
    Verset,
    Options,
    Loading,
    Recitateur,
    FullVerses,
    Head,
    Charity,
    Back,
  },
  data() {
    return {
      showFullText: false,
      displayV: false,
      options: {
        lang: true,
        arabic: true,
        phonetic: true,
        tafsir: false,
        tajweed: false,
      },
      ayahs: [],
      loading: true,
      sourateId: this.$route.params.id,
      audioUrl: "",
      audioUrlAll: "",
      playAllAr: false,
      playAllFr: false,
      playAllArFr: false,
      lastAudio: 0,
      frAudioUrl: "",
      ArAudioUrl: "",
      currentVerseIndex: 0,
      currentAudioUrl: "",
      currentAudioUrlAll: "",
      selectedSinger: "",
    };
  },
  computed: {
    ...mapGetters("sourate", ["sourate"]),
    ...mapState("user", ["user"]),
    ayahsChecksList() {
      return this.sourate?.attributes?.versets_checks?.data[0]?.attributes
        ?.list_verset;
    },
    ayahsCheckId() {
      return this.sourate?.attributes?.versets_checks?.data[0]?.id;
    },
  },
  methods: {
    ...mapActions("sourate", ["fetchSourate"]),
    ...mapActions("user", ["fetchUser"]),
    updateOptions(newOptions) {
      const currentScrollPos = window.scrollY;

      this.options = newOptions;

      this.$nextTick(() => {
        window.scrollTo(0, currentScrollPos);
      });
    },
    toggleText() {
      this.showFullText = !this.showFullText;
      window.scrollTo(0, 0);
    },
    async fetchAndSetayahs(sourateId) {
      this.loading = true;
      try {
        await this.fetchSourate(sourateId);
        this.ayahs = this.sourate?.attributes?.ayahs?.data;
        this.currentVerseIndex = 0; // Initialiser currentVerseIndex ici
      } catch (error) {
        // console.error("Error fetching sourate:", error);
      } finally {
        this.loading = false;
      }
    },
    handleSourateIdChange() {
      let sourateId = this.$route.params.id;
      if (this.$i18n.locale === "en") {
        sourateId = parseInt(sourateId) + 114;
      }
      this.currentVerseIndex = 0; // Initialiser currentVerseIndex ici
      this.fetchAndSetayahs(sourateId.toString());
    },
    scrollToVerset(versetId) {
      let previousVersetId = null;
      if (previousVersetId) {
        const previousElement = document.getElementById(previousVersetId);
        previousElement?.classList.remove("reading-verse");
      }
      const element = document.getElementById(versetId);
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
      element?.classList.add("reading-verse");
    },
    handleSingerChange(newSinger) {
      this.selectedSinger = newSinger;
    },
    constructAudioUrl(reciter, sourateNumero, lang, versetNumero) {
      return `https://audio.doonun.com/sourates/${lang}/${reciter}/${sourateNumero}/${versetNumero}.mp3`;
    },

    playAudioSolo(numero, lang) {
      const reciter =
        lang === "ar"
          ? this.selectedSinger || "Hussary"
          : `${this.$t("reciterSurah")}`;
      const sourateNumero = this.sourateId;
      const newAudioUrl = this.constructAudioUrl(
        reciter,
        sourateNumero,
        lang,
        numero
      );
      const audioPlayer = this.$refs.audioPlayer;
      this.lastAudio = numero;

      this.stopAudioAll();

      if (this.currentAudioUrl === newAudioUrl && !audioPlayer.paused) {
        this.pauseAudioSolo();
        this.updatePlayState(numero, false, lang);
      } else if (this.currentAudioUrl === newAudioUrl && audioPlayer.paused) {
        this.resumeAudioSolo();
        this.updatePlayState(numero, true, lang);
      } else {
        this.currentAudioUrl = newAudioUrl;
        this.playAudioUnique(newAudioUrl, numero, lang);
      }
    },

    playAudioUnique(url, versetNumero, lang) {
      this.pauseAudioAll();
      const audioPlayer = this.$refs.audioPlayer;
      if (audioPlayer) {
        audioPlayer.src = url;
        audioPlayer.play();
        audioPlayer.onended = () => {
          this.updatePlayState(versetNumero, false, lang);
          this.currentAudioUrl = "";
        };
        this.updatePlayState(versetNumero, true, lang);
      }
    },

    pauseAudioSolo() {
      const audioPlayer = this.$refs.audioPlayer;
      if (audioPlayer && !audioPlayer.paused) {
        audioPlayer.pause();
      }
    },

    resumeAudioSolo() {
      const audioPlayer = this.$refs.audioPlayer;
      if (audioPlayer && audioPlayer.paused) {
        audioPlayer.play().catch((error) => {
          console.error("Error resuming audio:", error);
        });
      }
    },

    stopAudioSolo() {
      const audioPlayer = this.$refs.audioPlayer;
      if (audioPlayer) {
        audioPlayer.pause();
        audioPlayer.src = "";
        this.currentAudioUrl = "";
        this.updatePlayState(this.lastAudio, false);
      }
    },

    updatePlayState(numero, isPlaying, lang) {
      this.ayahs = this.ayahs.map((verset) => {
        if (verset.attributes.numero === numero) {
          return {
            ...verset,
            playAr: lang === "ar" ? isPlaying : false,
            playFr: lang === this.$t("lang") ? isPlaying : false,
          };
        }
        return { ...verset, playAr: false, playFr: false };
      });
      if (!isPlaying) {
        this.currentAudioUrl = "";
      }
    },

    // Lecture groupée
    async playAllAudio(lang) {
      if (this.isPlayingAll && this.currentLangAll === lang) {
        this.pauseAudioAll();
        this.isPlayingAll = false;
        this.updateGroupPlayState(false, lang);
        return;
      } else if (!this.isPlayingAll && this.currentLangAll === lang) {
        this.resumeAudioAll();
        this.isPlayingAll = true;
        this.updateGroupPlayState(true, lang);
        return;
      }

      this.stopAudioSolo();
      this.currentVerseIndex = this.lastAudio || 0; // Assurer que currentVerseIndex est initialisé correctement
      this.isPlayingAll = true;
      this.currentLangAll = lang;
      this.updateGroupPlayState(true, lang);

      await this.playNextVerse(lang);
    },
    async playNextVerse(lang) {
      if (
        this.currentVerseIndex === undefined ||
        this.currentVerseIndex >= this.ayahs.length
      ) {
        this.lastAudio = 0;
        this.scrollToVerset(1);
        this.stopAudioAll();
        return;
      }
      const reciter =
        lang === "ar"
          ? this.selectedSinger || "Hussary"
          : `${this.$t("reciterSurah")}`;
      const currentVerse = this.ayahs[this.currentVerseIndex];

      if (!currentVerse) {
        console.error("currentVerse is undefined");
        return;
      }

      const numero = currentVerse.attributes.numero;

      this.scrollToVerset(numero);

      if (lang === "ar-fr") {
        const arAudioUrl = this.constructAudioUrl(
          this.selectedSinger || "Hussary",
          this.sourateId,
          "ar",
          numero
        );
        await this.playAudioGroup(arAudioUrl);
        if (!this.isPlayingAll) return;

        const frAudioUrl = this.constructAudioUrl(
          `${this.$t("reciterSurah")}`,
          this.sourateId,
          `${this.$t("lang")}`,
          numero
        );
        await this.playAudioGroup(frAudioUrl);
      } else {
        const audioUrl = this.constructAudioUrl(
          reciter,
          this.sourateId,
          lang,
          numero
        );
        await this.playAudioGroup(audioUrl);
      }

      this.currentVerseIndex++;
      this.playNextVerse(lang);
    },

    playAudioGroup(url) {
      return new Promise((resolve) => {
        const audioPlayerAll = this.$refs.audioPlayerAll;
        this.pauseAudioSolo();
        audioPlayerAll.src = url;
        audioPlayerAll.play();
        audioPlayerAll.onended = resolve;
      });
    },

    pauseAudioAll() {
      const audioPlayerAll = this.$refs.audioPlayerAll;
      if (audioPlayerAll && !audioPlayerAll.paused) {
        audioPlayerAll.pause();
      }
    },

    resumeAudioAll() {
      const audioPlayerAll = this.$refs.audioPlayerAll;
      if (audioPlayerAll && audioPlayerAll.paused) {
        audioPlayerAll.play().catch((error) => {
          console.error("Error resuming audio:", error);
        });
      }
    },

    stopAudioAll() {
      const audioPlayerAll = this.$refs.audioPlayerAll;
      if (audioPlayerAll) {
        audioPlayerAll.pause();
        audioPlayerAll.src = "";
        this.currentVerseIndex = 0;
        this.isPlayingAll = false;
        this.currentLangAll = "";
        this.updateGroupPlayState(false);
      }
    },

    updateGroupPlayState(isPlaying, lang) {
      this.playAllAr = lang === "ar" ? isPlaying : false;
      this.playAllFr = lang === this.$t("lang") ? isPlaying : false;
      this.playAllArFr = lang === "ar-fr" ? isPlaying : false;
    },

    scrollToHash(hash) {
      if (hash.match(/^#\d+/)) {
        const elementId = hash.substring(1);
        this.$nextTick(() => {
          this.scrollToElement(elementId);
        });
      }
    },

    scrollToElement(elementId) {
      const attempts = 10;
      let currentAttempt = 0;
      const scroll = () => {
        const element = document.getElementById(elementId);
        if (element) {
          const windowHeight = window.innerHeight;
          const elementHeight = element.clientHeight;
          const elementPosition =
            element.offsetTop - (windowHeight - elementHeight) / 2;
          window.scrollTo({ top: elementPosition, behavior: "smooth" });
        } else if (currentAttempt < attempts) {
          setTimeout(scroll, 500);
          currentAttempt++;
        }
      };

      scroll();
    },
  },
  async created() {
    let sourateId = this.$route.params.id;
    if (this.$i18n.locale === "en") {
      sourateId = parseInt(sourateId) + 114;
    }
    await this.fetchAndSetayahs(sourateId.toString());
  },
  watch: {
    "$i18n.locale"() {
      this.sourateId = this.$route.params.id;
      this.lastAudio = 0;
      this.stopAudioAll();
      this.stopAudioSolo();
      this.playAllAr = false;
      this.playAllFr = false;
      this.playAllArFr = false;
      this.lastAudio = null;
      this.handleSourateIdChange();
    },
    "$route.params.id"() {
      this.sourateId = this.$route.params.id;
      this.lastAudio = 0;
      this.stopAudioAll();
      this.stopAudioSolo();
      this.playAllAr = false;
      this.playAllFr = false;
      this.playAllArFr = false;
      this.lastAudio = null;
      this.handleSourateIdChange();
    },

    $route: {
      immediate: true,
      handler(to, from) {
        if (to.hash) {
          this.scrollToHash(to.hash);
        } else if (from && to.path !== from.path) {
          window.scrollTo(0, 0);
        }
      },
    },
  },
};
</script>

<style scoped>
.container {
  /* padding-top: 30px; */
  position: relative;
  border-radius: 0 0 10px 10px;
}

.c-sourate {
  /* position: relative; */
}

.back {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 20px;
  margin-left: 20px;
}

.sourate {
  position: relative;
  padding: 100px 0 60px 0;
  display: block;
  margin: 70px auto 0 auto;
  width: 100%;
  min-height: 100vh;
  background: var(--card);
  border-radius: 6px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 80vh;
}

.recitateur {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

h1 {
  text-align: center;
  font-size: clamp(20px, 8vw, 30px);
  font-weight: 600;
}

h2 {
  text-align: center;
  font-size: clamp(20px, 8vw, 25px);
  font-weight: 600;
}

h2 span {
  font-family: "Noto Sans Arabic", sans-serif;
}

.toggle-v {
  display: flex;
  justify-content: center;
}

.c-down {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  margin: 10px auto 0 auto;
}

.c-down .open-c,
.c-down .close-c {
  display: inline-block;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
  padding: 10px 25px;
  background: var(--body);
  border-radius: 30px;
  font-size: 14px;
  font-weight: 500;
}

.truncated {
  position: relative;
  height: 150px;
  overflow: hidden !important;
}

.truncated::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: var(--truncated);
  z-index: 0;
}

.bismi {
  margin-top: 80px;
  display: flex;
  justify-content: center;
}

.lists-ayahs {
  margin-top: 60px;
}

.opt {
  position: sticky !important;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 100px;
}

.cont-options {
  display: block;
  margin: 0 auto;
  max-width: 1400px;
  width: 100%;
  background: var(--truncated);
}

.charity[data-v-000e649a] {
  width: 100%;
  display: flex;
  margin: 20px auto;
  justify-content: center;
}

@media (max-width: 1300px) {
  .sourate {
    padding: 4rem;
  }
}

@media (max-width: 900px) {
  .container {
    background: var(--card);
  }

  .sourate {
    padding: 100px 0 30px 0;
    margin-top: 0;
  }

  .back {
    margin-top: 80px;
    margin-left: 0;
  }
}
</style>
