<template>
  <div class="container-history">
    <div class="container">
      <div class="back">
        <Back :link="'/'" />
        <div class="share-button" @click="openSharePopup">
          <Share />
        </div>
      </div>
    </div>
    <!-- Header -->
    <div class="header">
      <div class="icon-container">
        <span>🌎</span>
      </div>

      <h1 class="title">{{ $t("titleHi") }}</h1>
    </div>

    <!-- Search and Year Filter Inputs -->
    <div class="search-year-container">
      <!-- Recherche par description -->
      <div class="input-wrapper">
        <input
          type="text"
          v-model="searchQuery"
          :placeholder="$t('holderWords')"
          class="input search-input"
        />
        <i class="fas fa-search search-icon"></i>
      </div>

      <!-- Filtre par année -->
      <div class="input-wrapper">
        <input
          type="number"
          v-model.number="filterYear"
          :placeholder="$t('holderYear')"
          class="input year-input"
          min="500"
        />
        <i class="fas fa-calendar-alt search-icon"></i>
      </div>
    </div>

    <!-- Affichage de l'année courante -->
    <h3 class="current-year" v-if="currentYear">
      {{ $t("textY") }} : {{ currentYear }}
    </h3>
    <h3 class="current-year" v-else>Aucun résultat</h3>

    <!-- Progress Bar -->
    <div class="progress-bar-container">
      <div class="progress-bar">
        <div class="progress" :style="{ width: progress + '%' }"></div>
      </div>
    </div>

    <!-- Cards Stack -->
    <div class="container-cards">
      <div
        class="cards-stack"
        @touchstart.prevent="handleTouchStart"
        @touchmove.prevent="handleTouchMove"
        @touchend.prevent="handleTouchEnd"
      >
        <div
          v-for="(event, index) in visibleCards"
          :key="event.id"
          class="card"
          :ref="index === 0 ? 'firstCard' : null"
          :style="getCardStyle(index)"
        >
          <div class="card-decorator">{{ event.emoji }}</div>
          <h3 class="card-date">
            <span v-if="event.month">{{ event.month + " " }}</span>
            {{ event.date }}
          </h3>
          <!-- Ajout de la classe conditionnelle 'truncate' -->
          <p class="card-description" :class="{ truncate: index > 0 }">
            {{ event.description }}
          </p>
        </div>
      </div>
    </div>
    <!-- Navigation -->
    <div class="navigation">
      <button
        @click="previousCard"
        class="nav-button"
        :disabled="currentIndex === 0"
      >
        <i class="fas fa-chevron-left nav-icon"></i>
      </button>
      <button
        @click="nextCard"
        class="nav-button"
        :disabled="currentIndex >= filteredEvents.length - 1"
      >
        <i class="fas fa-chevron-right nav-icon"></i>
      </button>
    </div>

    <!-- Share Popup -->
    <SharePopup
      :pageUrl="sharePageUrl"
      :message="$t('messageTimeline')"
      ref="sharePopup"
    />
  </div>
</template>

<script>
import SharePopup from "../components/global/Share.vue";
import Back from "../components/navigation/Back.vue";
import Share from "../components/icons/Share.vue";
import axios from "axios";

export default {
  name: "IslamChronology",
  components: {
    SharePopup,
    Share,
    Back,
  },
  data() {
    return {
      searchQuery: "",
      filterYear: null, // Filtre par année
      currentIndex: 0, // Index de la carte courante
      isSwiping: false, // Indicateur de glissement
      startY: 0, // Position de départ en Y
      moveY: 0, // Déplacement en Y
      timeline: [],
      isLoading: true, // Indicateur de chargement
      error: null, // Gestion des erreurs
      // paddingTop: 0, // Supprimé
      // cardHeight: 0, // Supprimé
    };
  },

  computed: {
    // Aplatit la timeline en une liste d'événements avec des IDs uniques
    flattenedEvents() {
      let idCounter = 1;
      return this.timeline
        .flatMap((yearEntry) =>
          yearEntry.events.map((event) => ({
            ...event,
            year: yearEntry.year,
            id: `${yearEntry.year}-${event.date}-${idCounter++}`,
          }))
        )
        .sort((a, b) => a.year - b.year || Number(a.date) - Number(b.date));
    },

    // Filtre les événements selon la recherche et l'année
    filteredEvents() {
      return this.flattenedEvents.filter((event) => {
        const matchesSearch = event.description
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
        const matchesYear = this.filterYear
          ? event.year === Number(this.filterYear)
          : true;
        return matchesSearch && matchesYear;
      });
    },

    // Année courante basée sur l'événement actuel
    currentYear() {
      const currentEvent = this.filteredEvents[this.currentIndex];
      return currentEvent ? currentEvent.year : null;
    },

    // Limite les cartes visibles à trois niveaux
    visibleCards() {
      return this.filteredEvents.slice(
        this.currentIndex,
        this.currentIndex + 3
      );
    },

    // Calcul du pourcentage de progression
    progress() {
      if (this.filteredEvents.length > 0) {
        return ((this.currentIndex + 1) / this.filteredEvents.length) * 100;
      }
      return 0;
    },

    sharePageUrl() {
      // Vérifie si l'objet query contient des clés
      if (this.$route.query && Object.keys(this.$route.query).length > 0) {
        // Sérialise les paramètres de requête en une chaîne de requête valide
        const queryString = new URLSearchParams(this.$route.query).toString();
        return `history?${queryString}`;
      }
      // Si aucun paramètre de requête, retourne simplement '/history'
      return `history`;
    },
  },

  methods: {
    async loadTimeline() {
      const lang = `${this.$t("linkTimeline")}`;
      try {
        const response = await axios.get(
          `https://cp.doonun.com/uploads/${lang}.json`
        );
        this.timeline = response.data.events;
        this.$nextTick(() => {
          // this.updateCardHeight(); // Supprimé
        });
      } catch (err) {
        console.error("Erreur lors du chargement du timeline:", err);
        this.error = "Impossible de charger les données.";
      } finally {
        this.isLoading = false;
      }
    },

    getCardStyle(index) {
      const isTopCard = index === 0;
      const translateY = isTopCard ? this.moveY : index * 15;
      // Définir un scale moindre pour les cartes derrière
      const baseScale = 1 - index * 0.08; // Exemple : 1, 0.92, 0.84
      const scale = isTopCard
        ? Math.min(Math.max(1 - Math.abs(this.moveY) * 0.002, baseScale), 1)
        : baseScale;
      const opacity = isTopCard
        ? Math.max(1 - Math.abs(this.moveY) * 0.005, 0)
        : 1;

      return {
        transform: `translateY(${translateY}px) scale(${scale})`,
        "z-index": this.visibleCards.length - index,
        opacity,
        backgroundColor: this.visibleCards[index].color || "var(--card)",
        transition: this.isSwiping
          ? "none"
          : "transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease",
      };
    },

    handleTouchStart(event) {
      this.startY = event.touches ? event.touches[0].clientY : event.clientY;
      this.isSwiping = true;
      console.log("Swipe started at:", this.startY);
    },

    handleTouchMove(event) {
      if (!this.filteredEvents.length) return;

      const currentY = event.touches ? event.touches[0].clientY : event.clientY;
      this.moveY = currentY - this.startY;

      console.log("Swipe moved to:", currentY, "MoveY:", this.moveY);
    },

    handleTouchEnd() {
      if (!this.filteredEvents.length) return;

      this.determineSwipeDirection();
      console.log("Swipe ended with moveY:", this.moveY);
      this.moveY = 0;
      this.isSwiping = false;
    },

    determineSwipeDirection() {
      const swipeThreshold = 50; // Seuil fixe en pixels

      if (this.moveY < -swipeThreshold) {
        // Swipe vers le haut
        this.swipeUp();
      } else if (this.moveY > swipeThreshold) {
        // Swipe vers le bas
        this.swipeDown();
      }
      // Si le swipe est insuffisant, ne fait rien
    },

    swipeUp() {
      if (this.currentIndex < this.filteredEvents.length - 1) {
        this.currentIndex++;
        console.log("Swiped Up to index:", this.currentIndex);
      }
    },

    swipeDown() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        console.log("Swiped Down to index:", this.currentIndex);
      }
    },

    previousCard() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        console.log("Previous Card to index:", this.currentIndex);
      }
    },

    nextCard() {
      if (this.currentIndex < this.filteredEvents.length - 1) {
        this.currentIndex++;
        console.log("Next Card to index:", this.currentIndex);
      }
    },

    handleKeyDown(event) {
      if (event.key === "ArrowRight") {
        this.nextCard();
      } else if (event.key === "ArrowLeft") {
        this.previousCard();
      }
    },

    shareEvent(event) {
      // Fonctionnalité de partage à implémenter
      alert(`Partager l'événement : ${event.description}`);
    },

    // Met à jour les paramètres de l'URL en fonction des filtres
    updateURLParams() {
      const query = {};

      if (this.searchQuery) {
        // Si search est présent, ne stocke pas year
        query.search = this.searchQuery;
      } else if (this.filterYear) {
        // Si search n'est pas présent, stocke year
        query.year = this.filterYear;
      }

      this.$router.replace({ query });
    },

    // Applique les paramètres de l'URL au composant
    applyURLParameters() {
      const { search, year } = this.$route.query;

      if (search) {
        this.searchQuery = search;
        // Réinitialiser l'index après filtrage
        this.currentIndex = 0;
      } else if (year) {
        this.filterYear = year;
        // Réinitialiser l'index après filtrage
        this.currentIndex = 0;
      }
    },

    // Partage l'URL actuelle
    sharePage() {
      const url = this.sharePageUrl;
      navigator.clipboard
        .writeText(url)
        .then(() => {
          alert("URL copiée dans le presse-papiers !");
        })
        .catch((err) => {
          console.error("Erreur lors de la copie de l'URL:", err);
        });
    },

    openSharePopup() {
      // Utilisation de la référence pour appeler la méthode togglePopup du SharePopup
      this.$refs.sharePopup.togglePopup();
    },
  },

  watch: {
    currentIndex() {
      // Aucune mise à jour de cardHeight nécessaire
    },
    filteredEvents() {
      // Réinitialiser l'index si nécessaire
      if (this.currentIndex >= this.filteredEvents.length) {
        this.currentIndex = this.filteredEvents.length - 1;
      }
      // Empêcher currentIndex d'être négatif
      if (this.currentIndex < 0) {
        this.currentIndex = 0;
      }
    },
    searchQuery() {
      this.currentIndex = 0;
      this.updateURLParams(); // Met à jour les paramètres de l'URL
    },
    filterYear() {
      // Réinitialise l'index courant lorsque l'année filtrée change
      this.currentIndex = 0;
      this.updateURLParams(); // Met à jour les paramètres de l'URL
    },
    "$i18n.locale"() {
      this.loadTimeline();
    },
  },

  mounted() {
    this.loadTimeline().then(() => {
      this.applyURLParameters(); // Applique les paramètres de l'URL après le chargement des données
    });
    document.addEventListener("keydown", this.handleKeyDown);
  },

  beforeUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  },
};
</script>
<style scoped>
/* Container */
.container-history {
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  background: linear-gradient(to bottom, #a855f7, #60a5fa, #06b6d4);
  margin-top: 60px;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.back {
  display: flex;
  justify-content: space-between;

  width: 100%;
}

/* Header */
.header {
  text-align: center;
  margin-bottom: 20px;
}

.icon-container {
  width: 4rem;
  height: 4rem;
  background-color: var(--card);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem auto;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  font-size: 1.5rem;
}

.title {
  display: block;
  margin: 0 auto 0.25rem auto;
  color: var(--card);
  font-size: 1.5rem;
  width: 60%;
  font-weight: 600;
  text-align: center;
  line-height: 1.6rem;
}

.subtitle {
  color: var(--card);
  font-size: 1.25rem;
}

/* Search and Year Filter Inputs */
.search-year-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 24rem;
  padding: 0 1rem;
}

.input-wrapper {
  position: relative;
  flex: 1;
}

.input {
  width: 100%;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  border-radius: 0.75rem;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border: none;
  outline: none;
  font-size: 1rem;
}

.search-input {
  /* Styles spécifiques si nécessaire */
}

.year-input {
  /* Styles spécifiques si nécessaire */
}

.search-icon {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #a1a1aa;
  pointer-events: none;
}

/* Affichage de l'année courante */
.current-year {
  color: var(--card);
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
  text-align: center;
}

/* Progress Bar */
.progress-bar-container {
  width: 100%;
  max-width: 200px;
  margin-bottom: 1rem;
}

.progress-bar {
  width: 100%;
  height: 6px;
  background-color: #ffffff72; /* Gris clair */
  border-radius: 4px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: var(--card); /* Violet */
  transition: width 0.3s ease-out;
}

/* Container des Cartes */
.container-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  min-height: 100vh;
  padding-bottom: 100px;
}

/* Cards Stack */
.cards-stack {
  position: relative;
  width: 100%;
  min-width: 240px;
  max-width: 640px;
  perspective: 1000px;
}

/* Card */
.card {
  position: absolute;
  width: 100%; /* Ensures card scales down within parent container */
  min-width: 240px;
  max-width: 100%; /* Prevents overflow of the parent container */
  min-height: 200px;
  background-color: var(--card);
  border-radius: 1.5rem;
  padding: 1.5rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  transform-origin: center center;
  backface-visibility: hidden;
  user-select: none;
}

.card-decorator {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.card-date {
  color: #a855f7;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.card-description {
  color: var(--color);
  font-size: 1rem;
  margin-bottom: 1.5rem;
  /* Styles communs pour toutes les descriptions */
  overflow: hidden;
}

/* Classe pour tronquer le texte à deux lignes */
.card-description.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limite à 2 lignes */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Share Button */
.share-button {
  display: flex;
  justify-content: center;
  align-items: center;
  right: 1.5rem;
  bottom: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  background: var(--card);
  border-radius: 50%;
  margin-bottom: 20px;
}

/* Suppression de l'effet de survol sur le bouton de partage */
.share-button:hover {
  /* color: #4b5563; */
}

/* Navigation */
.navigation {
  position: fixed;
  bottom: 0;
  padding-bottom: 5vh;
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.nav-button {
  width: 3rem;
  height: 3rem;
  border-radius: 9999px;
  background-color: var(--card);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  /* Suppression de la transition de couleur de fond */
  /* transition: background-color 0.2s; */
  border: none;
}

/* Suppression de l'effet de survol sur les boutons de navigation */
.nav-button:hover:not(:disabled) {
  /* background-color: #f3f4f6; */
}

.nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.nav-icon {
  color: #a855f7;
  font-size: 1rem;
}

/* Suppression de l'effet de survol global sur les cartes */
@media (hover: hover) {
  /* Aucun style de survol appliqué */
}

/* Media Queries pour les petits écrans */
@media (max-width: 768px) {
  .card {
    min-width: 100%; /* Cards should take full width on smaller screens */
    padding: 1rem; /* Reduce padding on smaller screens */
  }

  .title {
    font-size: 1.25rem; /* Adjust font size on smaller screens */
    width: 90%; /* Adjust width for better visibility */
  }

  .progress-bar-container {
    width: 100%; /* Ensure progress bar fits within the parent container */
  }
}
</style>
