<template>
  <router-link :to="`/categories/${link}`">
    <div
      class="bgcat"
      :style="{
        backgroundImage: `url('${getIconUrl(img)}')`,
      }"
    >
      <h3>{{ title }}</h3>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "category-post",
  props: {
    link: String,
    img: String,
    title: String,
  },
  methods: {
    getIconUrl(category) {
      return `https://cp.doonun.com${category}`;
    },
  },
};
</script>

<style scoped>
a {
  aspect-ratio: 16 / 9;
  width: 100%;
}
.bgcat {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 315px;
  height: 100%;
  aspect-ratio: 16 / 9;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: #fff;
  border-radius: 6px;
  padding: 10px;
  text-align: center;
  z-index: 1;
}

h3 {
  z-index: 3;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  backdrop-filter: blur(15px);
  background-color: rgba(255, 246, 246, 0.4);
  padding: 10px;
  border-radius: 30px;
  min-width: 180px;
  transition: 0.2s ease-in;
  border: 1px solid #fff;
}

.bgcat:hover h3 {
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid #000;
}
</style>
