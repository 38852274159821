<template>
  <div class="sun-up">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.766"
      height="22.007"
      viewBox="0 0 22.766 22.007"
    >
      <g id="sunrise_14322340" transform="translate(0 0)">
        <g id="Groupe_195" data-name="Groupe 195" transform="translate(0 0)">
          <path
            id="Tracé_542"
            data-name="Tracé 542"
            d="M214.731,20.8h-16.7a.759.759,0,0,1,0-1.518h2.312a6.45,6.45,0,1,1,12.071,0h2.312a.759.759,0,0,1,0,1.518Zm-3.971-1.518a4.933,4.933,0,1,0-8.753,0Zm-5.135,5.626-.223.222a.759.759,0,0,1-1.073-1.073l1.518-1.517a.757.757,0,0,1,1.073,0l1.518,1.517a.759.759,0,1,1-1.073,1.073l-.223-.222v1.962a.758.758,0,1,1-1.517,0Zm0-18.527a.758.758,0,1,1,1.517,0v1.9a.758.758,0,1,1-1.517,0Zm7.735,2.575a.759.759,0,0,1,1.073,1.073l-1.342,1.342a.759.759,0,0,1-1.073-1.073Zm1.75,8.807a.758.758,0,0,1,0-1.517h1.9a.758.758,0,0,1,0,1.517Zm-19.351,0a.758.758,0,0,1,0-1.517h1.9a.758.758,0,0,1,0,1.517Zm2.575-7.735a.759.759,0,1,1,1.073-1.073l1.342,1.342a.759.759,0,1,1-1.073,1.073Z"
            transform="translate(-195 -5.625)"
            fill-rule="evenodd"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "sun-up-icon",
};
</script>

<style scoped>
.sun-up {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sun-up svg {
  width: 21px;
  height: 21px;
}

.sun-up svg path {
  fill: var(--link);
}

.current-prayer svg path {
  fill: #fff;
}
</style>
