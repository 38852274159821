<!-- src/components/AnimationObjectif.vue -->
<template>
  <div class="container">
    <div
      class="achievement"
      :class="{ hide: hideAchievement }"
      @animationend="onAchievementAnimationEnd"
    >
      <div class="checkmark" ref="cardContainer">
        <img src="https://cp.doonun.com/uploads/valide_5b2c927a2d.png" alt="" />
      </div>
      <h1>{{ $t("Objectif") }}</h1>
      <p>{{ $t("txtObjectif1") }} {{ Goal }} {{ $t("txtObjectif2") }}</p>
    </div>
  </div>
</template>

<script>
import confetti from "../../assets/js/confetti";
export default {
  name: "AnimationObjectif",
  data() {
    return {
      hideAchievement: false,
      Goal: null,
    };
  },
  methods: {
    async fetchCurrentGoal() {
      try {
        this.Goal = await this.$store.dispatch("check/getCurrentDailyGoal");
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de l'objectif actuel :",
          error
        );
      }
    },
    triggerConfetti() {
      const cardRect = this.$refs.cardContainer.getBoundingClientRect();
      confetti({
        particleCount: 150,
        spread: 60,
        origin: {
          x: (cardRect.left + cardRect.right) / 2 / window.innerWidth,
          y: (cardRect.top + cardRect.bottom) / 2 / window.innerHeight,
        },
      });
    },
    handleAnimationEnd() {
      // Émettre l'événement 'close' pour notifier le parent
      this.$emit("close");
    },
    onAchievementAnimationEnd(event) {
      if (event.animationName === "popOut") {
        this.handleAnimationEnd();
      }
    },
  },
  mounted() {
    this.triggerConfetti();
    // Cacher l'achievement après 4 secondes
    setTimeout(() => {
      this.hideAchievement = true;
    }, 5000);
  },
  created() {
    this.fetchCurrentGoal();
  },
};
</script>

<style scoped>
.container {
  pointer-events: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.achievement {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  background: var(--card);
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  animation: popIn 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
  z-index: 100;
  width: 100%;
  max-width: 315px;
  border: 1px solid var(--body);
}

.achievement.hide {
  animation: popOut 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}

.achievement h1 {
  margin: 0;
  color: #72a910;
  font-size: 2rem;
  transform: scale(0);
  animation: textScale 0.5s 0.3s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}

.achievement p {
  margin: 0 0 0;
  color: var(--color);
  transform: scale(0);
  animation: textScale 0.5s 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}

.checkmark {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  /* background: #2ecc71; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
  transform: scale(0);
  animation: checkScale 0.5s 0.1s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}

.checkmark img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

/* Animations */
@keyframes popIn {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes popOut {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
}

@keyframes textScale {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes checkScale {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes drawCheck {
  from {
    stroke-dashoffset: 100;
  }
  to {
    stroke-dashoffset: 0;
  }
}
</style>
