<template>
  <div>
    <div
      class="hero-section"
      :style="{
        backgroundImage: `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.6699054621848739) 32%, rgba(0,0,0,0.24693627450980393) 75%, rgba(0,0,0,0.053658963585434205) 100%), url('https://cp.doonun.com${image}')`,
      }"
    >
      <div class="container">
        <div class="hero-content">
          <h1 class="title">{{ title }}</h1>
          <div class="movie-info">
            <span class="tag">HD</span>
            <span class="rating">
              <i class="fa-solid fa-star"></i> {{ note }}.0
            </span>
            <!-- <span class="date">2024-09-07</span> -->
            <span
              class="categories"
              v-for="category in categories"
              :key="category.id"
            >
              {{ category?.attributes?.titre }}
            </span>
          </div>
          <p class="description">{{ description }}</p>
          <div class="hero-buttons">
            <router-link :to="movieUrl">
              <button class="btn-watch" @click="showSubscriptionDialog()">
                <i class="fa-solid fa-play"></i> {{ $t("lookMovie") }}
              </button>
            </router-link>
            <button class="btn-details" @click="togglePopup">
              <i class="fa-solid fa-info-circle"></i> {{ $t("lookDetails") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Intégration du Popup -->
  <PopupMovie
    :isOpen="isOpen"
    :title="title"
    :free="free"
    :description="description"
    :image="image"
    :lien="lien"
    :lang="lang"
    :note="note"
    :cc="cc"
    :categories="categories"
    @update:isOpen="isOpen = $event"
  />

  <el-dialog
    v-model="centerDialogVisible"
    :title="`${$t('warningTitleM')}`"
    width="500"
    align-center
  >
    <span>{{ $t("messageDoovie") }}</span>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Ok</el-button>
        <el-button type="primary" @click="goToSubscription">
          {{ $t("btnSub1") }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import PopupMovie from "./Popup.vue";
import { mapGetters } from "vuex";

export default {
  name: "HeroSection",
  components: {
    PopupMovie,
  },
  data() {
    return {
      isOpen: false,
      centerDialogVisible: false,
    };
  },
  props: {
    title: {
      type: String,
    },
    free: Boolean,
    description: {
      type: String,
    },
    image: {
      type: String,
    },
    lien: {
      type: String,
    },
    lang: {
      type: String,
    },
    note: Number,
    cc: {
      type: String,
    },
    categories: Array,
  },
  computed: {
    ...mapGetters("user", ["isAuthenticated"]),
    ...mapGetters("subscribe", ["isSubscribed"]),
    movieUrl() {
      if (this.isAuthenticated) {
        if (this.isSubscribed || this.free) {
          return `/doovie/${this.id}`;
        } else {
          return "";
        }
      } else {
        return "/connexion";
      }
    },
  },
  methods: {
    togglePopup() {
      this.isOpen = !this.isOpen;
    },
    showSubscriptionDialog() {
      if (!this.isSubscribed || !this.free) {
        this.centerDialogVisible = true;
      }
    },
    goToSubscription() {
      this.centerDialogVisible = false;
      this.$router.push({ name: "tarifs" });
    },
  },
};
</script>

<style scoped>
.hero-section {
  height: 80vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: flex-end;
}

.container {
  margin: 0 auto;
}

.hero-content {
  color: white;
  max-width: 600px;
  margin-bottom: 100px;
}

.title {
  font-size: clamp(1.8rem, 5vw, 2.5rem);
  margin-bottom: 1rem;
  line-height: 1.2;
  color: #fff;
}

.movie-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.movie-info .tag {
  background: #fff;
  padding: 0.3rem 0.6rem;
  border-radius: 0.3rem;
  color: #000;
}

.movie-info .rating {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.movie-info .rating i {
  color: #ffd900;
}

.description {
  margin-bottom: 2rem;
  font-size: 1rem;
  line-height: 1.5;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.hero-buttons {
  display: flex;
  gap: 1rem;
}

.btn-watch {
  background: #e50914;
  color: white;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.btn-details {
  background: rgba(255, 255, 255, 0.2);
  color: white;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.btn-watch i,
.btn-details i {
  font-size: 1.2rem;
}

@media (max-width: 700px) {
  .hero-content {
    margin-bottom: 0;
  }

  .hero-section {
    height: 60vh !important;
  }
}
</style>
