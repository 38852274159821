<!-- VideoPlayer.vue -->
<template>
  <div style="position: relative; padding-top: 56.25%">
    <iframe
      :src="`https://iframe.mediadelivery.net/embed/314059/${url}?autoplay=true&loop=false&muted=false&preload=true&playsinline=true&responsive=true`"
      loading="lazy"
      style="border: 0; position: absolute; top: 0; height: 100%; width: 100%"
      allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
      allowfullscreen="true"
    ></iframe>
  </div>
</template>
<script>
export default {
  name: "VideoPlayer",
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
