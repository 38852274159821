// store/modules/check.js
import axios from "axios";

const mutations = {
  SET_WEEK_CHECKS(state, checks) {
    state.weekChecks = checks;
  },
  SET_CURRENT_GOAL(state, goal) {
    state.currentGoal = goal;
  },
};

const state = () => ({
  weekChecks: [],
  currentGoal: null,
});

const actions = {
  // 1) updateOrCreateCheck
  async updateOrCreateCheck(
    { rootGetters, dispatch },
    { id, list_verset, sourate, iduser, taux, lang }
  ) {
    try {
      const userId = rootGetters["user/userId"];
      let response;

      // Création ou mise à jour "versets-checks"
      if (id) {
        // Mise à jour : Décocher un verset → Décrémenter
        response = await axios.put(`/versets-checks/${id}`, {
          params: {
            fields: ["id", "list_verset"],
          },
          data: {
            id_user: iduser,
            sourate: sourate,
            id_sourate: String(sourate),
            taux: taux,
            list_verset: list_verset.join("|"),
            locale: lang,
          },
        });
        let newCount = 0;
        newCount++; // Incrémenter lors de la création (cochage)
        await dispatch("createOrUpdateGoalCheck", { newCount });
      } else {
        // Création : Cocher un verset → Incrémenter
        response = await axios.post(`/versets-checks`, {
          data: {
            users_permissions_user: userId,
            id_user: iduser,
            id_sourate: String(sourate),
            sourate: sourate,
            taux: taux,
            list_verset: list_verset.join("|"),
            locale: lang,
          },
        });
        let newCount = 0;
        newCount++; // Incrémenter lors de la création (cochage)
        await dispatch("createOrUpdateGoalCheck", { newCount });
      }

      return response.data;
    } catch (error) {
      console.error("Erreur updateOrCreateCheck:", error);
      throw error;
    }
  },

  // 2) createOrUpdateDailyGoal
  async createOrUpdateDailyGoal({ rootGetters, dispatch }, { goalValue }) {
    try {
      const userId = rootGetters["user/userId"];
      const existingGoals = await axios.get(`/daily-goals`, {
        params: {
          filters: { users_permissions_user: { id: userId } },
          fields: ["id", "Goal"],
        },
      });

      let dailyGoalId = null;
      if (existingGoals.data?.data?.length) {
        dailyGoalId = existingGoals.data.data[0].id;
      }

      let response;
      if (dailyGoalId) {
        // Mise à jour : Inclure l'ID de l'utilisateur pour maintenir la relation
        response = await axios.put(`/daily-goals/${dailyGoalId}`, {
          data: {
            Goal: goalValue,
            users_permissions_user: userId, // Ajouté ici
          },
        });
      } else {
        // Création : Inclure l'ID de l'utilisateur
        response = await axios.post(`/daily-goals`, {
          data: {
            Goal: goalValue,
            users_permissions_user: userId,
          },
        });
      }

      await dispatch("fetchGoalChecksForWeek");
      return response.data;
    } catch (error) {
      console.error("Erreur createOrUpdateDailyGoal:", error);
      throw error;
    }
  },

  // 3) createOrUpdateGoalCheck
  async createOrUpdateGoalCheck({ rootGetters, dispatch }, { newCount }) {
    try {
      const userId = rootGetters["user/userId"];

      // Récupération du dailyGoal de l'utilisateur
      const dailyGoalRes = await axios.get(`/daily-goals`, {
        params: {
          filters: { users_permissions_user: { id: userId } },
          fields: ["id", "Goal"],
        },
      });

      if (!dailyGoalRes.data?.data?.length) {
        throw new Error("Veuillez d’abord définir un DailyGoal.");
      }
      const dailyGoal = dailyGoalRes.data.data[0].attributes.Goal; // Récupérer la valeur de l'objectif
      const dailyGoalId = dailyGoalRes.data.data[0].id;

      // Filtrer sur le GoalCheck du jour
      const today = new Date();
      const startOfDay = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0
      ).toISOString();
      const endOfDay = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        23,
        59,
        59
      ).toISOString();

      const existingCheck = await axios.get(`/goal-checks`, {
        params: {
          filters: {
            users_permissions_user: { id: userId },
            createdAt: { $gte: startOfDay, $lte: endOfDay },
          },
          fields: ["id", "versesCount", "createdAt"],
        },
      });

      let checkId = null;
      let currentCount = 0;

      if (existingCheck.data?.data?.length) {
        checkId = existingCheck.data.data[0].id;
        // Assurez-vous que 'attributes' existe
        if (existingCheck.data.data[0].attributes) {
          currentCount = existingCheck.data.data[0].attributes.versesCount || 0;
        }
      }

      let versesCount = currentCount + newCount;
      if (versesCount < 0) {
        versesCount = 0;
      }

      // Déterminer si l'objectif est validé
      const isValide = versesCount >= dailyGoal;

      let response;
      if (checkId) {
        response = await axios.put(`/goal-checks/${checkId}`, {
          data: {
            versesCount,
            valide: isValide, // Mettre à jour le champ 'valide'
          },
        });
      } else {
        // Créez un nouveau GoalCheck si aucun n'existe
        response = await axios.post(`/goal-checks`, {
          data: {
            versesCount: newCount > 0 ? newCount : 0,
            users_permissions_user: userId,
            daily_goal: dailyGoalId,
            valide: isValide, // Définir le champ 'valide' lors de la création
          },
        });
      }

      // Rechargement de la liste
      await dispatch("fetchGoalChecksForWeek");
      return response.data;
    } catch (error) {
      console.error("Erreur createOrUpdateGoalCheck:", error);
      throw error;
    }
  },

  // 4) fetchGoalChecksForWeek
  async fetchGoalChecksForWeek({ rootGetters, commit }) {
    try {
      const userId = rootGetters["user/userId"];

      const now = new Date();
      const sevenDaysAgo = new Date(now.getTime() - 6 * 24 * 60 * 60 * 1000);

      const checks = await axios.get(`/goal-checks`, {
        params: {
          filters: {
            users_permissions_user: { id: userId },
            createdAt: { $gte: sevenDaysAgo.toISOString() },
          },
          sort: ["createdAt:asc"],
          fields: ["id", "versesCount", "valide", "createdAt"],
          populate: ["daily_goal"],
        },
      });

      commit("SET_WEEK_CHECKS", checks.data?.data || []);
    } catch (error) {
      console.error("Erreur fetchGoalChecksForWeek:", error);
    }
  },

  // 5) createDailyGoalIfNotExists
  async createDailyGoalIfNotExists({ rootGetters, dispatch }) {
    try {
      const userId = rootGetters["user/userId"];

      // Définir les bornes de la journée actuelle
      const today = new Date();
      const startOfDay = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0
      ).toISOString();
      const endOfDay = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        23,
        59,
        59
      ).toISOString();

      // Vérifier si un objectif existe déjà pour aujourd'hui
      const existingGoal = await axios.get(`/daily-goals`, {
        params: {
          filters: {
            users_permissions_user: { id: userId },
            createdAt: { $gte: startOfDay, $lte: endOfDay },
          },
          fields: ["id", "Goal"],
        },
      });

      if (existingGoal.data?.data?.length) {
        // Ne rien faire si un objectif existe déjà
        return;
      }

      // Si aucun objectif n'existe pour aujourd'hui, en créer un nouveau
      const defaultGoalValue = 10; // Valeur par défaut pour le nouvel objectif
      await dispatch("createDailyGoal", {
        goalValue: defaultGoalValue,
      });
    } catch (error) {
      console.error("Erreur createDailyGoalIfNotExists:", error);
      throw error;
    }
  },

  // 6) createDailyGoal
  async createDailyGoal({ rootGetters, dispatch }, { goalValue }) {
    try {
      const userId = rootGetters["user/userId"];
      const response = await axios.post(`/daily-goals`, {
        data: {
          Goal: goalValue,
          users_permissions_user: userId, // Associer l'ID de l'utilisateur
        },
      });
      await dispatch("fetchGoalChecksForWeek");
      return response.data;
    } catch (error) {
      console.error("Erreur createDailyGoal:", error);
      throw error;
    }
  },

  // 7) updateDailyGoal (Facultatif)
  async updateDailyGoal({ dispatch }, { dailyGoalId, goalValue }) {
    try {
      const response = await axios.put(`/daily-goals/${dailyGoalId}`, {
        data: { Goal: goalValue },
      });
      await dispatch("fetchGoalChecksForWeek");
      return response.data;
    } catch (error) {
      console.error("Erreur updateDailyGoal:", error);
      throw error;
    }
  },
  async getCurrentDailyGoal({ rootGetters, commit }) {
    try {
      const userId = rootGetters["user/userId"];
      const response = await axios.get(`/daily-goals`, {
        params: {
          filters: { users_permissions_user: { id: userId } },
          fields: ["id", "Goal"],
        },
      });

      if (response.data?.data?.length) {
        const goal = response.data.data[0].attributes.Goal;
        commit("SET_CURRENT_GOAL", goal);
        return goal;
      } else {
        commit("SET_CURRENT_GOAL", null);
        return null;
      }
    } catch (error) {
      console.error("Erreur getCurrentDailyGoal:", error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
