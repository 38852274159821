<template>
  <Head>
    <!-- Titre de la page -->
    <title>Articles - Doonun</title>

    <!-- Description Meta -->
    <meta
      name="description"
      content="Découvrez nos articles sur les sciences islamiques, le développement personnel et bien plus encore. Explorez des sujets variés, enrichissez votre connaissance et renforcez votre foi avec Doonun."
    />

    <!-- Robots Meta -->
    <meta name="robots" content="index, follow, noarchive" />

    <!-- Favicon -->
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />

    <!-- Canonical URL -->
    <link rel="canonical" href="https://www.doonun.com/articles" />

    <!-- Open Graph / Facebook -->
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.doonun.com/articles" />
    <meta property="og:title" content="Articles - Doonun" />
    <meta
      property="og:description"
      content="Découvrez nos articles sur les sciences islamiques, le développement personnel et bien plus encore. Explorez des sujets variés, enrichissez votre connaissance et renforcez votre foi avec Doonun."
    />
    <!-- <meta property="og:image" content="https://cp.doonun.com/uploads/articles_homepage_image.png" /> -->

    <!-- Twitter Cards -->
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:url" content="https://www.doonun.com/articles" />
    <meta name="twitter:title" content="Articles - Doonun" />
    <meta
      name="twitter:description"
      content="Découvrez nos articles sur les sciences islamiques, le développement personnel et bien plus encore. Explorez des sujets variés, enrichissez votre connaissance et renforcez votre foi avec Doonun."
    />
    <!-- <meta name="twitter:image" content="https://cp.doonun.com/uploads/articles_homepage_image.png" /> -->
    <meta name="twitter:site" content="@doonunfr" />
    <meta name="twitter:creator" content="@doonunfr" />

    <!-- Langue de la page -->
    <meta http-equiv="Content-Language" content="fr" />
  </Head>

  <div class="container">
    <div class="box-posts">
      <div class="left"></div>
      <div class="middle">
        <div class="top-c">
          <h1><i class="fa-solid fa-hashtag"></i> Catégories</h1>
        </div>
        <div class="categories">
          <div class="tags-container">
            <Category
              v-for="cat in categoriesList"
              :key="cat?.id"
              :title="cat?.attributes?.titre"
              :img="
                cat?.attributes?.Cover?.data?.attributes?.formats?.small?.url
              "
              :link="cat?.attributes?.url"
            />
          </div>
        </div>
      </div>
      <div class="right">
        <div class="list-best-posts">
          <h2><i class="fa-solid fa-arrow-trend-up"></i> Les plus lus</h2>
          <Trends
            v-for="item in topArticlesList"
            :key="item.id"
            :img="
              item?.attributes?.Cover?.data?.attributes?.formats?.small?.url
            "
            :date="formatDate(item?.attributes?.publishedAt)"
            :title="item?.attributes?.titre"
            :link="`${item?.attributes?.categories?.data[0]?.attributes?.url}/${item?.attributes?.url}`"
            :cat="item?.attributes?.categories?.data[0]?.attributes?.titre"
          />
        </div>
      </div>
    </div>
  </div>
  <Tabs />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Trends from "../../components/articles/Trends.vue";
import Category from "../../components/articles/Category.vue";
import moment from "moment";
import "moment/locale/fr";
import Tabs from "../../components/global/Tabs.vue";
import { Head } from "@vueuse/head";

export default {
  name: "HomePage",
  components: {
    Head,
    Tabs,
    Trends,
    Category,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("articles", ["categoriesList", "topArticlesList"]),
  },
  methods: {
    ...mapActions("articles", ["fetchCategories", "fetchTopArticles"]),
    getImageUrl(cover) {
      if (
        cover &&
        cover.data &&
        cover.data.attributes &&
        cover.data.attributes.url
      ) {
        return cover.data.attributes.url;
      }
      return "/default-image.jpg";
    },

    formatDate(date) {
      return moment(date).locale("fr").fromNow();
    },
  },
  async mounted() {
    await this.fetchTopArticles(this?.$i18n?.locale);
    await this.fetchCategories(this?.$i18n?.locale);
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    clearTimeout(this.resizeTimeout);
  },
  watch: {
    "$i18n.locale"() {
      this.fetchCategories(this?.$i18n?.locale);
      this.fetchTopArticles(this?.$i18n?.locale);
    },
  },
};
</script>

<style scoped>
.container {
  min-height: 100vh;
}
.box-posts {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 70px;
  min-height: 100vh;
}

.left,
.right {
  flex: 1;
}

.middle {
  width: 700px;
  max-width: 100%;
  padding: 0 20px;
  border-left: 1px dotted #ccc;
  border-right: 1px dotted #ccc;
}

/* debut tags */

.categories {
  margin-top: 30px;
  position: relative;
}

.top-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 25px 0;
}

.top-c a {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--card);

  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 50%;
}

.tags-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 1rem;
}

/* fin new tags */

.list-best-posts {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 1200px) {
  .left,
  .right {
    flex: 100%;
  }

  .left {
    order: 2;
  }

  .middle {
    order: 1;
    flex: 100%;
    padding: 0;
    border: none;
  }

  .news {
    grid-auto-flow: column;
    overflow-x: auto;
    scrollbar-width: none;
  }

  .new-posts .slide-c {
    display: block;
  }

  .right {
    order: 3;
  }
}

@media (max-width: 760px) {
  .last-item img {
    width: 100%;
    max-width: 100px;
    border-radius: 3px;
  }

  .list-best-posts {
    padding: 0;
  }
}
</style>
