<template>
  <div>
    <Head>
      <title>{{ coursesChapters?.attributes?.titre }}</title>
      <meta name="robots" content="Noindex, nofollow" />
      <link
        rel="icon"
        href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
      />
    </Head>
    <div class="course">
      <div class="container c">
        <div
          class="container-details-c"
          v-if="
            termine === 1 &&
            coursesChapters &&
            coursesChapters.attributes &&
            coursesChapters.attributes.suivis &&
            coursesChapters.attributes.suivis.data &&
            coursesChapters.attributes.suivis.data.length > 0 &&
            coursesChapters.attributes.suivis.data[0].attributes.pourcentage ===
              '100'
          "
        >
          <div class="box-c finished">
            <div class="Check-f">
              <CheckAnimation />
            </div>
            <span v-if="coursesChapters.attributes.gain">
              +{{ coursesChapters.attributes.gain }} points
            </span>
            <h1>
              {{ $t("bravoC") }} ! <br />
              {{ $t("youFinished") }}
            </h1>
            <h2 v-if="coursesChapters.attributes.titre">
              {{ coursesChapters.attributes.titre }}
            </h2>
            <p class="m-f">
              {{ $t("courageC") }}
            </p>
            <router-link to="/cours" ref="cardContainer">
              <div class="back-f">
                <Left />
                <p>{{ $t("allCourses") }}</p>
              </div>
            </router-link>
          </div>
        </div>
        <div v-else class="container-details-c">
          <div class="box-c">
            <div class="head-c">
              <div class="top-p-b">
                <div class="back-course">
                  <router-link :to="`/cours/${slug}`">
                    <el-icon><ArrowLeft /></el-icon>
                    <p>{{ $t("pageCourse") }}</p>
                  </router-link>
                  <div class="chp" ref="cardContainer">
                    <el-icon><InfoFilled /></el-icon>
                    <span>
                      {{ $t("currentChap") }} {{ chapitres?.numero }} :
                      {{ chapitres?.titre }}
                    </span>
                  </div>
                </div>

                <div class="pr">
                  <div class="demo-progress pr">
                    <el-progress
                      v-if="
                        coursesChapters?.attributes?.suivis?.data?.length > 0
                      "
                      type="circle"
                      :percentage="
                        Number(
                          coursesChapters.attributes.suivis.data[0].attributes
                            .pourcentage
                        )
                      "
                      :width="45"
                      :stroke-width="4"
                      :color="customColors"
                    />
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <transition name="fade">
              <div>
                <div
                  v-if="
                    showContent &&
                    chapitres &&
                    chapitres.contenus &&
                    chapitres.contenus.data.length > 0
                  "
                >
                  <CourseAudio
                    v-if="
                      chapitres.contenus.data[cCIndex]?.attributes?.audio?.data
                        ?.attributes?.url
                    "
                    :urlAudio="
                      chapitres.contenus.data[cCIndex].attributes.audio.data
                        .attributes.url
                    "
                  />
                  <hr
                    v-if="
                      chapitres.contenus.data[cCIndex]?.attributes?.audio?.data
                        ?.attributes?.url
                    "
                  />
                  <h1>
                    {{ chapitres.contenus.data[cCIndex].attributes.titre }}
                  </h1>
                  <div
                    id="markdown"
                    ref="markdownBody"
                    class="markdown-body"
                    v-html="
                      $md.render(
                        chapitres.contenus.data[cCIndex].attributes.cours
                      )
                    "
                  ></div>
                </div>
                <div
                  class="Quizz"
                  v-if="
                    showQuiz &&
                    chapitres.contenus.data[cCIndex]?.attributes?.quizzes?.data[
                      quizIndex
                    ]
                  "
                >
                  <div class="container box-quiz">
                    <form @submit.prevent="submitQuiz" ref="markdownBody">
                      <div class="quiz-option">
                        <h1>
                          {{
                            chapitres.contenus.data[cCIndex].attributes.quizzes
                              .data[quizIndex].attributes.Question
                          }}
                        </h1>
                        <p
                          class="introduction"
                          v-if="
                            chapitres.contenus.data[cCIndex].attributes.quizzes
                              .data[quizIndex].attributes.Instruction
                          "
                        >
                          {{
                            chapitres.contenus.data[cCIndex].attributes.quizzes
                              .data[quizIndex].attributes.Instruction
                          }}
                        </p>
                        <div
                          v-for="option in quizOptions"
                          :key="option.letter"
                          class="option"
                          :class="`option-${option.letter} ${optionClass(
                            option.letter
                          )}`"
                          @click="selectOption(option.letter)"
                        >
                          <div class="letter">
                            {{ option.letter }}
                          </div>
                          <label :for="`option-${option.letter}`">{{
                            option.text
                          }}</label>
                          <div class="check-l">
                            <el-icon><Check /></el-icon>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div class="navigation sticky" v-if="showContent">
          <div class="box">
            <div class="left-nav" @click="goBack()">
              <button class="back-k">
                <el-icon><ArrowLeft /></el-icon>
              </button>
            </div>
            <div class="middle">
              <p>{{ this.$route.params.id }}/{{ totalChapters }}</p>
            </div>
            <div
              class="right-nav"
              @click="showQuiz ? null : handleNavigation()"
            >
              <button class="next-k">
                <el-icon><ArrowRight /></el-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
// import Light from "../../components/icons/Light.vue";
import CheckAnimation from "../../components/icons/CheckAnimation.vue";
import correctSoundPath from "../../assets/audios/quiz/correct.wav";
import incorrectSoundPath from "../../assets/audios/quiz/error.mp3";
import CourseAudio from "../../components/global/CourseAudio.vue";
import confetti from "../../assets/js/confetti";
import { Head } from "@vueuse/head";

export default {
  name: "chapitre-d",
  components: {
    CheckAnimation,
    CourseAudio,
    Head,
  },
  data() {
    return {
      chapitres: null,
      slug: this.$route.params.slug,
      idCours: null,
      idSuivi: null,
      id: null,
      totalChapters: null,
      cCIndex: 0,
      quizIndex: 0,
      errorCount: 0,
      showContent: true,
      showQuiz: false,
      selectedAnswer: null,
      totalElementsCourse: 0,
      clickCount: 0,
      prevShowState: true,
      termine: null,
      lastErrorQuizIndex: -1,
      currentAudioElement: null,
    };
  },

  setup() {
    const customColors = [
      { color: "#FEC733", percentage: 20 },
      { color: "#FC68EB", percentage: 40 },
      { color: "#F88055", percentage: 60 },
      { color: "#0738FD", percentage: 80 },
      { color: "#2BC971", percentage: 100 },
    ];
    const format = (percentage) =>
      percentage === 100 ? "✔" : `${percentage}%`;
    const percentage = ref(50);

    return { percentage, format, customColors };
  },

  watch: {
    "$route.params.id"(newId, oldId) {
      if (newId !== oldId) {
        this.resetIndices();
        this.updateProgress(newId);
      }
    },
    "$i18n.locale"() {
      this.fetchChaptersDetails();
    },
    "chapitres.contenus.data[cCIndex].attributes.cours": {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          // Rendre la description audio
          this.renderAudioDescriptionSingle();
          // Détecter et styliser le texte en arabe
          this.detectArabicText();
        });
      },
    },
  },

  async created() {
    try {
      await this.fetchUser();
      await this.fetchChaptersDetails();
      await this.loadUserProgress();
      await this.detectArabicText();
      await this.renderAudioDescriptionSingle();

      if (!this.isAuthenticated) {
        this.$router.push("/connexion");
      } else {
        if (this.idSuivi === null) {
          this.$router.push("/cours");
        }
      }
    } catch (error) {
      console.error("Erreur lors de la création du composant:", error);
    }
  },

  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("login", ["isAuthenticated"]),
    ...mapGetters("cours", ["coursesChapters", "progressId"]),

    quizOptions() {
      const quiz =
        this.chapitres.contenus.data[this.cCIndex]?.attributes?.quizzes?.data[
          this.quizIndex
        ]?.attributes;
      return ["A", "B", "C", "D"].map((letter) => ({
        letter: letter,
        text: quiz ? quiz[letter] : "",
      }));
    },
  },

  methods: {
    ...mapActions("user", ["fetchUser"]),
    ...mapActions("cours", ["fetchCoursesAndChapters", "updateCourseProgress"]),
    ...mapActions("points", ["updateUserPoints"]),

    async loadUserProgress() {
      try {
        if (this.isAuthenticated && this.coursesChapters) {
          const progressResponse = this.coursesChapters.attributes.suivis.data;
          if (progressResponse && progressResponse.length > 0) {
            const progressData = progressResponse[0].attributes;
            this.idSuivi = progressResponse[0].id;
            this.cCIndex = parseInt(progressData.id_contenu);
            this.quizIndex = parseInt(progressData.id_quiz) || 0;
            this.lastErrorQuizIndex = parseInt(progressData.indexError) || -1;
            this.percentage = parseInt(progressData.pourcentage);
            this.clickCount = parseInt(progressData.clicks);
            this.idCours = parseInt(progressData.id_cours);
            this.id = parseInt(progressData.id_chapitre);
            this.termine = progressData.termine;

            this.showContent = Boolean(progressData.content_active);
            this.showQuiz = Boolean(progressData.quiz_active);
            const targetUrl = `/cours/${this.slug}/${progressData.id_chapitre}`;
            this.$router.push(targetUrl).then(() => {
              this.fetchChaptersDetails();
            });
          } else {
            // Aucune progression trouvée : charger le contenu du début
            this.fetchChaptersDetails();
          }
        }
      } catch (error) {
        // console.error("Error loading user progress:", error);
        this.fetchChaptersDetails();
      }
    },

    triggerConfetti() {
      const cardRect = this.$refs.cardContainer.getBoundingClientRect();
      confetti({
        particleCount: 150,
        spread: 60,
        origin: {
          x: (cardRect.left + cardRect.right) / 2 / window.innerWidth,
          y: (cardRect.top + cardRect.bottom) / 2 / window.innerHeight,
        },
      });
    },

    updatePoints() {
      if (this.user && this.user.hassanate) {
        let newPoints =
          Number(this.user.hassanate.points) +
          Number(this.coursesChapters.attributes.gain);
        this.user.hassanate.points = newPoints;
        // console.log("points : ", this.user.hassanate.points);
        this.updateUserPoints(newPoints);
      } else {
        console.error("L'utilisateur ou ses points ne sont pas définis.");
      }
    },

    async updateProgress(newChapterId) {
      await this.updateCourseProgress({
        id: this.coursesChapters.attributes.suivis.data[0]?.id,
        id_quiz: String(this.quizIndex >= 0 ? this.quizIndex : 0),
        indexError: String(
          this.lastErrorQuizIndex >= 0 ? this.lastErrorQuizIndex : "-1"
        ),
        id_contenu: String(this.cCIndex),
        id_chapitre: String(newChapterId || this.$route.params.id),
        pourcentage: String(this.percentage),
        clicks: String(this.clickCount),
        elements: String(this.totalElementsCourse),
        slug: String(this.slug),
        quiz_active: this.showQuiz,
        content_active: this.showContent,
        termine: String(this.termine),
      });

      // console.log("maj succès");

      this.fetchChaptersDetails();
    },

    async fetchChaptersDetails() {
      await this.fetchCoursesAndChapters({
        slug: this.slug,
        lang: this.$i18n.locale,
      });

      // Accédez aux données du cours depuis l'état Vuex
      const courseDetails = this.coursesChapters;
      // console.log("cours détails", courseDetails);

      if (courseDetails) {
        this.idCours = courseDetails.id; // Directement l'id de l'objet
        this.totalChapters = courseDetails.attributes?.chapitres.data.length;
        // Trouver le chapitre correspondant à l'ID du chapitre actuel
        const currentChapter = courseDetails.attributes?.chapitres.data.find(
          (chapitre) =>
            chapitre.attributes?.numero === parseInt(this.$route.params.id)
        );
        if (currentChapter) {
          this.chapitres = currentChapter.attributes;
          // console.log("ce chapitre : ", this.chapitres);
        }

        // Calculer le total des éléments du cours
        let totalElementsCourse = 0;

        courseDetails.attributes?.chapitres.data.forEach((chapitre) => {
          // Initialiser le total des éléments pour le chapitre courant
          let totalElementsForChapter = 0;

          chapitre.attributes?.contenus.data.forEach((contenu) => {
            totalElementsForChapter += 1; // Compter chaque contenu comme un élément
            totalElementsForChapter += contenu.attributes?.quizzes.data.length; // Ajouter le nombre de quiz comme éléments supplémentaires
          });

          // Ajouter le total des éléments du chapitre au total global des éléments du cours
          totalElementsCourse += totalElementsForChapter;
        });

        this.totalElementsCourse = totalElementsCourse;
        // console.log("totalElementsCourse :", this.totalElementsCourse);
        // console.log("total click :", this.clickCount);
        // console.log("total élement", this.totalElementsCourse);
      } else {
        // console.error("Aucun détail de cours trouvé dans l'état Vuex.");
      }
    },

    resetIndices() {
      this.cCIndex = 0;
      this.quizIndex = 0;
      this.showContent = true;
      this.showQuiz = false;
      this.selectedAnswer = null;
    },

    calculateProgress() {
      let nombreElementsParcourus = this.clickCount;
      if (nombreElementsParcourus > this.totalElementsCourse) {
        this.percentage = 100;
      } else {
        this.percentage = Math.round(
          (nombreElementsParcourus / this.totalElementsCourse) * 100
        );

        // console.log("taux : ", this.percentage);
      }
    },

    incrementClickCount() {
      if (this.clickCount < this.totalElementsCourse) {
        this.clickCount++;
        this.calculateProgress();
        this.updateProgress();
        this.detectArabicText();
        this.renderAudioDescriptionSingle();
      }
    },

    decrementClickCount() {
      if (this.clickCount > 0) {
        this.clickCount--;
        this.calculateProgress();
        this.updateProgress();
        this.detectArabicText();
        this.renderAudioDescriptionSingle();
      }
    },

    goBack() {
      if (this.showContent !== this.prevShowState) {
        if (this.quizIndex > 0) {
          this.quizIndex--;
          this.decrementClickCount();
          this.updateProgress();
        } else {
          this.showQuiz = false;
          this.showContent = true;
          this.decrementClickCount();
          this.updateProgress();
          this.detectArabicText();
          this.renderAudioDescriptionSingle();
        }
      } else if (this.showContent && this.cCIndex > 0) {
        this.cCIndex--;
        this.quizIndex =
          this.chapitres.contenus.data[this.cCIndex].attributes?.quizzes.data
            .length - 1;
        this.decrementClickCount();
        this.updateProgress();
        this.detectArabicText();
        this.renderAudioDescriptionSingle();
        if (
          this.chapitres.contenus.data[this.cCIndex].attributes?.quizzes.data
            .length > 0
        ) {
          this.showQuiz = true;
          this.showContent = false;
          this.decrementClickCount();
          this.updateProgress();
          this.detectArabicText();
          this.renderAudioDescriptionSingle();
        }
      }
      window.scrollTo(0, 0);
    },

    goForward() {
      if (this.showContent) {
        if (
          this.chapitres.contenus.data[this.cCIndex].attributes?.quizzes.data
            .length > 0
        ) {
          this.showContent = false;
          this.showQuiz = true;
          this.reprendreQuiz();
          this.incrementClickCount();
          this.updateProgress();
          this.detectArabicText();
          this.renderAudioDescriptionSingle();
        } else {
          this.moveToNextContentOrChapter();
          this.incrementClickCount();
          this.detectArabicText();
          this.renderAudioDescriptionSingle();
        }
      } else {
        if (
          this.quizIndex <
          this.chapitres.contenus.data[this.cCIndex].attributes?.quizzes.data
            .length -
            1
        ) {
          this.quizIndex++;
          this.incrementClickCount();
          this.updateProgress();
          this.detectArabicText();
          this.renderAudioDescriptionSingle();
          // console.log("Quiz index", this.quizIndex);
        } else {
          this.moveToNextContentOrChapter();
          this.showContent = true;
          this.showQuiz = false;
          this.incrementClickCount();
          this.updateProgress();
          this.detectArabicText();
          this.renderAudioDescriptionSingle();
        }
      }
      window.scrollTo(0, 0);
    },

    async moveToNextContentOrChapter() {
      this.quizIndex = 0;
      this.showQuiz = false;
      if (this.cCIndex < this.chapitres.contenus.data.length - 1) {
        // Passer au contenu suivant
        this.cCIndex++;
        if (
          this.chapitres.contenus.data[this.cCIndex].attributes?.quizzes.data
            .length > 0
        ) {
          this.showQuiz = !this.showContent;
          // this.showContent = false;
        } else {
          this.showContent = !this.showQuiz;
        }
        this.updateProgress();
        this.detectArabicText();
        this.renderAudioDescriptionSingle();
      } else {
        // Passer au chapitre suivant
        if (parseInt(this.$route.params.id) < this.totalChapters) {
          let nextChapterId = parseInt(this.$route.params.id) + 1;
          // Rediriger vers la prochaine page de chapitre
          this.$router.push(`/cours/${this.slug}/${nextChapterId}`);
          this.quizIndex = 0;
          this.showQuiz = false;
        } else {
          // Rediriger vers la page "fin" lorsque vous n'avez plus de chapitres
          this.triggerConfetti();
          if (this.termine !== "1") {
            this.updatePoints();
            // console.log("points mise à jour");
          } else {
            // console.log("les points ont déjà été distribués");
          }
          this.termine = 1;
          this.updateProgress();
          this.detectArabicText();
          this.renderAudioDescriptionSingle();
          // this.$router.push(`/fin/${this.slug}`);
        }
      }

      this.selectedAnswer = null;
      // console.log("moveToNextContentOrChapter");
      window.scrollTo(0, 0);
    },

    shuffleQuizOptions() {
      for (let i = this.quizOptions.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [this.quizOptions[i], this.quizOptions[j]] = [
          this.quizOptions[j],
          this.quizOptions[i],
        ];
      }
    },

    selectOption(option) {
      this.selectedAnswer = option;
      this.validateQuiz();
    },

    validateQuiz() {
      const quiz =
        this.chapitres.contenus.data[this.cCIndex].attributes?.quizzes.data[
          this.quizIndex
        ].attributes;
      let correctAnswer = quiz?.reponse;
      if (this.selectedAnswer === correctAnswer) {
        // Réinitialiser le compteur d'erreurs en cas de réponse correcte
        this.errorCount = 0;
        this.lastErrorQuizIndex = -1;
        // Son pour une réponse correcte
        const correctSound = new Audio(correctSoundPath);
        correctSound.play();
        this.updateProgress();
        setTimeout(() => {
          this.selectedAnswer = null;
          this.goForward();
        }, 1000);
      } else {
        // Son pour une réponse incorrecte
        const incorrectSound = new Audio(incorrectSoundPath);
        incorrectSound.play();

        // Incrémenter le compteur d'erreurs
        this.errorCount++;

        // Mélanger les options de quiz
        this.shuffleQuizOptions();

        if (this.errorCount >= 2) {
          this.lastErrorQuizIndex = this.quizIndex;
          this.decrementClickCount();
          // console.log("index erreur : ", this.lastErrorQuizIndex);
          setTimeout(() => {
            this.showContent = true;
            this.showQuiz = false;
            this.errorCount = 0;
          }, 1000);
        }

        setTimeout(() => {
          this.selectedAnswer = null;
        }, 1000);
      }
      // console.log("validateQuiz");
    },

    reprendreQuiz() {
      if (this.lastErrorQuizIndex !== -1) {
        // Reprendre le quiz à partir de l'indice de la dernière erreur
        this.quizIndex = this.lastErrorQuizIndex;
        this.showQuiz = true;
        this.showContent = false;
      } else {
        this.quizIndex = 0;
      }
    },

    optionClass(option) {
      const quiz =
        this.chapitres.contenus.data[this.cCIndex].attributes?.quizzes.data[
          this.quizIndex
        ].attributes;
      let correctAnswer = quiz?.reponse;

      // Si une réponse a été sélectionnée
      if (this.selectedAnswer) {
        // Si la réponse sélectionnée est correcte
        if (this.selectedAnswer === correctAnswer) {
          return option === this.selectedAnswer ? "green" : "red";
        } else {
          // Si la réponse sélectionnée est incorrecte, appliquez 'red' uniquement à cette option
          return option === this.selectedAnswer ? "red" : "";
        }
      }
      return "";
    },

    renderAudioDescriptionSingle() {
      const markdownElement = document.getElementById("markdown");
      if (!markdownElement) return;

      // 1. Sélectionner tous les <a href="...mp3"> dans les cellules <td>
      const mp3Links = markdownElement.querySelectorAll('td a[href$=".mp3"]');
      if (!mp3Links.length) return;

      let currentAudio = null;

      mp3Links.forEach((audioLink) => {
        // Empêcher l'ouverture du lien
        audioLink.addEventListener("click", (event) => {
          event.preventDefault();
        });

        // Récupérer la cellule <td> parente
        const parentTD = audioLink.closest("td");
        if (!parentTD) return;

        // Créer un <p> pour remplacer <a>
        const newParagraph = document.createElement("span");
        newParagraph.classList.add("my-audio-css");

        // Récupérer le texte + URL
        const textContent = audioLink.textContent.trim();
        const audioHref = audioLink.href;

        newParagraph.textContent = textContent;
        newParagraph.setAttribute("data-src", audioHref);

        // Remplacer le <a> par <p>
        parentTD.innerHTML = "";
        parentTD.appendChild(newParagraph);

        // Au clic : jouer l'audio
        newParagraph.addEventListener("click", () => {
          if (currentAudio) {
            currentAudio.pause();
          }
          const newAudio = new Audio(audioHref);
          currentAudio = newAudio;
          newAudio.play();
        });
      });
    },

    containsArabic(text) {
      const arabicRegex = /[\u0600-\u06FF]/; // Plage de caractères arabes
      return arabicRegex.test(text);
    },

    detectArabicText() {
      this.$nextTick(() => {
        const markdownBody = this.$refs.markdownBody;
        if (markdownBody) {
          // Sélectionner les balises <p>, <span> et <td>
          const elements = markdownBody.querySelectorAll(
            "p, span, td, strong, label, li, h1, h2, h3"
          );
          elements.forEach((element) => {
            // Créer un TreeWalker pour parcourir les nœuds texte
            const walker = document.createTreeWalker(
              element,
              NodeFilter.SHOW_TEXT,
              null,
              false
            );

            let node;
            while ((node = walker.nextNode())) {
              const parent = node.parentNode;

              if (this.containsArabic(node.textContent)) {
                // Vérifier si le parent est déjà un <span>
                if (parent.tagName.toLowerCase() === "span") {
                  // Ajouter la classe 'arabic-course' si elle n'est pas déjà présente
                  if (!parent.classList.contains("arabic-course")) {
                    parent.classList.add("arabic-course");
                  }
                } else {
                  // Remplacer le texte arabe par un <span> avec la classe 'arabic-course'
                  const replacedHTML = node.textContent.replace(
                    /([\u0600-\u06FF]+)/g,
                    '<span class="arabic-course">$1</span>'
                  );

                  // Créer un fragment à partir du HTML remplacé
                  const tempDiv = document.createElement("div");
                  tempDiv.innerHTML = replacedHTML;

                  // Insérer le fragment avant le nœud actuel
                  while (tempDiv.firstChild) {
                    parent.insertBefore(tempDiv.firstChild, node);
                  }

                  // Supprimer le nœud texte original
                  parent.removeChild(node);
                }
              }
            }
          });
        } else {
          // console.error("Référence 'markdownBody' non trouvée.");
        }
      });
    },

    handleNavigation() {
      if (this.showContent) {
        if (
          this.chapitres.contenus.data[this.cCIndex].attributes?.quizzes.data
            .length > 0
        ) {
          this.showContent = false;
          this.showQuiz = true;
        } else {
          this.moveToNextContentOrChapter();
        }
      } else {
        if (
          this.quizIndex <
          this.chapitres.contenus.data[this.cCIndex].attributes?.quizzes.data
            .length -
            1
        ) {
          this.quizIndex++;
        } else {
          this.moveToNextContentOrChapter();
        }
      }
      this.incrementClickCount();
      this.updateProgress();
      window.scrollTo(0, 0);
    },
  },

  async mounted() {
    this.$nextTick(() => {
      this.renderAudioDescriptionSingle();
    });
  },

  updated() {
    this.detectArabicText();
    this.renderAudioDescriptionSingle();
  },
};
</script>

<style scoped>
.course {
  position: relative;
  margin-top: 10px;
  padding-top: 70px;
  background: var(--card);
  min-height: 100vh;
}

.container {
  position: relative;
  min-height: 100vh;
  width: 100%;
  height: 100%;
}

.head-c {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: var(--body);
  padding: 20px 20px 0 20px;
  border-radius: 6px;
  margin-bottom: 20px;
}

.top-p-b {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.back-course a {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  transition: 0.2s ease-in;
  margin-bottom: 10px;
}

.back-course a:hover {
  gap: 10px;
}

.container-details-c {
  background: var(--card);
  border-radius: 6px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 200px;
  padding: 50px 20px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active en <2.1.8 */ {
  opacity: 0;
}

.box-c {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  padding-bottom: 200px;
}

.finished {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Check-f {
  width: 100px;
}

.finished h1 {
  text-align: center;
  font-size: 18px;
  margin-bottom: 0px;
}

.finished h2 {
  font-size: 20px;
  text-align: center;
  margin-bottom: 0;
}

.finished p {
  text-align: center;
  font-size: 16px;
}

.finished .m-f {
  margin-bottom: 20px;
}

.finished span {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
  color: orange !important;
}

.finished .back-f {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color);
  padding: 9px 33px 9px 25px;
  border-radius: 5px;
  gap: 5px;
  transition: 0.2s ease-in;
}

.finished .back-f:hover {
  gap: 10px;
  background: var(--button);
}

.finished .back-f p {
  color: var(--colorBtn);
  font-weight: 700;
  font-size: 14px;
}

.finished .back-f:hover p {
  color: #fff;
}

hr {
  border: 1px solid var(--body);
}

.markdown-body {
  width: 100%;
  margin-top: 20px;
  background: transparent !important;
}

.markdown-body #player {
  width: 100% !important;
}

.back {
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: 0.2s ease-in;
}

.back:hover {
  gap: 10px;
}

.back p {
  font-size: 14px;
  cursor: pointer;
}

h1 {
  font-size: 20px;
  line-height: 1.4em;
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  margin-bottom: 15px;
}

.chp {
  display: flex;
  align-items: start;
  gap: 4px;
  margin-bottom: 20px;
}

.chp i {
  margin-top: 4px;
}

.container-details-c span {
  color: var(--color);
  /* font-style: italic; */
  font-weight: 500;
}

.markdown-body p {
  font-size: 18px !important;
  margin-bottom: 10px;
  padding: 0;
}

.markdown-body a {
  color: #0066cc;
  text-decoration: none;
}

.markdown-body a:hover {
  text-decoration: underline;
}

.navigation {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: end;
  margin: 0 auto 0 auto;
  min-height: 200px;
  max-width: 1400px;
  width: 100%;
  padding-bottom: 5vh;
  background: var(--truncated);
}

.navigation .box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 250px;
}

.navigation .box button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  font-size: 20px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: 0.2s ease-in;
}

.navigation .box button:hover {
  background: var(--color);
}

.navigation .box button:hover i {
  color: #fff;
}

.navigation .box .middle span {
  font-size: 14px;
  opacity: 0.6;
}

.controls-c {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  width: 100%;
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 999;
  border-radius: 5px 5px 0 0;
  padding-top: 10px;
  padding-bottom: 5vh;
}

.c-control {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-control div.nxt {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  background: var(--color);
  transition: 0.2s ease-in;
}

.c-control div.nxt:hover {
  background: var(--button);
}

.Quizz {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  min-height: 100vh;
  background: var(--button);
}

.box-quiz {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  max-width: 700px;
  min-height: 100vh;
  height: 100%;
}
.quiz {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* margin: auto; */
}

.Quizz .container {
  height: 90vh;
  overflow-y: auto;
  scrollbar-width: none;
}

.box-quiz h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
  color: #fff;
  padding: 20px 30px;
  font-size: 20px;
  text-align: center;
  margin-bottom: 0;
  width: 100%;
  /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
  border-radius: 6px;
  text-wrap: balance;
}

.introduction {
  background: var(--card);
  padding: 15px 20px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 0;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 6px;
}

.quiz-option {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  margin-bottom: 20px;
  gap: 10px;
  width: 100%;
  padding: 10px;
  background: var(--truncated);
  border-radius: 6px;
}

.quiz-option .option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 15px 20px;
  cursor: pointer;
  transition: 0.2s ease-in;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 6px;
}

.quiz-option .option .check-l {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 25px;
  min-height: 25px;
  max-width: 25px;
  max-height: 25px;
  border-radius: 50%;
  border: 1px solid var(--color);
}

.quiz-option .option .check-l i {
  opacity: 0;
}

.quiz-option .option:hover {
  transform: scale(1.01);
}

.option-A,
.option-B,
.option-C,
.option-D {
  background: var(--body);
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.quiz-option .input-quiz {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quiz-option .green {
  background: #bffff0;
  border: 1px solid #42a58f;
}

.quiz-option .green .check-l {
  background: #42a58f;
  border: 1px solid #42a58f;
}
.quiz-option .green .check-l i {
  opacity: 1;
  color: #fff;
}

.quiz-option .red {
  background: #ffbfbf;
  border: 1px solid #a54242;
}

.quiz-option .red .check-l {
  background: #a54242;
  border: 1px solid #a54242;
}

.quiz-option .red .check-l i {
  opacity: 1;
  color: #fff;
}

.quiz-option .red label,
.quiz-option .red .letter,
.quiz-option .green label,
.quiz-option .green .letter {
  color: #000 !important;
}

.quiz-option div label {
  font-size: 16px;
  cursor: pointer;
}

.quiz-option label {
  display: block;
  color: var(--color);
}

.quiz-option input {
  width: 25px;
  height: 25px;
  cursor: pointer;
  background: red;
}

.pr {
  display: flex;
  align-items: start;
  justify-content: end;
  width: 60px;
  height: 60px;
  border-radius: 50px;
}

.pr svg {
  width: 60px !important;
  height: 60px !important;
}

@media (max-width: 830px) {
  .c {
    margin-top: -40px;
    padding-top: 20px;
    background: var(--card) !important;
  }

  .container-details-c {
    padding: 0 0 200px 0;
  }

  .c-control {
    justify-content: end;
  }
}
</style>
