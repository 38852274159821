<template>
  <div class="search-container">
    <input
      type="text"
      v-model="searchQuery"
      @input="handleSearch"
      placeholder="Recherchez des articles..."
      class="search-input"
    />
    <div v-if="isLoading" class="loading">
      <p>Recherche en cours...</p>
    </div>
    <div v-else>
      <div v-if="searchResults && searchResults.length > 0" class="results">
        <DefaultPost
          v-for="article in searchResults"
          :key="article.id"
          :img="getImageUrl(article.attributes.Cover)"
          :date="formatDate(article.attributes.publishedAt)"
          :title="article.attributes.titre"
          :desc="article.attributes.Meta"
          :lien="`${article.attributes.categories.data[0].attributes.url}/${article.attributes.url}`"
          :cat="article.attributes.categories.data[0].attributes.titre"
        />
        <button
          v-if="currentPage < totalPages"
          @click="loadMore"
          class="load-more-button"
        >
          Charger plus d'articles
        </button>
      </div>
      <div v-else class="no-results">
        <p v-if="searchQuery">Aucun article trouvé pour "{{ searchQuery }}".</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DefaultPost from "../../components/articles/DefaultPost.vue";
import moment from "moment";
import "moment/locale/fr";
import debounce from "lodash.debounce";

export default {
  name: "ArticleSearch",
  components: {
    DefaultPost,
  },
  data() {
    return {
      searchQuery: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("articles", [
      "searchResultsList",
      "currentPage",
      "totalPages",
    ]),
    searchResults() {
      return this.searchResultsList;
    },
  },
  methods: {
    ...mapActions("articles", ["searchArticles"]),
    handleSearch: debounce(function () {
      if (this.searchQuery.trim() === "") {
        // Si la requête est vide, réinitialisez les résultats
        this.$store.commit("articles/CLEAR_SEARCH_RESULTS");
        this.isLoading = false;
        return;
      }
      this.isLoading = true;
      this.$store.commit("articles/CLEAR_SEARCH_RESULTS"); // Réinitialiser les résultats avant une nouvelle recherche
      this.performSearch();
    }, 500), // Délais de 500ms pour éviter les appels excessifs
    async performSearch() {
      await this.searchArticles({
        query: this.searchQuery,
        lang: this.$i18n.locale,
        page: 1, // Démarrer à la première page
      });
      this.isLoading = false;
    },
    async loadMore() {
      if (this.currentPage < this.totalPages) {
        this.isLoading = true;
        await this.searchArticles({
          query: this.searchQuery,
          lang: this.$i18n.locale,
          page: this.currentPage + 1,
        });
        this.isLoading = false;
      }
    },
    getImageUrl(cover) {
      if (
        cover &&
        cover.data &&
        cover.data.attributes &&
        cover.data.attributes.url
      ) {
        return cover.data.attributes.url;
      }
      return "/default-image.jpg";
    },
    formatDate(date) {
      return moment(date).locale("fr").fromNow();
    },
  },
};
</script>

<style scoped>
.search-container {
  max-width: 800px;
  margin: 0 auto;
}

.search-input {
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--card);
  border: none;
  outline: none;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.search-input:hover {
  color: var(--placeholder);
}

.loading,
.no-results {
  text-align: center;
  color: #777;
  font-size: 18px;
}

.results {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.load-more-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.load-more-button:hover {
  background-color: #0056b3;
}
</style>
