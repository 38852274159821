<template>
  <div class="box-verset">
    <!-- <div class="box-inner"> -->
    <p class="arabe" :class="vActive" v-if="options.arabic && !options.tajweed">
      <template v-for="(verset, verseIndex) in verses" :key="verseIndex">
        <span :id="verset.attributes.numero">
          <span
            v-for="(word, wordIndex) in verset?.attributes?.arabe?.split(' ')"
            :key="wordIndex"
            class="word-arabic"
            @click="playWordAudio(verset.attributes.numero, wordIndex)"
          >
            {{ word }}&nbsp;
          </span>
          <span class="number">{{
            convertToArabic(verset?.attributes?.numero)
          }}</span>
        </span>
      </template>
    </p>
    <p class="tajweed" :class="vActive" v-if="options.tajweed">
      <template v-for="(verset, index) in verses" :key="verset?.id || index">
        <span
          :id="'verse-' + (index + 1)"
          v-html="verset?.attributes?.Tajweed"
        ></span>
        <span class="number">
          {{ convertToArabic(verset?.attributes?.numero) }}
        </span>
      </template>
    </p>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: "full-verse",
  props: {
    verses: Object,
    tajweed: Object,
    vActive: String,
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentAudio: null, // Gère l'instance audio en cours
    };
  },
  methods: {
    convertToArabic(number) {
      const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
      return number
        .toString()
        .split("")
        .map((digit) => arabicNumerals[parseInt(digit)])
        .join("");
    },
    playWordAudio(verseNumber, wordIndex) {
      const audioPath = this.getWordAudioPath(verseNumber, wordIndex);
      if (!audioPath) return;

      // Arrête l'audio en cours s'il y en a un
      if (this.currentAudio) {
        this.currentAudio.pause();
        this.currentAudio = null;
      }

      // Joue le nouvel audio
      this.currentAudio = new Audio(audioPath);
      this.currentAudio.play();

      // Réinitialise une fois terminé
      this.currentAudio.addEventListener("ended", () => {
        this.currentAudio = null;
      });
    },
    getWordAudioPath(verseNumber, wordIndex) {
      const surahId = this.$route.params.id; // ID de la sourate depuis l'URL
      if (!surahId || !verseNumber) return null;

      // Formatage des nombres pour le chemin
      const sourateDossier = String(surahId);
      const versetFile = String(verseNumber).padStart(3, "0");
      const sourateNumero = String(surahId).padStart(3, "0");
      const versetNumero = String(verseNumber).padStart(3, "0");
      const wordNumber = String(wordIndex + 1).padStart(3, "0");

      return `https://audio.doonun.com/mots/${sourateDossier}/${versetFile}/${sourateNumero}_${versetNumero}_${wordNumber}.mp3`;
    },
  },
};
</script>

<style scoped>
.box-verset {
  display: block;
  margin: 20px auto 80px auto;
  max-width: 800px;
  border: 1px solid var(--button);
  border-bottom: 0;
  border-top: 0;
}

.arabe {
  direction: rtl;
  font-family: "uthmanic";
  font-size: clamp(20px, 8vw, 33px);
  text-align: justify;
  text-align-last: right;
  overflow-wrap: break-word;
  line-height: 2em;
  padding-top: 1px;
  padding-right: 10px;
  padding-left: 10px;
  background-image: linear-gradient(
    to bottom,
    var(--button) 1px,
    var(--bgV) 1px
  );
  background-size: 100% 2em;
  /* background-position: -1px; */
}

.arabe .word-arabic:hover {
  cursor: pointer;
  color: #ff6370;
}

.tajweed {
  direction: rtl;
  font-family: "uthmanic";
  font-size: clamp(20px, 8vw, 33px);
  line-height: 2em;
  padding-top: 1px;
  padding-right: 10px;
  padding-left: 10px;
  background-image: linear-gradient(
    to bottom,
    var(--button) 1px,
    var(--bgV) 1px
  );
  background-size: 100% 2em;
  /* background-position: -1px; */
}

.number {
  margin: 0 5px 0 10px;
  position: relative;
  top: -2px;
}

.reading-verse {
  color: #71d5e3 !important;
  transition: 0.3s ease;
}
</style>
